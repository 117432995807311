.header {
    text-align: center;
    margin: 50px 0;
}

.header img {
    width: 150px;
}
.landing-page{
    background-image: url('/src/JAINSHREE/jainnamkin/jain%20bg.png');
    background-size: cover; /* Ensures the image covers the entire area */
    background-repeat: no-repeat; /* Prevents tiling of the image */
    background-position: center; /* Centers the image */
    height: 100vh; /* Sets the height to the full viewport height */
    width: 100vw; /* Ensures it takes the full viewport width */
    display: flex; /* Maintains your flex display */
    padding: 25px 25px;
    
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.landing-page .container{
    padding: 75px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
@media  (max-width: 992px) {
    .card-container{
        flex-direction: column !important;
    }
    .landing-page{
        background-image: url('/src/JAINSHREE/jainnamkin/jain%20bg.png');
        background-size: cover; /* Ensures the image covers the entire area */
        background-repeat: no-repeat; /* Prevents tiling of the image */
        background-position: center; /* Centers the image */
        height: 1200px !important; /* Sets the height to the full viewport height */
        width: 100vw; /* Ensures it takes the full viewport width */
        display: flex; /* Maintains your flex display */
        animation: gradient 15s ease infinite; /* Keep this if animation is required */
        padding: 25px 25px;
    }
    .section-content h2 {
        font-size: 19px !important;
    }
}
@media  (max-width: 920px) {
    .landing-page .container{
        flex-direction: column;
    }
    .card-container{
        flex-direction: column !important;
    }


}
@media  (max-width: 768) {
    .landing-page{

        height: 500px !important;
     
    }
}
@media  (max-width: 395px) {
    .landing-page{
        height: 140vh !important;
    }
    .section-content h2 {
        font-size: 15px !important;
    }
}
@media  (max-width: 345px) {
    .landing-page{
        height: 135vh !important;
    }
    .section-content h2 {
        font-size: 15px !important;
    }
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}
.cta-button{
    color: black;
    font-weight: bold;
}
.section img {
    width: 450px;
    height: 100%;
    border-radius: 10px;
}

.section-content {
    max-width: 500px;
    margin: 20px;
    text-align: center;
}

.section-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: white;
}

.section-content a {
    text-decoration: none;
    color: white;
    padding: 10px 20px;
 
    border-radius: 5px;
}

.section-content a {
    background-color: #e0e0e0;
    color: black;
}

/* footer {
    text-align: center;
    padding: 30px;
    background-color: #000;
} */
