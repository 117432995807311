/*-----------------------------------------------------------------------------------

    Template Name: Housey - Resort and Hotel Booking HTML Template
    Author: Theme_Pure
    Support: basictheme400@gmail.com
    Description: Housey - Resort and Hotel Booking HTML Template
    Version: 1.0.1

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

	-----------------
    01. THEME CSS
	-----------------
		1.1 Common Classes
		1.2 Default Spacing
		1.3 Theme Default

	-----------------
    02. COMPONENTS css
	-----------------
		2.1 animetion
		2.2 background
		2.3 backtotop
		2.4 breadcrumb
		2.5 buttons 
 		2.6 instagram
		2.7 nice-select
		2.8 offcanvas
		2.9 pagenation
		2.10 preloader
		2.11 section-title
		2.12 sidebar

	---------------------------------
	03. BLOG CSS
	---------------------------------
		3.1 Blog css
		3.2 Postbox css

	---------------------------------
	04. FOOTER CSS
	---------------------------------
		4.1 Footer Style 1

	-----------------
  05. HEADER CSS
	-----------------
		5.1 Header Style 1

  ---------------------------------
	06. MENU CSS
	---------------------------------
		6.1 menu css
		6.2 Mobile css
		6.3 offcanvas css


	---------------------------------
	07. PAGES CSS
	---------------------------------
		7.1 about css
		7.2 adventures css
		7.3 banefit css
		7.4 banner css
		7.5 booking css
		7.6 brands css
		7.7 connected css
		7.8 contact css
		7.9 counter css
		7.10 cta css
		7.11 destinations css
		7.12 faq css
		7.13 feature css
		7.14 flatpicker css
		7.15 gallery css
		7.16 hero css
		7.17 offer css
		7.18 place css
		7.19 pricing css
		7.20 review css
		7.21 room-details css
		7.22 room css
		7.23 service css
		7.24 suites css
		7.25 team css
		7.26 testimonial css
		7.27 video css
**********************************************/
/*----------------------------------------*/
/*  1.1 Theme Default
/*----------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&amp;family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&amp;family=Onest:wght@100..900&amp;family=Outfit:wght@100..900&amp;family=Parisienne&amp;family=Playfair+Display:ital,wght@0,400..900;1,400..900&amp;display=swap");
.tp-offcanvas-area, .tp-offcanvas-social ul li a {
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}

.tp-cart-checkout-shipping-option label::after, .tp-cart-checkout-shipping-option label::before, .tp-cart-plus,
.tp-cart-minus, .tp-pulse-border::after, .tp-pulse-border::before {
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}

a,
button,
p,
input,
select,
textarea,
li,
.transition-3 {
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}

.tp-cart-list tr td .tp-cart-action-btn svg {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}

/* transform */
:root {
  /**
  @font family declaration
  */
  --tp-ff-dm: 'DM Sans', sans-serif;
  --tp-ff-playfair: 'Playfair Display', serif;
  --tp-ff-amita: 'Amita', serif;
  --tp-ff-outfit: 'Outfit', sans-serif;
  --tp-ff-onest: 'Onest', sans-serif;
  --tp-ff-parisienne: 'Parisienne', system-ui;
  --tp-ff-fontawesome: "Font Awesome 6 Pro";
  /**
  @color declaration
  */
  --tp-common-white: #ffffff;
  --tp-common-white2: #faf5ef;
  --tp-common-black: #141414;
  --tp-common-yellow: #ffeb38;
  --tp-common-yellow2: #ffd084;
  --tp-common-black2: #19163c;
  --tp-common-black3: #180d06;
  --tp-common-black4: #2d1a0e;
  --tp-common-falured: #a05916;
  --tp-grey-1: #5A5859;
  --tp-grey-2: #c7c7c7;
  --tp-grey-3: #9d9d9d;
  --tp-grey-4: #595654;
  --tp-grey-5: #635c57;
  --tp-theme-primary: #B7124D;
  --tp-theme-1: #761f0e;
  --tp-theme-2: #d59020;
}

@media (min-width: 1400px) {
  .container-1750 {
    max-width: 1750px;
  }

  .custom-column-1 {
    width: 63%;
  }

  .custom-column-2 {
    width: 37%;
  }

  .custom-column-3 {
    width: 40.4%;
  }

  .custom-column-4 {
    width: 59.6%;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
  font-family: var(--tp-ff-dm);
  font-size: 16px;
  font-weight: normal;
  color: var(--tp-common-black);
  background-color: #FAF5EF;
  line-height: 1.5;

}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tp-ff-dm);
  color: var(--tp-common-black);
  font-weight: 800;
  line-height: 1.12;
}

h1 {
  font-size: 80px;
}

h2 {
  font-size: 50px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
}

p {
  margin-bottom: 15px;
  font-weight: 400;
  line-height: 1.56;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.round-6 {
  border-radius: 6px;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=number],
input[type=password],
input[type=url],
textarea {
  outline: none;
  background-color: #fff;
  height: 56px;
  width: 100%;
  line-height: 56px;
  font-size: 14px;
  color: var(--tp-heading-1);
  padding-left: 26px;
  padding-right: 26px;
  border: 1px solid #E0E2E3;
}
input[type=text]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #95999D;
}
input[type=text]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=number]::-moz-placeholder,
input[type=password]::-moz-placeholder,
input[type=url]::-moz-placeholder,
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #95999D;
}
input[type=text]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=tel]:-moz-placeholder,
input[type=number]:-moz-placeholder,
input[type=password]:-moz-placeholder,
input[type=url]:-moz-placeholder,
textarea:-moz-placeholder {
  /* Firefox 4-18 */
  color: #95999D;
}
input[type=text]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=url]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* IE 10+  Edge*/
  color: #95999D;
}
input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=tel]::placeholder,
input[type=number]::placeholder,
input[type=password]::placeholder,
input[type=url]::placeholder,
textarea::placeholder {
  /* MODERN BROWSER */
  color: #95999D;
}

textarea {
  line-height: 1.4;
  padding-top: 18px;
  padding-bottom: 17px;
}

input[type=color] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

::selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--tp-grey-1);
  font-size: 14px;
  opacity: 1;
}

*::placeholder {
  color: var(--tp-grey-1);
  font-size: 14px;
  opacity: 1;
}

@media (min-width: 1400px) {
  .container-1700 {
    max-width: 1700px;
  }
}
@media (min-width: 1400px) {
  .container-1430 {
    max-width: 1430px;
  }
}
/*---------------------------------
  1.1 Common Classes
---------------------------------*/
.w-img {
  width: 100%;
}

.m-img img {
  max-width: 100%;
}

.lineheight-1 {
  line-height: 1;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.f-right {
  float: right;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-11 {
  z-index: 11;
}

.text-right {
  text-align: right;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-position {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bg-position-md-left {
    background-position: left;
  }
}

/* dropcap */
.tp-dropcap::first-letter {
  font-size: 90px;
  font-weight: 500;
  float: left;
  text-align: center;
  color: var(--tp-common-black);
  margin-right: 5px;
  line-height: inherit;
  text-transform: capitalize;
}

.class {
  stroke-dasharray: 189px, 191px;
  stroke-dashoffset: 0px;
}

/* gutter for x axis */
.tp-gx-10 {
  --bs-gutter-x: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-gx-10 {
    --bs-gutter-x: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-gx-10 {
    --bs-gutter-x: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-gx-10 {
    --bs-gutter-x: 10px;
  }
}
@media (max-width: 575px) {
  .tp-gx-10 {
    --bs-gutter-x: 10px;
  }
}
.tp-gx-10 [class*=col-] {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.tp-gx-12 {
  --bs-gutter-x: 12px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-gx-12 {
    --bs-gutter-x: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-gx-12 {
    --bs-gutter-x: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-gx-12 {
    --bs-gutter-x: 10px;
  }
}
@media (max-width: 575px) {
  .tp-gx-12 {
    --bs-gutter-x: 10px;
  }
}
.tp-gx-12 [class*=col-] {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

/* gutter for x axis */
.tp-gx-15 {
  --bs-gutter-x: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-gx-15 {
    --bs-gutter-x: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-gx-15 {
    --bs-gutter-x: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-gx-15 {
    --bs-gutter-x: 15px;
  }
}
@media (max-width: 575px) {
  .tp-gx-15 {
    --bs-gutter-x: 10px;
  }
}
.tp-gx-15 [class*=col-] {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.tp-gx-20 {
  --bs-gutter-x: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-gx-20 {
    --bs-gutter-x: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-gx-20 {
    --bs-gutter-x: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-gx-20 {
    --bs-gutter-x: 15px;
  }
}
@media (max-width: 575px) {
  .tp-gx-20 {
    --bs-gutter-x: 10px;
  }
}
.tp-gx-20 [class*=col-] {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.tp-gx-23 {
  --bs-gutter-x: 23px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-gx-23 {
    --bs-gutter-x: 23px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-gx-23 {
    --bs-gutter-x: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-gx-23 {
    --bs-gutter-x: 15px;
  }
}
@media (max-width: 575px) {
  .tp-gx-23 {
    --bs-gutter-x: 10px;
  }
}
.tp-gx-23 [class*=col-] {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

/* gutter for x axis */
.tp-gx-30 {
  --bs-gutter-x: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-gx-30 {
    --bs-gutter-x: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-gx-30 {
    --bs-gutter-x: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-gx-30 {
    --bs-gutter-x: 15px;
  }
}
@media (max-width: 575px) {
  .tp-gx-30 {
    --bs-gutter-x: 10px;
  }
}
.tp-gx-30 [class*=col-] {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

/* gutter for x axis */
.tp-gx-40 {
  --bs-gutter-x: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-gx-40 {
    --bs-gutter-x: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-gx-40 {
    --bs-gutter-x: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-gx-40 {
    --bs-gutter-x: 30px;
  }
}
@media (max-width: 575px) {
  .tp-gx-40 {
    --bs-gutter-x: 15px;
  }
}
.tp-gx-40 [class*=col-] {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

/* gutter for x axis */
.tp-gx-50 {
  --bs-gutter-x: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-gx-50 {
    --bs-gutter-x: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-gx-50 {
    --bs-gutter-x: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-gx-50 {
    --bs-gutter-x: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-gx-50 {
    --bs-gutter-x: 15px;
  }
}
@media (max-width: 575px) {
  .tp-gx-50 {
    --bs-gutter-x: 10px;
  }
}
.tp-gx-50 [class*=col-] {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

/* gutter for x axis */
.tp-gx-60 {
  --bs-gutter-x: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-gx-60 {
    --bs-gutter-x: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-gx-60 {
    --bs-gutter-x: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-gx-60 {
    --bs-gutter-x: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-gx-60 {
    --bs-gutter-x: 15px;
  }
}
@media (max-width: 575px) {
  .tp-gx-60 {
    --bs-gutter-x: 10px;
  }
}
.tp-gx-60 [class*=col-] {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

/*---------------------------------
    1.2 Default Spacing
---------------------------------*/
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-170 {
    padding-top: 115px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-165 {
    padding-top: 110px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-160 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-155 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-150 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-145 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-140 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-135 {
    padding-top: 95px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-130 {
    padding-top: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-125 {
    padding-top: 85px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-120 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-115 {
    padding-top: 75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-110 {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-105 {
    padding-top: 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pt-100 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-170 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-165 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-160 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-155 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-150 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-145 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-140 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-135 {
    padding-bottom: 95px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-130 {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-125 {
    padding-bottom: 85px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-120 {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-115 {
    padding-bottom: 75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-110 {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-105 {
    padding-bottom: 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-100 {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-95 {
    padding-bottom: 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .pb-90 {
    padding-bottom: 50px;
  }
}

/*----------------------------------------*/
/*  2.3 backtotop
/*----------------------------------------*/
.back-to-top-wrapper {
  position: fixed;
  right: 20px;
  bottom: 0;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 575px) {
  .back-to-top-wrapper {
    right: 20px;
    bottom: 20px;
  }
}
.back-to-top-wrapper.back-to-top-btn-show {
  visibility: visible;
  opacity: 1;
  bottom: 50px;
}

.back-to-top-btn {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: var(--tp-theme-primary);
  box-shadow: 0px 8px 16px rgba(3, 4, 28, 0.3);
  color: var(--tp-common-white);
  border-radius: 50%;
}
.back-to-top-btn svg {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}
.back-to-top-btn:hover {
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  -o-transform: translateY(-4px);
  transform: translateY(-4px);
}

/*----------------------------------------*/
/*  2.5 buttons 
/*----------------------------------------*/
.tp-btn {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  padding: 8px 29px;
  font-weight: 500;
  font-size: 16px;
  color: var(--tp-common-white);
  display: inline-block;
  transition: all 0.4s ease;
}
.tp-btn:hover {
  color: var(--tp-common-black);
  background: var(--tp-common-white);
  border-color: var(--tp-common-white);
}
.roomidtext{
  font-size:15px ;
}
.tp-btn-2 {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.02em;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--tp-common-white);
  padding: 8px 18px;
  display: inline-block;
}
.room-list{
  padding: 65px;
}
.rs-dropdown img{
  height: 50px;
  border-radius: 50%;
  margin-left: 20px
}
.rs-dropdown-menu{
  position: absolute;
}
.tp-btn-2:hover {
  background: var(--tp-common-white);
}
.tp-btn-2 span {
  margin-right: 5px;
}
.tp-btn-3 {
  border: 1px solid var(--tp-theme-primary);
  color: var(--tp-theme-primary);
  padding: 9px 22px;
}
.tp-btn-3:hover {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
}
.shape-overlays {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.shape-overlays__path {
  fill: #ffcc00; /* Adjust color to match the theme */
  transition: d 0.6s ease-in-out;
}

.shape-overlays.animate .shape-overlays__path:nth-child(1) {
  animation: pathAnimation1 0.8s ease-in-out forwards;
}

.shape-overlays.animate .shape-overlays__path:nth-child(2) {
  animation: pathAnimation2 0.8s ease-in-out 0.2s forwards;
}

.shape-overlays.animate .shape-overlays__path:nth-child(3) {
  animation: pathAnimation3 0.8s ease-in-out 0.4s forwards;
}

@keyframes pathAnimation1 {
  from {
    d: path("M 0 100 C 50 100 50 100 100 100 V 100 H 0");
  }
  to {
    d: path("M 0 0 C 50 50 50 50 100 0 V 100 H 0");
  }
}

@keyframes pathAnimation2 {
  from {
    d: path("M 0 100 C 50 100 50 100 100 100 V 100 H 0");
  }
  to {
    d: path("M 0 0 C 50 100 50 100 100 0 V 100 H 0");
  }
}

@keyframes pathAnimation3 {
  from {
    d: path("M 0 100 C 50 100 50 100 100 100 V 100 H 0");
  }
  to {
    d: path("M 0 0 C 50 50 50 50 100 0 V 100 H 0");
  }
}

.tp-btn-4 {
  border-radius: 4px;
  background: var(--tp-theme-2);
  border: none;
  font-size: 15px;
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  color: var(--tp-common-white2);
  padding: 10px 27px;
  display: inline-block;
}
.tp-btn-4 svg {
  margin-right: 3px;
  transform: translateY(-3px);
}
.tp-btn-4:hover {
  background: var(--tp-common-falured);
  color: var(--tp-common-white2);
}
.tp-btn-5 {
  background: var(--tp-common-white);
  color: var(--tp-common-black4);
}
.tp-btn-square {
  font-weight: 600;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
  border: 1px solid var(--tp-common-black);
  padding: 8px 18px;
  display: inline-block;
}
.tp-btn-square:hover {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
}
.tp-btn-square-yellow:hover {
  background: var(--tp-theme-2);
  border: 1px solid var(--tp-theme-2);
}
.tp-btn-square-red {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  border: none;
}
.tp-btn-square-red:hover {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
}
.tp-btn-banner {
  border-radius: 6px;
  background: #e7256b;
  border: none;
  color: var(--tp-common-white);
}
.tp-btn-banner-white {
  background: var(--tp-common-white);
  color: var(--tp-common-black);
}
.tp-btn-banner-white:hover {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
}
.tp-btn-banner:hover {
  background: var(--tp-theme-primary);
}
.tp-btn-sm {
  border: 1px solid var(--tp-common-black);
  border-radius: 100px;
  padding: 8px 24px;
  font-weight: 500;
  font-size: 14px;
  color: var(--tp-common-black);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-btn-sm svg {
  margin-left: 7px;
  transform: translateY(-2px);
}
.tp-btn-sm:hover {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  border: 1px solid var(--tp-common-black);
}
.tp-btn-border-black {
  backdrop-filter: blur(6px);
  background: #2d1a0e;
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  color: var(--tp-common-white);
  width: 100%;
  padding: 13px 15px;
  transition: all 0.4s ease;
  display: block;
}
.tp-btn-border-black:hover {
  background: var(--tp-common-falured);
}
.tp-btn-falured {
  background: var(--tp-common-falured);
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  color: var(--tp-common-white2);
  width: 100%;
  padding: 13px 15px;
  transition: all 0.4s ease;
  display: block;
}
.tp-btn-falured:hover {
  background: var(--tp-theme-2);
}
.tp-btn-white {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 15px;
  color: var(--tp-common-black4);
  border: 1px solid #fff;
  padding: 8px 21px;
  backdrop-filter: blur(20px);
  box-shadow: 0 1px 2px 0 rgba(45, 26, 14, 0.18);
  background: rgba(255, 255, 255, 0.8);
  display: inline-block;
}
.tp-btn-white:hover {
  background: var(--tp-theme-2);
  color: var(--tp-common-white);
  border-color: var(--tp-theme-2);
}
.tp-btn-white-2 {
  background: var(--tp-common-white);
  color: var(--tp-common-black);
  border-radius: 6px;
}
.tp-btn-white-2:hover {
  background: var(--tp-theme-primary);
  border-color: var(--tp-theme-primary);
}
.tp-btn-large {
  border-radius: 8px;
  padding: 13px 33px;
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 16px;
  color: var(--tp-common-white);
  display: inline-block;
  background: var(--tp-theme-primary);
}
.tp-btn-large:hover {
  background: var(--tp-common-black);
}
.tp-btn-xxl {
  display: inline-block;
  font-weight: 700;
  color: var(--tp-common-white);
  background: var(--tp-common-black);
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  letter-spacing: -0.02em;
  font-size: 18px;
  padding: 17px 30px;
}
.tp-btn-xxl:hover {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
}

.pulse-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background-color: var(--tp-common-white);
  border-radius: 50%;
  color: var(--tp-common-black);
  animation: pulse 2s infinite;
}
.pulse-btn:hover {
  background-color: var(--tp-common-black);
  color: var(--tp-common-black);
}

.tp-demo-btn {
  border-radius: 20px;
  padding: 12px 22px;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
  background: var(--tp-theme-primary);
  flex: 0 0 auto;
  padding: 8px 23px;
  display: inline-block;
}
.tp-demo-btn:hover {
  color: var(--tp-common-black);
  background: var(--tp-common-white);
}
.tp-demo-btn-black {
  background: var(--tp-common-black);
}
.tp-demo-btn-black:hover {
  color: var(--tp-common-black);
  background: var(--tp-common-white);
}

/*----------------------------------------*/
/*  2.1 animetion
/*----------------------------------------*/
@keyframes bar_anim {
  0%, 100% {
    -webkit-clip-path: inset(-2px 0);
    clip-path: inset(-2px 0);
  }
  42% {
    -webkit-clip-path: inset(-2px 0 -2px 100%);
    clip-path: inset(-2px 0 -2px 100%);
  }
  43% {
    -webkit-clip-path: inset(-2px 100% -2px 0);
    clip-path: inset(-2px 100% -2px 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@-webkit-keyframes rotate-infinite {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-moz-keyframes rotate-infinite {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-ms-keyframes rotate-infinite {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes rotate-infinite {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.rotate-infinite {
  -webkit-animation: rotate-infinite 15s linear infinite;
  animation: rotate-infinite 15s linear infinite;
}

@-webkit-keyframes rotate-infinite-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotate-infinite-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-ms-keyframes rotate-infinite-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate-infinite-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rotate-infinite-2 {
  -webkit-animation: rotate-infinite-2 15s linear infinite;
  animation: rotate-infinite-2 15s linear infinite;
}

@-webkit-keyframes borderanimate2 {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  60% {
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.9);
    opacity: 0;
  }
}
@-moz-keyframes borderanimate2 {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  60% {
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.9);
    opacity: 0;
  }
}
@-ms-keyframes borderanimate2 {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  60% {
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.9);
    opacity: 0;
  }
}
@keyframes borderanimate2 {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  60% {
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.9);
    opacity: 0;
  }
}
@keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes img-anim-right {
  0% {
    transform: translateX(5%);
    clip-path: inset(0 0 0 100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}
.img-custom-anim-left {
  animation: img-anim-left 1.3s forwards cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
  opacity: 0;
}

.img-custom-anim-right {
  animation: img-anim-right 1.3s forwards cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
  opacity: 0;
}

@keyframes img-anim-left {
  0% {
    transform: translateX(-5%);
    clip-path: inset(0 100% 0 0);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}
.img-custom-anim-top {
  animation: img-anim-top 1.3s forwards cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
}

@keyframes img-anim-top {
  0% {
    transform: translateY(-5%);
    clip-path: inset(0 0 100% 0);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}
.img-custom-anim-bottom {
  animation: img-anim-bottom 1.3s forwards cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
}

@keyframes img-anim-bottom {
  0% {
    transform: translateY(5%);
    clip-path: inset(100% 0 0 0);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}
@keyframes zoom {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes tpswing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
@-webkit-keyframes tpswing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
@keyframes tpswings {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(-20px) rotate(-10deg);
  }
}
.tpswings {
  animation: tpswing 3s ease-in-out 0.1s forwards infinite alternate;
  transform-origin: bottom left;
}

@keyframes hero-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
/*----------------------------------------*/
/*  2.10 Modal
/*----------------------------------------*/
/*----------------------------------------*/
/*  2.9 pagenation
/*----------------------------------------*/
#loading {
  background-color: var(--tp-common-falured);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  margin-top: 0px;
  top: 0px;
}

.loading {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -100px;
  display: block;
  margin: 15px auto;
  position: relative;
  box-sizing: border-box;
  animation: shadowRolling 2s linear infinite;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes shadowRolling {
  0% {
    box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  36% {
    box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
  }
  62% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white;
  }
  75% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
  }
  87% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white;
  }
  100% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
  }
}
/*----------------------------------------*/
/*  2.2 background
/*----------------------------------------*/
.tp-section-black {
  background: var(--tp-common-black);
}

.tp-bg-gray {
  background: #f9f9f9;
}

.tp-bg-gray-2 {
  background: #f8f8f8;
}

.tp-bg-gray-3 {
  background: #f6f6f6;
}

.tp-bg-gray-4 {
  background: #f6efe7;
}

.tp-pink-bg {
  background: #44343a;
}

.tp-yellow-bg {
  background: #ffd084;
}

.tp-falured-bg {
  background: #761f0e;
}

/* .tp-falured-bg-2 {
  background: var(--tp-common-black4);
} */

.tp-home-3-bg {
  background: #faf5ef;
}

/*----------------------------------------*/
/*  2.7 nice select
/*----------------------------------------*/
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 15px;
  font-weight: normal;
  line-height: 58px;
  outline: none;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
  width: 100%;
  text-transform: uppercase;
  height: 60px;
  color: var(--tp-grey-1);
}
.nice-select:hover {
  border-color: #dbdbdb;
}
.nice-select .current {
  font-size: 15px;
}
.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: var(--tp-theme-primary);
}
.nice-select::after {
  position: absolute;
  content: "\f107";
  top: 50%;
  right: 20px;
  font-size: 18px;
  font-family: var(--tp-ff-fontawesome);
  color: var(--tp-common-black);
  font-weight: 500;
  pointer-events: none;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  margin-top: 0;
  transform-origin: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.nice-select.open::after {
  -webkit-transform: translateY(-50%) rotate(-180deg);
  -moz-transform: translateY(-50%) rotate(-180deg);
  -ms-transform: translateY(-50%) rotate(-180deg);
  -o-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}
.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}
.nice-select.disabled::after {
  border-color: #cccccc;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right;
}
.nice-select.right .list {
  left: auto;
  right: 0;
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}
.nice-select.small::after {
  height: 4px;
  width: 4px;
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}
.nice-select .list {
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  width: 100%;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nice-select .option.selected {
  font-weight: bold;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

/*----------------------------------------*/
/*  2.8 offcanvas
/*----------------------------------------*/
.body-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 995;
  width: 100%;
  height: 100%;
  background: rgba(24, 24, 24, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: 0.45s ease-in-out;
}
.body-overlay.apply {
  opacity: 1;
  visibility: visible;
}
.body-overlay.openeds {
  opacity: 1;
  visibility: visible;
}
.body-overlay.active {
  opacity: 1;
  visibility: visible;
}
.body-overlay:hover {
  cursor: url(../src/COMPONENT/img/icon/cross-out.png), pointer;
}

.tpoffcanvas {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  right: -100%;
  width: 480px;
  bottom: 0;
  box-shadow: 0 16px -32px 0 rgba(0, 0, 0, 0.8);
  background-color: var(--tp-common-white);
  z-index: 997;
  padding: 50px;
  scrollbar-width: none;
  opacity: 0;
  visibility: hidden;
  transition: 0.45s ease-in-out;
  overflow-y: scroll;
}
.tpoffcanvas .homemenu {
  margin-bottom: 30px;
}
.tpoffcanvas .homemenu-title {
  color: var(--tp-common-white);
}
.tpoffcanvas .tp-main-menu-mobile {
  margin-bottom: 30px;
}
.tpoffcanvas.opened {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 575px) {
  .tpoffcanvas {
    width: 100%;
    padding: 40px 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpoffcanvas {
    width: 400px;
    padding: 40px;
  }
}
.tpoffcanvas.opened {
  right: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-duration: 0.6s;
  z-index: 999;
}
.tpoffcanvas-btn {
  padding: 22px 40px;
  background: var(--tp-theme-primary);
  display: inline-block;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 20px;
  color: var(--tp-common-white);
  font-weight: 700;
  position: relative;
  transition: all 0.3s linear;
  overflow: hidden;
  z-index: 1;
  line-height: 1;
  width: 100%;
  text-align: center;
  border-radius: 50px;
}
.tpoffcanvas-btn:hover {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
}
.tpoffcanvas__contact-title h5 {
  font-size: 14px;
  color: var(--tp-common-black);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 30px;
  display: inline-block;
}
.tpoffcanvas__contact-info {
  margin-bottom: 30px;
}
.tpoffcanvas__contact-info ul li {
  font-size: 16px;
  color: var(--tp-common-black);
  margin-bottom: 15px;
  list-style-type: none;
}
.tpoffcanvas__contact-info ul li i {
  width: 16px;
}
@media (max-width: 575px) {
  .tpoffcanvas__contact-info ul li {
    font-size: 14px;
  }
}
.tpoffcanvas__contact-info ul li:last-child {
  margin-bottom: 0;
}
.tpoffcanvas__contact-info ul li a {
  margin-left: 10px;
  transition: 0.3s;
}
.tpoffcanvas__contact-info ul li a:hover {
  color: var(--tp-theme-primary);
}
.tpoffcanvas__logo {
  margin-bottom: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpoffcanvas__logo {
    padding-top: 0;
  }
}
@media (max-width: 575px) {
  .tpoffcanvas__logo {
    padding-top: 0;
  }
}
@media (max-width: 575px) {
  .tpoffcanvas__logo img {
    width: 150px;
  }
}
.tpoffcanvas__close-btn button {
  position: absolute;
  right: 30px;
  top: 37px;
  transition: 0.4s;
  -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-duration: 0.3s;
  height: 40px;
  width: 40px;
  line-height: 41px;
  border-radius: 50px;
  border: 1px solid var(--tp-theme-primary);
  color: var(--tp-theme-primary);
}
.tpoffcanvas__close-btn button:hover {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  border: 1px solid var(--tp-theme-primary);
}
.tpoffcanvas__close-btn button i {
  font-weight: 300;
  font-size: 20px;
  transition: 0.4s;
}
.tpoffcanvas__social {
  margin-top: 30px;
}
.tpoffcanvas__social .social-icon a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  border: 1px solid var(--tp-theme-primary);
  color: var(--tp-theme-primary);
  margin-right: 15px;
  transition: 0.3s;
  display: inline-block;
  border-radius: 50px;
}
@media (max-width: 575px) {
  .tpoffcanvas__social .social-icon a {
    margin-right: 3px;
    height: 50px;
    width: 50px;
    line-height: 53px;
    font-size: 15px;
  }
}
.tpoffcanvas__social .social-icon a:hover {
  background-color: var(--tp-theme-primary);
  color: var(--tp-common-white);
}

/*----------------------------------------*/
/*  2.4 breadcrumb
/*----------------------------------------*/
.tp-breadcrumb-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 700;
  font-size: 80px;
  line-height: 1;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-breadcrumb-title {
    font-size: 65px;
  }
}
@media (max-width: 575px) {
  .tp-breadcrumb-title {
    font-size: 42px;
  }
}
.tp-breadcrumb-title-blog {
  font-family: var(--tp-ff-onest);
  font-weight: 800;
  font-size: 60px;
  line-height: 1.33;
  letter-spacing: -0.02em;
}
.tp-breadcrumb-title-blog-2 {
  color: #2d1a0e;
  font-size: 70px;
  line-height: 1.11;
  font-family: var(--tp-ff-playfair);
}
.tp-breadcrumb-title-blog-3 {
  font-size: 67px;
  line-height: 1.11;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body{
    display: flex;
    justify-content: center;
    align-items: center;a
    min-height: 100vh;
    background: url('https://i.postimg.cc/FR2vpqbG/black-aesthetic-mountains-4k-9k.jpg') no-repeat;
    background-size: cover;
    background-position: center;
} */
.login-container {
  height: 800px;
  width: 100%;
  background: url('../src/COMPONENT//img/hero/02.jpg') no-repeat center center;
  background-size: cover; /* Ensures the image covers the entire screen */
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper{
    width: 420px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(6px);
    box-shadow: 0 0 10px rgba(255, 255, 255, .2);
    color: #fff;
    padding: 30px 40px;
    
}

.wrapper h1{
    font-size: 36px;
    text-align: center;
}

.wrapper .input-box{
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.input-box input{
    width: 100%;
    height: 100%;
    background: transparent;
    font-size: 16px;
    color: #fff;
    padding: 20px 40px 20px 20px;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 40px;
}

.input-box input::placeholder{
    color: #fff;
}

.input-box i{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

.wrapper .remember-forget{
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: 20px 0 15px;
}

.remember-forget label input{
    accent-color: #fff;
    margin-right: 10px;
}

.remember-forget a{
    color: #fff;
    text-decoration: none;
}

.remember-forget a:hover{
    text-decoration: underline;
}

.wrapper .btn{
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    color: #333;
    font-weight: 600;
}

.wrapper .btn:hover{
    background: rgba(255, 255, 255, .2);
    color: #fff;
    border-color: #fff;
}

.wrapper .register-link{
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.register-link p a{
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.register-link p a:hover{
    text-decoration: underline;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-breadcrumb-title-blog {
    font-size: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-breadcrumb-title-blog {
    font-size: 42px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-breadcrumb-title-blog {
    font-size: 50px;
  }
}
@media (max-width: 575px) {
  .tp-breadcrumb-title-blog {
    font-size: 42px;
  }
}
.tp-breadcrumb-title-pre {
  font-size: 22px;
  line-height: 1.36;
  letter-spacing: -0.02em;
  color: #fafaf8;
  font-family: var(--tp-ff-amita);
  font-weight: 500;
}
.tp-breadcrumb-shape {
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-breadcrumb-shape img {
    width: 100%;
  }
}
.tp-breadcrumb-para-blog {
  font-family: var(--tp-ff-onest);
  font-size: 18px;
  letter-spacing: -0.02em;
  color: #2f2c2d;
}
.tp-breadcrumb-para-3 {
  font-size: 17px;
  color: #2f2c2d;
}
.tp-breadcrumb-spacing {
  padding-top: 200px;
  padding-bottom: 125px;
  background-image: url('../src//COMPONENT/img/breadcrumb/01.jpg');
}
.tp-breadcrumb-spacing-2 {
  padding-top: 93px;
  padding-bottom: 47px;
}
.tp-breadcrumb-spacing-3 {
  padding-top: 120px;
  padding-bottom: 28px;
}
.tp-breadcrumb-tag {
  font-family: var(--tp-ff-onest);
  font-size: 14px;
  padding: 5px 13px 3px 13px;
  background: #e8e8e8;
  margin-bottom: 7px;
  font-weight: 500;
  display: inline-block;
}
.tp-breadcrumb-tag-2 {
  color: var(--tp-common-falured);
  border: 1px solid #a05916;
  backdrop-filter: blur(6px);
  background: none;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}
.tp-breadcrumb-blog-meta span {
  font-family: var(--tp-ff-onest);
  margin-right: 11px;
  padding-right: 14px;
  margin-bottom: 20px;
  display: inline-block;
  font-weight: 500;
}
.tp-breadcrumb-blog-meta span img {
  margin-right: 3px;
  border-radius: 50%;
}
.tp-breadcrumb-blog-meta span:not(:last-child) {
  position: relative;
}
.tp-breadcrumb-blog-meta span:not(:last-child)::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 24px;
  background: #d9d9d9;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.tp-breadcrumb-blog-meta-2 span {
  color: #2d1a0e;
}
.tp-breadcrumb-faq-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 600;
  font-size: 60px;
  letter-spacing: -0.01em;
  color: var(--tp-common-black4);
}
@media (max-width: 575px) {
  .tp-breadcrumb-faq-title {
    font-size: 50px;
  }
}
.tp-breadcrumb-faq-title-pre {
  font-family: var(--tp-ff-playfair);
  font-weight: 500;
  font-size: 18px;
  color: var(--tp-common-falured);
}
.tp-breadcrumb-faq-text {
  font-family: var(--tp-ff-onest);
  font-size: 18px;
  line-height: 28px;
  color: var(--tp-grey-5);
}
.tp-breadcrumb-list span {
  font-weight: 400;
  font-size: 16px;
  position: relative;
}
.tp-breadcrumb-list span a:hover {
  color: var(--tp-theme-primary);
}
.tp-breadcrumb-list span:not(:last-child) {
  padding-right: 12px;
  margin-right: 5px;
}
.tp-breadcrumb-list span:not(:last-child)::after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  width: 4px;
  height: 4px;
  background-color: #A8ACB0;
  border-radius: 50%;
}

/*----------------------------------------*/
/*  2.11 Section Title
/*----------------------------------------*/
.tp-section-title {
  font-size: 50px;
  color: var(--tp-common-black);
}
@media (max-width: 575px) {
  .tp-section-title {
    font-size: 40px;
  }
}
.tp-section-title-white {
  color: var(--tp-common-white);
}
.tp-section-title-para {
  font-weight: 300;
  font-size: 20px;
  color: var(--tp-grey-1);
}
.tp-section-title-pre {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.02em;
  color: var(--tp-common-yellow);
}
.tp-section-title-pre-red {
  color: var(--tp-theme-primary);
}
.tp-section-title-content {
  color: var(--tp-grey-2);
}
.tp-section-title-small {
  font-size: 36px;
}
.tp-section-title-two {
  font-family: var(--tp-ff-onest);
  font-weight: 700;
  font-size: 36px;
  letter-spacing: -0.01em;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-section-title-two br {
    display: none;
  }
}
.tp-section-title-two img {
  transform: translateY(-4px);
}
.tp-section-title-two-pre {
  font-family: var(--tp-ff-amita);
  font-weight: 400;
  font-size: 20px;
  color: var(--tp-theme-primary);
  margin-bottom: 4px;
}
.tp-section-title-cta {
  font-family: var(--tp-ff-amita);
  font-weight: 700;
  font-size: 90px;
  line-height: 89%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}
@media (max-width: 575px) {
  .tp-section-title-cta {
    font-size: 65px;
  }
}
.tp-section-title-cta-pre {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2em;
  color: var(--tp-theme-primary);
}
.tp-section-two-title {
  font-family: var(--tp-ff-onest);
  font-weight: 700;
  font-size: 66px;
  line-height: 1.2;
  letter-spacing: -0.02em;
}
@media (max-width: 575px) {
  .tp-section-two-title {
    font-size: 44px;
  }
}
.tp-section-two-title-pre {
  font-family: var(--tp-ff-amita);
  font-weight: 700;
  font-size: 66px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .tp-section-two-title-pre {
    font-size: 45px;
  }
}
.tp-section-three-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: #2d1a0e;
  font-size: 48px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-section-three-title {
    font-size: 46px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-section-three-title {
    font-size: 34px;
  }
  .tp-section-three-title br {
    display: none;
  }
}
.tp-section-three-title-pre {
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 0.02em;
  line-height: 1.3;
  color: var(--tp-theme-1);
  font-family: var(--tp-ff-amita);
}

.lh-115 {
  line-height: 115%;
}

.fs-40 {
  font-size: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .fs-40 {
    font-size: 36px;
  }
}

.fs-15 {
  font-size: 15px;
}

/*----------------------------------------*/
/*  2.6 instagram
/*----------------------------------------*/
.tp-instagram-icon {
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  transform: translateY(-50%);
}
.tp-instagram-icon span {
  color: var(--tp-common-white);
  font-size: 26px;
  position: relative;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-instagram-icon span::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  border: 1px solid var(--tp-common-white);
  left: -18px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.tp-instagram-thumb {
  overflow: hidden;
  position: relative;
}
.tp-instagram-thumb a {
  display: block;
  position: relative;
}
.tp-instagram-thumb a::after {
  background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.8) 100%);
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.4s ease;
}
.tp-instagram-thumb img {
  transition: all 0.4s ease;
}
.tp-instagram-thumb:hover a::after {
  opacity: 1;
}
.tp-instagram-thumb:hover span {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.tp-instagram-thumb:hover img {
  transform: scale(1.1);
}

/*----------------------------------------*/
/*  2.12 Sidebar CSS START
/*----------------------------------------*/
.tp-sidebar-wrapper {
  margin-left: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-sidebar-wrapper {
    margin-left: 0;
  }
}
.tp-sidebar-search-input {
  position: relative;
}
.tp-sidebar-search-input input {
  font-weight: 400;
  font-size: 16px;
  color: #75726e;
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
  height: 60px;
  padding: 22px 50px 23px 27px;
  border: 1px solid transparent;
}
.tp-sidebar-search-input input:focus {
  border: 1px solid var(--tp-theme-2);
  background: transparent;
}
.tp-sidebar-search-input input::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: var(--tp-common-falured);
}
.tp-sidebar-search-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 23px;
}
.tp-sidebar-widget-title {
  font-weight: 700;
  font-size: 24px;
  font-family: var(--tp-ff-playfair);
  color: var(--tp-common-black4);
}
.tp-sidebar-border {
  border-bottom: 1px solid #dfdbd7;
  margin-bottom: 36px;
  padding-bottom: 30px;
}
.tp-sidebar-post-meta span {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  color: #75726e;
  display: inline-block;
}
.tp-sidebar-post-meta span.dvdr {
  height: 4px;
  width: 4px;
  background: #75726e;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 7px;
  transform: translateY(-3px);
}
.tp-sidebar-post-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.44;
  font-family: var(--tp-ff-playfair);
  color: var(--tp-common-black4);
  margin-bottom: 10px;
}
.tp-sidebar-post-title a:hover {
  color: var(--tp-common-falured);
}
.tp-sidebar-post-cetagory {
  font-weight: 700;
  font-size: 12px;
  line-height: 1.67;
  text-transform: uppercase;
  color: var(--tp-common-black4);
  border: 1px solid #2d1a0e;
  padding: 2px 12px 2px 12px;
  backdrop-filter: blur(6px);
  display: inline-block;
  margin-bottom: 12px;
}
.tp-sidebar-post-cetagory.cetagory-2 {
  border: 1px solid var(--tp-common-falured);
  color: var(--tp-common-falured);
}
.tp-sidebar-categories-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.7;
  color: var(--tp-common-white);
  padding: 20px 30px;
  margin-bottom: 0;
}
.tp-sidebar-categories-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.tp-sidebar-categories-item:hover .tp-sidebar-categories-img {
  transform: scale(1.08);
}
.tp-sidebar-categories-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.6s ease;
  width: 100%;
  object-fit: cover;
}

.tagcloud a {
  box-shadow: 0 1px 2px 0 rgba(32, 33, 36, 0.2);
  background: var(--tp-common-white);
  padding: 6px 18px;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.67;
  text-transform: uppercase;
  color: var(--tp-grey-5);
  transition: all 0.4s ease;
  margin-bottom: 8px;
  margin-right: 2px;
}
.tagcloud a:hover {
  color: var(--tp-common-white);
  background: var(--tp-common-black4);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tagcloud-details {
    margin-bottom: 30px;
  }
}
.tagcloud-details a {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
  color: var(--tp-grey-1);
  box-shadow: 0 1px 1px 0 rgba(48, 54, 81, 0.06);
  background: #f5f5f5;
  border-radius: 6px;
  text-transform: capitalize;
}
.tagcloud-details a:hover {
  background: var(--tp-theme-primary);
}
.tagcloud-details-2 a {
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
  border-radius: 0;
  color: #635c57;
}
.tagcloud-details-2 a:hover {
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-falured);
}

.tp-sticky {
  position: sticky;
  top: 130px;
}

/*----------------------------------------*/
/*  2.9 pagenation
/*----------------------------------------*/
.tp-pagenation nav ul li {
  list-style: none;
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 2px;
}
.tp-pagenation nav ul li a {
  border: 1px solid #2d1a0e;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  color: var(--tp-common-black4);
}
.tp-pagenation nav ul li a.active {
  background: var(--tp-common-black4);
  color: var(--tp-common-white);
  border-color: var(--tp-common-black4);
}
.tp-pagenation nav ul li a:hover {
  background: var(--tp-common-black4);
  color: var(--tp-common-white);
  border-color: var(--tp-common-black4);
}
.tp-pagenation-2 nav ul li a {
  color: #121420;
  border-radius: 6px;
}
.tp-pagenation-2 nav ul li a.active {
  background: var(--tp-theme-primary);
  border-color: var(--tp-theme-primary);
}
.tp-pagenation-2 nav ul li a:hover {
  background: var(--tp-theme-primary);
  border-color: var(--tp-theme-primary);
}

/*----------------------------------------*/
/*  5.1 Header Style 1
/*----------------------------------------*/
.tp-header-info ul li {
  list-style: none;
  display: inline-block;
  margin-right: 19px;
  padding: 8px 0;
  padding-right: 20px;
}
.tp-header-info ul li a {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: -0.02em;
  color: var(--tp-grey-1);
}
.tp-header-info ul li a:hover {
  color: var(--tp-theme-primary);
}
.tp-header-info ul li a svg {
  color: var(--tp-common-black);
  transform: translateY(-1px);
  margin-right: 3px;
}
.tp-header-usd {
  padding: 8px 20px;
  padding-left: 0;
  position: relative;
  z-index: 99;
}
.tp-header-user{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 25px;
}
.tp-header-usd-list {
  position: absolute;
  top: 120%;
  right: 0;
  background-color: var(--tp-common-white);
  z-index: 11;
  padding: 15px 15px;
  min-width: 100px;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 20px 30px rgba(3, 4, 28, 0.1);
  transition: all 0.2s ease-out 0s;
}
.tp-header-usd-list li {
  list-style: none;
  color: var(--tp-common-black);
  text-align: left;
  cursor: pointer;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.tp-header-usd-list li:hover {
  color: var(--tp-theme-primary);
}
.tp-header-heigh{
  padding: 50px;
}
.tp-header-usd:hover .tp-header-usd-list {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.tp-header-selected-usd {
  color: var(--tp-common-black);
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  position: relative;
  padding-right: 15px;
  cursor: pointer;
  letter-spacing: -0.02em;
  transition: all 0.3s ease;
}
.tp-header-selected-usd:hover {
  color: var(--tp-theme-primary);
}
.tp-header-selected-usd:hover::after {
  color: var(--tp-theme-primary);
}
.tp-header-selected-usd::after {
  position: absolute;
  transition: all 0.3s ease;
  content: "\f107";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--tp-common-black);
  font-size: 14px;
  font-family: var(--tp-ff-fontawesome);
}
.tp-header-acount a {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
.tp-header-acount a:hover {
  color: var(--tp-theme-primary);
}
.tp-header-acount a svg {
  transform: translateY(-2px);
}
.tp-header-border-right {
  position: relative;
}
.tp-header-border-right::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 1px;
  height: 104%;
  background: rgba(20, 20, 20, 0.1);
}
.tp-header-border-bottom {
  border: 1px solid rgba(20, 20, 20, 0.1);
}
.tp-header-btn {
  border-radius: 20px;
  padding: 12px 22px;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
  background: var(--tp-common-black);
  flex: 0 0 auto;
  padding: 8px 23px;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-header-btn-wrap {
    margin-left: 0;
  }
}
.tp-header-btn:hover {
  color: var(--tp-common-white);
  background: var(--tp-theme-primary);
}
.tp-header-submit-btn {
  padding-right: 8px;
}
.tp-header-form {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.tp-header-search .tp-header-search-input {
  font-weight: 400;
  font-size: 16px;
  color: #adadad;
  height: 25px;
  background: none;
  border: none;
  padding: 0;
  width: 100%;
}
.tp-header-search .tp-header-search-input:focus {
  font-weight: 400;
  font-size: 16px;
  color: var(--tp-common-black);
}
.tp-header-input-toggle {
  position: relative;
  width: 80px;
  transition: 0.45s ease-in-out;
}
.tp-header-input-toggle::before {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: var(--tp-common-black);
  transition: all 0.4s ease;
}
.tp-header-input-toggle.active {
  width: 160px;
  z-index: 9999;
  position: relative;
}
.tp-header-input-toggle.active::before {
  width: 100%;
  right: 0;
}
.tp-header-lang button {
  margin-left: 10px;
}
.tp-header-3-menu-bar {
  line-height: 0;
  margin-right: 38px;
}
.tp-header-3-menu-bar button span {
  height: 2px;
  width: 30px;
  background-color: var(--tp-common-white2);
  display: block;
  margin: 6px auto;
}
.tp-header-3-menu-bar button:hover span {
  animation: bar_anim 0.8s cubic-bezier(0.44, 1.1, 0.53, 0.99) 1 forwards;
}
.tp-header-3-menu-bar button:hover span:nth-child(2) {
  animation-delay: 0.1s;
}
.tp-header-3-menu-bar.tp-black button span {
  background: var(--tp-common-black4);
}
.tp-header-3-search button {
  color: var(--tp-common-white);
}
.tp-header-3-search.tp-black button {
  color: var(--tp-common-black4);
}
.tp-header-3-search .tp-header-search-input {
  color: var(--tp-common-white);
}
.tp-header-3-search .tp-header-search-input::placeholder {
  color: var(--tp-common-white);
}
.tp-header-3-search .tp-header-search-input:focus {
  color: var(--tp-common-white);
}
.tp-header-3-search .tp-header-input-toggle::before {
  background-color: var(--tp-common-white);
}
.tp-header-3-border {
  border-bottom: 1px solid rgba(250, 245, 239, 0.1);
}
.tp-header-2-border {
  box-shadow: 0 1px 0 0 rgba(22, 21, 19, 0.1);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-header-2-border {
    box-shadow: inherit;
  }
}
.tp-header-2-menu-bar {
  margin-right: 0;
}
.tp-header-4-border {
  border-bottom: 1px solid rgba(160, 89, 22, 0.1);
}
.tp-header-lang button {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
}
.tp-header-lang button:hover {
  color: var(--tp-theme-2);
}
.tp-header-lang.tp-black button {
  color: var(--tp-common-black4);
}
.tp-header-lang.tp-black button:hover {
  color: var(--tp-theme-2);
}
.tp-header-logo .logo-sticky {
  display: none;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-header-logo-3 img {
    margin-left: -57px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-header-logo-3 {
    padding: 20px 0;
    text-align: right !important;
  }
}
.tp-header-top-blog {
  background: var(--tp-common-white);
}
.tp-header-bottom-2 {
  box-shadow: 0 1px 2px 0 rgba(22, 21, 19, 0.2);
  background: var(--tp-common-white);
}
.tp-header-bg {
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-header-sm-spacing {
    padding: 22px 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-header-menu-btn-black {
    margin-right: 0;
  }
}
.tp-header-menu-btn-black button span {
  background: var(--tp-common-black);
}

.input-body-overlay.active {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  transition: 0.45s ease-in-out;
}

.tp-transparent {

  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #761f0e;
}
.slick-slide > div{
  margin:  0 10px;
}
.btc_team_img_wrapper img{
  height: 100%;
  width: 100%;
}
.container-namking-40 {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Equal width columns */
  gap: 20px; /* Adjust gap as needed */
  padding: 20px; /* Add padding for spacing */
}

@media (max-width: 768px) {
  .container-namking-40 {
    grid-template-columns: 1fr; /* Single column for smaller screens */
  }
}
.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  width: 100%;
  z-index: 999;
  visibility: visible;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 3px 0px rgba(18, 20, 32, 0.14);
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  backdrop-filter: blur(10px);
  background-color: "red";
}
.header-sticky .logo-normal {
  display: none;
}
.header-sticky .logo-sticky {
  display: inline-block;
}
.header-sticky.header-sticky-white {
  background: #faf5ef;
}
.header-sticky.header-sticky-white .tp-main-menu-3 nav ul li > a {
  color: var(--tp-common-black);
}
.header-sticky.header-sticky-white .tp-header-3-menu-bar button span {
  background-color: var(--tp-common-black);
}
.header-sticky.header-sticky-white .tp-header-search-input {
  color: var(--tp-common-black);
}
.header-sticky.header-sticky-white .tp-header-search-input::placeholder {
  color: var(--tp-common-black);
}
.header-sticky.header-sticky-white .tp-header-submit-btn {
  color: var(--tp-common-black);
}
.header-sticky.header-sticky-white .tp-header-3-search .tp-header-input-toggle::before {
  background-color: var(--tp-common-black);
}
.header-sticky.header-sticky-white .tp-header-lang button {
  color: var(--tp-common-black);
}
.header-sticky.header-sticky-white .tp-header-lang button:hover {
  color: var(--tp-theme-2);
}
.header-sticky .tp-main-menu nav ul li > a {
  padding: 21px 0;
}

/* HEADER CSS */
/*----------------------------------------*/
/*  6.1 menu css
/*----------------------------------------*/
.tp-main-menu nav ul li {
  list-style: none;
  display: inline-block;
  margin-right: 30px;
  position: relative;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-main-menu nav ul li {
    margin-right: 24px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-main-menu nav ul li.p-lg-inherit {
    position: inherit;
  }
}
.tp-main-menu nav ul li .submenu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 218px;
  background: var(--tp-common-white);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  transform-origin: top center;
  padding: 22px 0 12px;
  background: var(--tp-common-white);
  transform: scaleY(0);
  box-shadow: 0px 20px 30px rgba(3, 4, 28, 0.1);
}
.tp-main-menu nav ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  transform: translateY(-10px);
}
.tp-main-menu nav ul li .submenu li .submenu {
  right: -100%;
  left: auto;
  top: 0;
}
.tp-main-menu nav ul li .submenu li:hover > a {
  color: var(--tp-theme-primary) !important;
  padding-left: 43px;
}
.tp-main-menu nav ul li .submenu li:hover > a::before {
  width: 12px;
}
.tp-main-menu nav ul li .submenu li:hover > .submenu {
  -webkit-transform: translateY(1);
  -moz-transform: translateY(1);
  -ms-transform: translateY(1);
  -o-transform: translateY(1);
  transform: translateY(1);
  visibility: visible;
  opacity: 1;
}
.tp-main-menu nav ul li .submenu li a {
  position: relative;
  z-index: 11;
  color: var(--tp-common-black);
  font-weight: 400;
  font-size: 15px;
  padding: 6px 28px;
  padding-bottom: 1px;
  width: 100%;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tp-main-menu nav ul li .submenu li a::before {
  position: absolute;
  content: "";
  left: 25px;
  top: 63%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  transform: translateY(-50%);
  width: 0;
  height: 1px;
  background-color: var(--tp-theme-primary);
}
.tp-main-menu nav ul li:hover > a {
  color: var(--tp-theme-primary);
}
.tp-main-menu nav ul li:hover > .submenu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}
.tp-main-menu nav ul li:hover > .tp-mega-menu {
  transform: scaleY(1);
  visibility: visible;
  opacity: 1;
}
.tp-main-menu nav ul li > a {
  display: inline-block;
  color: var(--tp-common-black);
  padding: 28px 0;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.02em;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tp-main-menu nav ul .tp-mega-menu {
  position: absolute;
  z-index: 9999;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  margin-left: 0;
  transform-origin: top;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  transform: scaleY(0);
  box-shadow: 0px 20px 30px rgba(3, 4, 28, 0.1);
}
.tp-main-menu-2 nav ul li {
  margin: 0 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-main-menu-3 nav ul li {
    margin-right: 17px;
  }
}
.tp-main-menu-3 nav ul li:hover > a {
  color: var(--tp-theme-2);
}
.tp-main-menu-3 nav ul li .submenu li:hover > a {
  color: var(--tp-theme-2) !important;
}
.tp-main-menu-3 nav ul li .submenu li a::before {
  background: var(--tp-theme-2);
}
.tp-main-menu-3 nav ul li > a {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 16px;
  color: var(--tp-common-white2);
  padding: 33px 0;
}
.tp-main-menu-3 nav ul li > a i {
  transform: translateY(2px);
  margin-left: 6px;
}
.tp-main-menu-4 nav ul li > a {
  color: var(--tp-common-black4);
  padding: 38px 0;
}
.tp-main-menu .tp-megamenu-small {
  width: 700px;
  padding: 22px 10px 25px;
  box-shadow: 0px 20px 30px rgba(3, 4, 28, 0.1);
  background: var(--tp-common-white);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-main-menu .tp-megamenu-small {
    width: 650px;
  }
}
.tp-main-menu .tp-megamenu-list ul li {
  display: block;
  margin-right: 0;
  position: relative;
}
.tp-main-menu .tp-megamenu-list ul li:hover > a {
  padding-left: 43px !important;
  color: var(--tp-theme-2) !important;
}
.tp-main-menu .tp-megamenu-list ul li:hover > a::before {
  width: 12px;
  background: var(--tp-theme-2);
}
.tp-main-menu .tp-megamenu-list ul li a {
  position: relative;
  z-index: 11;
  color: var(--tp-common-black);
  font-weight: 400;
  font-size: 15px;
  padding: 4px 25px !important;
  padding-bottom: 1px;
  width: 100%;
  text-transform: capitalize;
  display: block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tp-main-menu .tp-megamenu-list ul li a::before {
  position: absolute;
  content: "";
  left: 25px;
  top: 50%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  transform: translateY(-50%);
  width: 0;
  height: 1px;
  background-color: var(--tp-theme-primary);
}
.tp-main-has-submenu {
  width: 100%;
  padding: 30px 30px 10px 30px;
  background-color: var(--tp-common-white);
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
  left: 0;
  right: 0;
  margin: auto !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-main-has-submenu {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-main-has-submenu {
    background: none;
    box-shadow: none;
    padding: 0;
    border: none;
  }
}
.tp-main-has-submenu .homemenu {
  padding: 0px 10px;
}
.tp-main-has-submenu .homemenu .homemenu-thumb {
  box-shadow: 0 1px 3px #010f1c33;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.tp-main-has-submenu .homemenu .homemenu-thumb a {
  position: relative;
  display: block;
  position: relative;
  overflow: hidden;
}
.tp-main-has-submenu .homemenu .homemenu-thumb a img {
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
  width: 100%;
}
.tp-main-has-submenu .homemenu .homemenu-thumb a:hover img {
  transform: scale(1.1);
}
.tp-main-has-submenu .homemenu .homemenu-thumb a:hover::before {
  opacity: 1;
}
.tp-main-has-submenu .homemenu .homemenu-thumb a::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(38, 34, 33, 0.3);
  transition: all 0.3s 0s ease-out;
  opacity: 0;
  z-index: 1;
}
.tp-main-has-submenu .homemenu-title a {
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  background-image: linear-gradient(#b7124d, #b7124d), linear-gradient(#b7124d, #b7124d);
  background-size: 0 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s linear;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: var(--tp-common-black);
}
.tp-main-has-submenu .homemenu-title a:hover {
  background-image: linear-gradient(#b7124d, #b7124d), linear-gradient(#b7124d, #b7124d);
  background-size: 0 1px, 100% 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s linear;
  color: var(--tp-theme-primary);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tp-main-has-submenu-2 {
  width: 1200px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-main-has-submenu-2 {
    width: 1000px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-main-has-submenu-2 {
    width: 100%;
  }
}
.tp-main-has-submenu-2 .homemenu-title a {
  background-image: linear-gradient(#d59020, #d59020), linear-gradient(#d59020, #d59020);
  background-size: 0 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s linear;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: var(--tp-common-black);
}
.tp-main-has-submenu-2 .homemenu-title a:hover {
  background-image: linear-gradient(#d59020, #d59020), linear-gradient(#d59020, #d59020);
  background-size: 0 1px, 100% 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s linear;
  color: var(--tp-theme-2);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/* loader css */
.spinner {
	position: absolute;
	top: 50%;
	left: 45%;
	transform: translate(-50%, -50%);
	width: 30px;
	height: 30px;
	border: 10px solid rgba(180, 37, 45, 0.6);
	border-radius: 50%;
}

.spinner:after {
	position: absolute;
	top: -3.7px;
	left: -3.7px;
	display: block;
	width: 10px;
	height: 10px;
	background: #d13c42;
	border-radius: 50%;
	content: ' ';
	animation: spin 1s linear infinite;
	transform-origin: 18.7px 18.7px;
}

.spinner.green {
	left: 50%;
	border-color: rgba(69, 216, 143, 0.6);
}

.spinner.green:after {
	background: #45d88f;
}

.spinner.blue {
	left: 55%;
	border-color: rgba(68, 153, 214, 0.6);
}

.spinner.blue:after {
	background: #4499d6;
}

.spinner.reverse:after {
	animation-direction: reverse;
}

.quarter-spinner {
	position: absolute;
	top: 50px;
	left: 50px;
	display: block;
	width: 25px;
	height: 25px;
	border: 2px solid #b4252d;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}
.datepicker{
  display: flex;
  gap: 12px;
}

/*----------------------------------------*/
/* 6.2 Mobile css
/*----------------------------------------*/
.tp-offcanvas-menu {
  margin-bottom: 40px;
}
.tp-offcanvas-menu ul {
  list-style: none;
}
.tp-offcanvas-menu ul li {
  position: relative !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-offcanvas-menu ul li a i {
    display: none;
  }
}
.tp-offcanvas-menu ul li > a {
  padding: 8px 0;
  display: block;
  font-size: 15px;
  font-weight: 500;
}
.tp-offcanvas-menu ul li:not(:last-child) > a {
  border-bottom: 1px solid rgba(1, 15, 28, 0.1);
}
.tp-offcanvas-menu ul li .tp-megamenu-list ul li a {
  border-bottom: 1px solid rgba(1, 15, 28, 0.1);
}
.tp-offcanvas-menu ul li:hover > a {
  color: var(--tp-theme-primary);
}
.tp-offcanvas-menu ul li.active > a {
  color: var(--tp-theme-primary);
}
.tp-offcanvas-menu ul li.active > .tp-menu-close {
  color: var(--tp-common-white);
  background: var(--tp-theme-primary);
  border-color: var(--tp-theme-primary);
}
.tp-offcanvas-menu ul li.active > .tp-menu-close i {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tp-offcanvas-menu ul li .tp-submenu {
  display: none;
  padding-left: 20px;
}
.tp-offcanvas-menu ul li .tp-mega-menu {
  display: none;
  padding-left: 0;
  margin-top: -1px;
}
.tp-offcanvas-menu .homemenu-title {
  margin-bottom: 25px;
}
.tp-offcanvas-style-2 .tp-offcanvas-menu ul li:hover > a {
  color: var(--tp-theme-2);
}
.tp-offcanvas-style-2 .tp-offcanvas-menu ul li.active > a {
  color: var(--tp-theme-2);
}
.tp-offcanvas-style-2 .tp-offcanvas-menu ul li.active > .tp-menu-close {
  color: var(--tp-common-white);
  background: var(--tp-theme-2);
  border-color: var(--tp-theme-2);
}

.tp-menu-close {
  position: absolute;
  right: 0;
  top: 4px;
  height: 30px;
  width: 30px;
  font-size: 12px;
  line-height: 29px;
  text-align: center;
  border: 1px solid rgba(1, 15, 28, 0.12);
}
.tp-menu-close i {
  transition: all 0.3s;
}

/*----------------------------------------*/
/* 6.3 offcanvas css
/*----------------------------------------*/
.body-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.45s ease-in-out;
  background: rgba(24, 24, 24, 0.4);
}
.body-overlay.opened {
  opacity: 1;
  visibility: visible;
}

.tp-offcanvas-logo {
  width: 150px;
}
.tp-offcanvas-logo img {
  width: 100%;
}
.tp-offcanvas-area {
  position: fixed;
  top: 0;
  right: 0;
  width: 310px;
  height: 100%;
  z-index: 99;
  z-index: 99999;
  padding: 50px 50px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  background: #FFF;
  overscroll-behavior-y: contain;
  -webkit-transform: translateX(calc(100% + 80px));
  -moz-transform: translateX(calc(100% + 80px));
  -ms-transform: translateX(calc(100% + 80px));
  -o-transform: translateX(calc(100% + 80px));
  transform: translateX(calc(100% + 80px));
}
@media only screen and (max-width: 400px) {
  .tp-offcanvas-area {
    padding: 35px 30px;
  }
}
@media (max-width: 991px) {
  .foutyparcent{
    flex-direction: column;
  }
  .btc_team_right_wrapper{
    width: 100%  !important;
  }
  .btc_team_left_wrapper{
    width: 100% !important;
  }
  /* .ck_slider_top_content h2{
    color: #3d3d3d !important;
  } */
}
@media (max-width: 575px) {
  .tp-offcanvas-area {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-offcanvas-area {
    width: 450px;
  }
}
.tp-offcanvas-area.opened {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.tp-offcanvas-top {
  margin-bottom: 60px;
}
.tp-offcanvas-close-btn {
  color: black;
}
/* @import url("https://fonts.googleapis.com/css?family=Montserrat");

* {
  box-sizing: border-box;
}

body {
  background-color: #eee;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 10px;
} */

.testimonial-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  /* gap: 2rem; */
  background-color: #fff;
  color: #333;
  border-radius: 15px;
  /* margin: 20px auto; */
  width: 800px;
  height: 307px;
  position: relative;
}
.testimonial{
  text-align: center;
  padding: 0px 250px;
}
.footer-head{color: white;}
.footter-text-p{
  color: white;
}
.footer-list li{
  color: white;
}
.cont-det p{
  color: white;
}
.text-foot{
  color: white;
}
.text-center p{
  color: white;
}
.stars {
  font-size: 14px;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 10px;
  margin-top: 50px;
}

.user .user-image {
  border-radius: 50%;
  height: 83px;
  width: 83px;
  object-fit: cover;
}

.user .user-details {
  text-align: center;
}

.user .username {
  margin: 0;
  font-size: 26px;
  margin-bottom: 12px;
}

.user .role {
  margin: 0;
  font-size: 12px;
  margin-bottom: 14px;
}

.progress-dots {
  display: flex;
  gap: 5px;
}

.progress-dot {
  width: 5px;
  height: 5px;
  background-color: #eee;
  border-radius: 50%;
}

.progress-dot.active {
  background-color: #555;
}

.btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  font-size: 10px;
  cursor: pointer;
  transition: 0.1s ease;
}

.btn:hover {
  background-color: #eee;
}

#btn-prev {
  left: 25px;
}

#btn-next {
  right: 25px;
}

.tp-offcanvas-close-btn:hover {
  opacity: 1;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tp-offcanvas-content {
  margin-bottom: 45px;
}
.tp-offcanvas-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #414144;
}
.tp-offcanvas-title {
  font-size: 40px;
  line-height: 1;
  letter-spacing: -0.8px;
  font-weight: 700;
  margin-bottom: 15px;
  color: var(--tp-common-black);
}
.tp-offcanvas-title.sm {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: var(--tp-common-black);
}
.tp-offcanvas-gallery {
  margin-bottom: 65px;
}
.tp-offcanvas-contact {
  margin-bottom: 55px;
}
.tp-offcanvas-contact ul li {
  list-style: none;
}
.tp-offcanvas-contact ul li:not(:last-child) {
  margin-bottom: 2px;
}
.tp-offcanvas-contact ul li a {
  display: inline-block;
  color: #414144;
  font-size: 18px;
  position: relative;
}
.tp-offcanvas-contact ul li a::after {
  position: absolute;
  bottom: 2px;
  right: 0;
  width: 0;
  height: 1px;
  content: "";
  transition: 0.4s;
  background-color: #1e1e1e;
}
.tp-offcanvas-contact ul li a:hover {
  color: var(--tp-common-black);
}
.tp-offcanvas-contact ul li a:hover::after {
  width: 100%;
  right: auto;
  left: 0;
}
.tp-offcanvas-social ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.tp-offcanvas-social ul li {
  list-style: none;
}
.tp-offcanvas-social ul li:not(:last-child) {
  margin-right: 8px;
}
.tp-offcanvas-social ul li a {
  display: inline-block;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 38px;
  border-radius: 40px;
  color: var(--tp-common-black);
  border: 1px solid rgba(2, 11, 24, 0.1);
  background-color: var(--tp-common-white);
}
.tp-offcanvas-social ul li a:hover {
  background-color: var(--tp-common-black);
  border-color: var(--tp-common-black);
  color: var(--tp-common-white);
}
.tp-offcanvas-social ul li a svg {
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
}

/*----------------------------------------*/
/*  3.2 Postbox css
/*----------------------------------------*/
.tp-postbox-meta {
  display: flex;
  align-items: center;
}
.tp-postbox-meta span {
  font-family: var(--tp-ff-onest);
  font-size: 14px;
  letter-spacing: -0.02em;
  color: var(--tp-grey-1);
  display: flex;
  align-items: center;
}
.tp-postbox-meta span.dvdr {
  width: 1px;
  height: 20px;
  background: #d9d9d9;
  display: inline-block;
  margin: 0 14px;
}
.tp-postbox-meta span img {
  border-radius: 50%;
  margin-right: 10px;
}
.tp-postbox-meta span svg {
  margin-right: 6px;
  transform: translateY(-1px);
}
.tp-postbox-meta span a {
  font-weight: 500;
  color: var(--tp-common-black4);
}
.tp-postbox-meta span a:hover {
  color: var(--tp-theme-2);
}
.tp-postbox-big-thumb {
  height: 800px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-postbox-big-thumb {
    height: 600px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-postbox-big-thumb {
    height: 500px;
  }
}
@media (max-width: 575px) {
  .tp-postbox-big-thumb {
    height: 400px;
  }
}
.tp-postbox-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 700;
  font-size: 34px;
  line-height: 1.18;
  color: var(--tp-common-black4);
}
.tp-postbox-title a:hover {
  color: var(--tp-common-black);
}
.tp-postbox-text p {
  font-family: var(--tp-ff-onest);
  color: var(--tp-grey-5);
}
.tp-postbox-nav button {
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 30px;
  color: var(--tp-common-white);
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.tp-postbox-nav button:hover {
  opacity: 0.6;
}
.tp-postbox-nav button.tp-postbox-slider-button-next {
  right: 40px;
  left: auto;
}
.tp-postbox-left-social {
  display: flex;
  flex-direction: column;
}
.tp-postbox-left-social-sticky {
  margin-right: 36px;
  margin-left: -120px;
  width: 50px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-postbox-left-social-sticky {
    margin-left: -50px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-postbox-left-social-sticky {
    margin-right: 0;
    margin-left: 0;
    width: auto;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-postbox-left-social-sticky {
    margin-right: 0;
    margin-left: 0;
    width: auto;
    flex-direction: row;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-postbox-left-social {
    flex-direction: row;
    align-items: flex-start;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.tp-postbox-left-social a {
  font-size: 16px;
  height: 40px;
  width: 40px;
  display: inline-block;
  border: 1px solid #1414143e;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  margin-bottom: 6px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-postbox-left-social a {
    margin-right: 6px;
  }
}
.tp-postbox-left-social a:hover {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  border: 1px solid var(--tp-theme-primary);
}
.tp-postbox-left-social a:hover.svg {
  fill: var(--tp-common-white);
}
.tp-postbox-left-social a svg {
  width: 16px;
  transform: translateY(-1px);
}
.tp-postbox-left-social-transrent {
  position: sticky;
  left: 0;
  top: 120px;
  height: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-postbox-left-social-transrent {
    position: inherit;
    top: 0;
    height: auto;
  }
  .tp-postbox-left-social-transrent a {
    margin-right: 10px;
  }
}
.tp-postbox-left-social-2 a {
  background: var(--tp-common-white);
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  border: none;
}
.tp-postbox-left-social-2 a:hover {
  background: #d59020;
  border: none;
}
.tp-postbox-left-social-3 {
  align-items: end;
  margin-left: 0;
}
.tp-postbox-details-thumb img {
  border-radius: 15px;
}
.tp-postbox-details-thumb-2 img {
  border-radius: 0;
}
.tp-postbox-details-content-para p {
  font-family: var(--tp-ff-onest);
  font-size: 17px;
  line-height: 1.65;
  color: var(--tp-grey-1);
}
.tp-postbox-details-content-para-2 p {
  color: #635c57;
}
.tp-postbox-details-content-para .first-para::first-letter {
  font-size: 130px;
  line-height: 20px;
  color: var(--tp-common-black);
  font-weight: 500;
  float: left;
  text-align: center;
  margin-right: 13px;
  text-transform: capitalize;
  line-height: 0.9;
}
.tp-postbox-details-content-para .first-para::first-letter-2 {
  color: #2d1a0e;
}
.tp-postbox-quote {
  background: #f5f5f5;
  padding: 48px 66px 30px 50px;
  border-radius: 12px;
}
.tp-postbox-quote-2 {
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
  border-radius: 0;
}
@media (max-width: 575px) {
  .tp-postbox-quote {
    padding: 20px 20px 20px 20px;
  }
}
.tp-postbox-quote blockquote {
  display: flex;
}
@media (max-width: 575px) {
  .tp-postbox-quote blockquote {
    flex-direction: column;
  }
}
.tp-postbox-quote-icon {
  margin-right: 30px;
}
@media (max-width: 575px) {
  .tp-postbox-quote-icon {
    margin-bottom: 20px;
  }
}
.tp-postbox-quote-meta-title {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 30px;
  line-height: 133%;
  letter-spacing: -0.02em;
  color: #031f42;
}
@media (max-width: 575px) {
  .tp-postbox-quote-meta-title {
    font-size: 26px;
  }
}
.tp-postbox-quote-meta-title-2 {
  color: #2d1a0e;
}
.tp-postbox-quote-meta span {
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 14px;
  color: #525356;
}
.tp-postbox-quote-meta span.first {
  font-family: var(--tp-ff-outfit);
  font-weight: 400;
  font-size: 17px;
  letter-spacing: -0.02em;
  color: #031f42;
  display: inline-block;
}
.tp-postbox-quote-meta span.dvdr {
  width: 4px;
  height: 4px;
  border-radius: 30px;
  background: #525356;
  display: inline-block;
  margin: 0 8px;
  transform: translateY(-2px);
}
.tp-postbox-quote-meta-2 span {
  color: #635c57;
}
.tp-postbox-quote-meta-2 span.first {
  color: #2d1a0e;
}
.tp-postbox-quote-title {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 34px;
  line-height: 1.3;
  letter-spacing: -0.02em;
}
.tp-postbox-quote-title-2 {
  color: #2d1a0e;
}
.tp-postbox-features-title {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 34px;
  line-height: 1.3;
  letter-spacing: -0.02em;
}
.tp-postbox-features-title-2 {
  color: #2d1a0e;
}
.tp-postbox-features ul li {
  list-style: none;
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 17px;
  line-height: 1.5;
  color: var(--tp-grey-1);
  position: relative;
  padding-left: 15px;
  margin-bottom: 8px;
}
.tp-postbox-features ul li::before {
  position: absolute;
  height: 3px;
  width: 3px;
  content: "";
  top: 50%;
  background: var(--tp-grey-1);
  transform: translateY(-50%);
  left: 0;
  border-radius: 20px;
}
.tp-postbox-features-2 ul li {
  color: #635c57;
}
.tp-postbox-share-wrapper {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding-top: 30px;
  padding-bottom: 20px;
}
.tp-postbox-share-wrapper-2 {
  border-top: 1px solid rgba(45, 26, 14, 0.1);
  border-bottom: 1px solid rgba(45, 26, 14, 0.1);
}
.tp-postbox-share-social {
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 0;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-postbox-share-social {
    justify-content: flex-start;
  }
}
.tp-postbox-share-social a {
  margin-left: 6px;
}
.tp-postbox-share-social-2 a {
  background: var(--tp-common-white);
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  border: none;
}
.tp-postbox-share-social-2 a:hover {
  background: var(--tp-common-falured);
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  border: none;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-postbox-more-middle {
    text-align: left !important;
    margin: 10px 0px;
  }
}
.tp-postbox-more-right {
  text-align: right;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-postbox-more-right {
    text-align: left;
  }
}
.tp-postbox-more-icon a {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.02em;
  color: #9d9c9d;
  margin-bottom: 7px;
  display: inline-block;
}
.tp-postbox-more-icon a:hover {
  color: var(--tp-theme-primary);
}
.tp-postbox-more-title {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: -0.02em;
}
.tp-postbox-more-title.more-title-2 a:hover {
  color: var(--tp-theme-primary) !important;
}
.tp-postbox-more-title a:hover {
  color: var(--tp-theme-primary);
}
.tp-postbox-more-left .tp-postbox-more-icon a:hover {
  color: #a05916;
}
.tp-postbox-more-left .tp-postbox-more-title a:hover {
  color: #a05916;
}
.tp-postbox-more-right .tp-postbox-more-icon a:hover {
  color: #a05916;
}
.tp-postbox-more-right .tp-postbox-more-title a:hover {
  color: #a05916;
}
.tp-postbox-author {
  background: #f5f5f5;
  border-radius: 12px;
  padding: 40px 70px 33px 40px;
  display: flex;
}
.tp-postbox-author-2 {
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
  border-radius: 0;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-postbox-author {
    padding: 40px 30px 33px 30px;
  }
}
@media (max-width: 575px) {
  .tp-postbox-author {
    flex-direction: column;
  }
}
.tp-postbox-author-thumb {
  margin-right: 30px;
}
@media (max-width: 575px) {
  .tp-postbox-author-thumb {
    margin-bottom: 20px;
  }
}
.tp-postbox-author-social {
  flex-direction: row;
  align-items: flex-start;
  margin-left: 0;
}
.tp-postbox-author-social a {
  margin-right: 6px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  box-shadow: 0 1px 2px 0 rgba(18, 20, 32, 0.2);
  background: var(--tp-common-white);
  border: none;
}
.tp-postbox-author-social a:hover {
  border: none;
  box-shadow: none;
}
.tp-postbox-author-social a svg {
  width: 14px;
  transform: translateY(-1px);
}
.tp-postbox-author-social-2 a {
  box-shadow: 0 1px 2px 0 rgba(18, 20, 32, 0.2);
  background: var(--tp-common-white);
}
.tp-postbox-author-social-2 a:hover {
  background: var(--tp-common-falured);
}
.tp-postbox-author-subtitle {
  font-weight: 500;
  font-size: 13px;
  line-height: 1.85;
  letter-spacing: -0.02em;
  color: #9d9c9d;
  text-transform: uppercase;
  margin-bottom: 0;
}
.tp-postbox-author-title {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 26px;
}
.tp-postbox-author-content p {
  font-family: var(--tp-ff-onest);
  line-height: 1.62;
  color: #4f5158;
}
.tp-postbox-author-content-2 p {
  color: #635c57;
}
.tp-postbox-comment-title {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 18px;
  line-height: 1.22;
  margin-bottom: 0;
}
.tp-postbox-comment-title-2 {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 30px;
}
.tp-postbox-comment-text p {
  font-family: var(--tp-ff-onest);
  line-height: 1.62;
  color: #57595f;
  margin-bottom: 23px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-postbox-comment-text p br {
    display: none;
  }
}
.tp-postbox-comment-box {
  display: flex;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 46px;
  padding-bottom: 44px;
}
@media (max-width: 575px) {
  .tp-postbox-comment-box {
    flex-direction: column;
  }
}
@media (max-width: 575px) {
  .tp-postbox-comment-avater {
    margin-bottom: 20px;
  }
}
.tp-postbox-comment-avater img {
  width: 50px;
  margin-top: -3px;
}
.tp-postbox-comment-name .tp-post-meta {
  font-family: var(--tp-ff-onest);
  font-size: 14px;
  line-height: 1.57;
  color: #6c7275;
}
.tp-postbox-comment ul li {
  list-style: none;
}
.tp-postbox-comment ul li ul.children {
  padding-left: 100px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-postbox-comment ul li ul.children {
    padding-left: 0;
  }
}
.tp-postbox-comment-reply a {
  border: 1px solid #e6e6e6;
  border-radius: 100px;
  padding: 2px 14px 2px 14px;
  display: inline-block;
  font-family: var(--tp-ff-onest);
  font-size: 15px;
  line-height: 1.47;
}
.tp-postbox-comment-reply a:hover {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
}
.tp-postbox-comment-reply a svg {
  margin-right: 6px;
  transform: translateY(-1px);
}
.tp-postbox-comment-input .tp-label {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
}
.tp-postbox-comment-input .tp-input {
  width: 100%;
  height: 50px;
  box-shadow: 0 1px 1px 0 rgba(50, 50, 50, 0.06), 0 0 0 1px rgba(50, 50, 50, 0.04);
  background: var(--tp-common-white);
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 17px 20px 17px 20px;
  background: #f6f6f6;
}
.tp-postbox-comment-input .tp-input::placeholder {
  font-size: 16px;
  color: #9d9c9d;
}
.tp-postbox-comment-input .tp-input:focus {
  border: 1px solid #b7124d;
  background: transparent;
}
.tp-postbox-comment-input .tp-label {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
}
.tp-postbox-comment-input .tp-textarea {
  width: 100%;
  height: 160px;
  box-shadow: 0 1px 1px 0 rgba(50, 50, 50, 0.06), 0 0 0 1px rgba(50, 50, 50, 0.04);
  background: var(--tp-common-white);
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  resize: none;
  padding: 20px 20px 20px 20px;
  background: #f6f6f6;
}
.tp-postbox-comment-input .tp-textarea::placeholder {
  font-size: 16px;
  color: #9d9c9d;
}
.tp-postbox-comment-input .tp-textarea:focus {
  border: 1px solid #b7124d;
  background: transparent;
}
.tp-postbox-comment-input .tp-checkbox {
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #e4e4e4;
  outline: none;
  flex: 0 0 auto;
  box-shadow: inset 0 1px 4px 0 rgba(48, 54, 81, 0.06), 0 1px 1px 0 rgba(48, 54, 81, 0.06);
  background: var(--tp-common-white);
  -webkit-transform: translateY(-2.5px);
  -moz-transform: translateY(-2.5px);
  -ms-transform: translateY(-2.5px);
  -o-transform: translateY(-2.5px);
  transform: translateY(-2.5px);
  padding: 0;
  margin-right: 10px;
  transform: translateY(1px);
  border-radius: 4px;
}
.tp-postbox-comment-input .tp-checkbox:checked {
  position: relative;
  background-color: var(--tp-theme-primary);
  border-color: transparent;
}
.tp-postbox-comment-input .tp-checkbox:checked::after {
  box-sizing: border-box;
  position: absolute;
  content: "\f00c";
  font-weight: 700;
  font-family: var(--tp-ff-fontawesome);
  font-size: 10px;
  color: var(--tp-common-white);
  top: 46%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.tp-postbox-comment-input .tp-checkbox:hover {
  cursor: pointer;
}
.tp-postbox-comment-input .tp-agree {
  font-family: var(--tp-ff-onest);
  font-size: 15px;
  line-height: 1.47;
  color: var(--tp-grey-1);
  cursor: pointer;
}
.tp-postbox-comment-input .tp-agree-2 a {
  color: #a05916;
}
.tp-postbox-comment-input .tp-agree a {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  display: inline-block;
}
.tp-postbox-comment-input .tp-agree a:hover {
  color: #b7124d;
}
.tp-postbox-comment-input-2 .tp-label {
  color: #2d1a0e;
}
.tp-postbox-comment-input-2 .tp-input {
  background: var(--tp-common-white);
  border-radius: 0;
}
.tp-postbox-comment-input-2 .tp-input:focus {
  border: 1px solid var(--tp-common-falured);
}
.tp-postbox-comment-input-2 .tp-textarea {
  background: var(--tp-common-white);
  border-radius: 0;
}
.tp-postbox-comment-input-2 .tp-textarea:focus {
  border: 1px solid var(--tp-common-falured);
}
.tp-postbox-comment-input-2 .tp-checkbox {
  border-radius: 0;
}
.tp-postbox-comment-input-2 .tp-checkbox:checked {
  background: #d59020;
}
.tp-postbox-comment-input-2 .tp-agree {
  color: #5a5859;
}
.tp-postbox-comment-input-2 .tp-agree a:hover {
  color: var(--tp-common-falured);
}
.tp-postbox-comment-input-2 .tp-postbox-comment-btn {
  background: #d59020;
  border-radius: 0;
}
.tp-postbox-comment-input-2 .tp-postbox-comment-btn:hover {
  background: var(--tp-common-falured);
}
.tp-postbox-comment-form p {
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.37;
  color: var(--tp-grey-1);
}
.tp-postbox-comment-form-title {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 30px;
}
.tp-postbox-comment-form-2 .tp-postbox-comment-form-title {
  color: #2d1a0e;
}
.tp-postbox-comment-form-2 p {
  color: #635c57;
}
.tp-postbox-comment-box-2 {
  border-bottom: 1px solid rgba(45, 26, 14, 0.1);
}
.tp-postbox-comment-box-2 .tp-postbox-comment-title {
  color: #2d1a0e;
}
.tp-postbox-comment-box-2 .tp-postbox-comment-name .tp-post-meta {
  color: #635c57;
}
.tp-postbox-comment-box-2 .tp-postbox-comment-text p {
  color: #635c57;
}
.tp-postbox-comment-box-2 .tp-postbox-comment-reply a {
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
  color: #2d1a0e;
  border: none;
  border-radius: 0;
}
.tp-postbox-comment-box-2 .tp-postbox-comment-reply a:hover {
  background: var(--tp-common-falured);
  color: var(--tp-common-white);
}
.tp-postbox-thumb .play-btn svg {
  transform: translate(2px, 1px);
}

.play-btn {
  border-radius: 100%;
  background: var(--tp-common-white);
  box-shadow: 0 20px 30px 0 rgba(20, 20, 20, 0.5);
  height: 78px;
  width: 78px;
  display: inline-block;
  color: var(--tp-theme-2);
  text-align: center;
  line-height: 78px;
}
.play-btn:hover {
  background: var(--tp-theme-2);
}
.play-btn.position {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
}

/*----------------------------------------*/
/*  3.1 Blog css
/*----------------------------------------*/
.tp-blog-wrapper {
  padding: 10px;
  box-shadow: 0 20px 30px 6px rgba(20, 20, 20, 0.1);
  background: var(--tp-common-white);
  border: 1px solid transparent;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-blog-wrapper:hover {
  border-color: var(--tp-common-black);
}
.tp-blog-wrapper:hover .tp-blog-thumb img {
  transform: scale(1.1);
}
.tp-blog-thumb {
  overflow: hidden;
}
.tp-blog-thumb img {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-blog-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 117%;
  letter-spacing: -0.02em;
}
.tp-blog-title a:hover {
  color: var(--tp-theme-primary);
}
.tp-blog-date {
  letter-spacing: -0.02em;
  color: var(--tp-grey-1);
  display: inline-block;
}
.tp-blog-content {
  border-bottom: 1px solid #e8e8e8;
  padding-left: 20px;
  padding-right: 20px;
}
.tp-blog-content p {
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #5a5859;
}
.tp-blog-read span {
  font-size: 14px;
  letter-spacing: -0.02em;
  color: var(--tp-grey-1);
}
.tp-blog-user-wrap {
  padding: 4px 20px;
}
.tp-blog-user img {
  border-radius: 50%;
  margin-right: 6px;
}
.tp-blog-user span {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
}
.tp-blog-btn a {
  padding: 10px 20px;
}
.tp-blog-grid-wrapper {
  border-radius: 12px;
}
.tp-blog-grid-wrapper .tp-blog-thumb img {
  border-radius: 8px;
}
.tp-blog-top-border {
  border-top: 1px solid #e6e6e6;
}
.tp-blog-details-title {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 36px;
  letter-spacing: -0.02em;
  color: #031f42;
}

@media (max-width: 575px) {
  .tp-post-cetagory {
    padding-bottom: 100px;
  }
}
.tp-post-cetagory-list ul {
  text-align: center;
}
.tp-post-cetagory-list ul li {
  list-style: none;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 2px;
}
.tp-post-cetagory-list ul li.active {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 15px;
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12), inset 0 -1px 3px 0 rgba(32, 33, 36, 0.14);
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  border-radius: 6px;
  padding: 6px 20px 5px 20px;
}
.tp-post-cetagory-list ul li a {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 15px;
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12), inset 0 -1px 3px 0 rgba(32, 33, 36, 0.14);
  background: var(--tp-common-white);
  border-radius: 6px;
  padding: 6px 20px 5px 20px;
  display: inline-block;
}
.tp-post-cetagory-list ul li a:hover {
  background: #b7124d1c;
  color: var(--tp-theme-primary);
  box-shadow: none;
}

/*----------------------------------------*/
/*  4.1 Footer Style 1
/*----------------------------------------*/
footer{
  background-color: #79644b;
}
.tp-footer-logo img {
  width: 138px;
}
.tp-footer-location a {
  letter-spacing: -0.02em;
  color: #cabbc0;
}
.tp-footer-location a:hover {
  color: var(--tp-theme-primary);
}
.tp-footer-location-3 a {
  font-family: var(--tp-ff-onest);
  color: #d7c8be;
}
.tp-footer-number a {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.02em;
  color: #ffc978;
}
.tp-footer-number-3 a {
  font-family: var(--tp-ff-onest);
}
.tp-footer-btn a {
  font-weight: 500;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
}
.tp-footer-btn a:hover {
  color: var(--tp-theme-primary);
}
.tp-footer-btn-3 a {
  font-family: var(--tp-ff-onest);
  color: var(--tp-common-white2);
}
.tp-footer-btn span {
  margin-left: 7px;
}
.tp-footer-call-title {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: var(--tp-common-white);
  display: block;
}
.tp-footer-call-title-3 {
  font-family: var(--tp-ff-onest);
  color: var(--tp-common-white2);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-footer-widget {
    padding-left: 20px;
  }
}
@media (max-width: 575px) {
  .tp-footer-widget {
    padding-left: 0;
  }
}
.tp-footer-widget-title {
  font-size: 20px;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
  line-height: 1.3;
}
.tp-footer-widget-title-2 {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
}
.tp-footer-widget-title-3 {
  font-family: var(--tp-ff-onest);
  color: #ffcc86;
}
.tp-footer-widget-para {
  color: #cabbc0;
  letter-spacing: -0.02em;
}
.tp-footer-widget-para-3 {
  color: #d7c8be;
}
.tp-footer-widget-content ul li {
  list-style: none;
  margin-bottom: 2px;
}
.tp-footer-widget-content ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 187%;
  letter-spacing: -0.02em;
  color: #cabbc0;
  padding-left: 13px;
  position: relative;
}
.tp-footer-widget-content ul li a:hover {
  color: var(--tp-common-white);
}
.tp-footer-widget-content ul li a::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  background: #cabbc0;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border-radius: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-footer-widget-content-2 {
    margin-left: 0;
  }
}
.tp-footer-widget-content-2 ul {
  border-left: 1px solid #e8e8e8;
  padding: 23px 0 23px 30px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-footer-widget-content-2 ul {
    border-left: 0;
    padding: 23px 0 23px 0;
  }
}
.tp-footer-widget-content-2 ul li {
  margin-bottom: 6px;
}
.tp-footer-widget-content-2 ul li a {
  font-family: var(--tp-ff-onest);
  color: var(--tp-common-black);
}
.tp-footer-widget-content-2 ul li a:hover {
  color: var(--tp-theme-primary);
}
.tp-footer-widget-content-2 ul li a::before {
  background: var(--tp-common-black);
}
.tp-footer-widget-content-3 ul li a {
  font-family: var(--tp-ff-onest);
  color: #d7c8be;
}
.tp-footer-widget-content-3 ul li a:hover {
  color: #ffcc86;
}
.tp-footer-widget-content-3 ul li a::before {
  background: #d7c8be;
}
.tp-footer-input-btn {
  background: var(--tp-theme-primary);
  font-weight: 500;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
  padding: 9px 18px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  right: 4px;
}
.tp-footer-input-btn:hover {
  background: #e7256b;
}
.tp-footer-input-btn.footer-btn-2 {
  background: var(--tp-theme-2);
}
.tp-footer-input-btn.footer-btn-2:hover {
  background: var(--tp-common-falured);
}
.tp-footer-input-btn-2 {
  font-family: var(--tp-ff-onest);
  padding: 12px 31px;
  border-radius: 100px;
  letter-spacing: -0.02em;
  right: 6px;
}
.tp-footer-input-btn-3 {
  background: var(--tp-common-falured);
}
.tp-footer-input-btn-3:hover {
  background: var(--tp-theme-2);
}
.tp-footer-input-btn-4 {
  background: var(--tp-theme-2);
}
.tp-footer-input-btn-4:hover {
  background: var(--tp-common-falured);
}
.tp-footer-input-2 input {
  border: 1px solid #e6e6e6;
  border-radius: 100px;
  width: 100%;
  height: 60px;
  background: #f3f3f3;
  font-weight: 400;
  font-size: 16px;
  color: #a8a8a8;
  padding-right: 150px;
}
.tp-footer-input-2 input:focus {
  box-shadow: 0 0 0 4px rgba(183, 18, 77, 0.08), 0 1px 3px 0 rgba(71, 2, 27, 0.3);
  background: var(--tp-common-white);
}
.tp-footer-input-2 input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: 400;
  font-size: 16px;
  color: #a8a8a8;
}
.tp-footer-input-2 input::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: 400;
  font-size: 16px;
  color: #a8a8a8;
}
.tp-footer-input-2 input:-moz-placeholder {
  /* Firefox 4-18 */
  font-weight: 400;
  font-size: 16px;
  color: #a8a8a8;
}
.tp-footer-input-2 input:-ms-input-placeholder {
  /* IE 10+  Edge*/
  font-weight: 400;
  font-size: 16px;
  color: #a8a8a8;
}
.tp-footer-input-2 input::placeholder {
  /* MODERN BROWSER */
  font-weight: 400;
  font-size: 16px;
  color: #a8a8a8;
}
.tp-footer-input input {
  background: var(--tp-common-white);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #9d9c9d;
  height: 50px;
  width: 100%;
  padding-right: 120px;
}
.tp-footer-input input::placeholder {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #9d9c9d;
}
.tp-footer-input-3 input {
  background: #faf5ef;
}
.tp-footer-social a {
  color: #CABBC0;
  margin-left: 12px;
}
.tp-footer-social a svg {
  transform: translateY(-2px);
}
.tp-footer-social a:hover {
  color: var(--tp-common-white);
}
.tp-footer-social-2 a {
  font-size: 18px;
  color: var(--tp-grey-1);
  display: inline-block;
  border: 1px solid #e8e8e8;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-right: 3px;
}
.tp-footer-social-2 a svg {
  transform: translateY(-2px);
}
.tp-footer-social-2 a:hover {
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  border-color: var(--tp-theme-primary);
}
.tp-footer-bottom-2 {
  padding: 22px 0px 2px 0px;
}
.tp-footer-bottom-inner {
  border-top: 1px solid rgb(239, 239, 239);
  padding: 17px 0px 41px 0px;
}
.tp-footer-copyright p {
  letter-spacing: -0.02em;
  color: #cabbc0;
  margin: 0;
}
.tp-footer-copyright a {
  color: var(--tp-common-white);
}
.tp-footer-copyright a:hover {
  color: var(--tp-theme-primary);
}
.tp-footer-copyright-2 p {
  font-family: var(--tp-ff-onest);
  color: #5a5859;
}
.tp-footer-copyright-2 a {
  color: var(--tp-theme-primary);
}
.tp-footer-copyright-3 p {
  color: #d7c8be;
  font-family: var(--tp-ff-onest);
  text-align: center;
}
.tp-footer-copyright-3 a {
  color: #ffcc86;
  font-family: var(--tp-ff-onest);
}
@media (max-width: 575px) {
  .tp-footer-develop {
    text-align: left !important;
  }
}
.tp-footer-border-2 {
  border-top: 1px solid #e8e8e8;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-footer-card {
    text-align: left;
  }
}

/*----------------------------------------*/
/* 7.15 hero css
/*----------------------------------------*/
.tp-hero-content {
  padding-top: 140px;
  padding-bottom: 120px;
}
.tp-hero-content-area {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  right: 0;
  margin: 0 auto;
}
@media (max-width: 575px) {
  .tp-hero-content {
    padding-top: 100px;
    padding-bottom: 460px;
  }
}
.tp-hero-slider-active {
  height: 830px;
}
.tp-hero-arrow-box button {
  top: 50%;
  z-index: 99;
  left: 40px;
  position: absolute;
  transform: translateY(-50%);
  color: var(--tp-common-white);
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-arrow-box button {
    left: 15px;
  }
}
.tp-hero-arrow-box button:hover {
  color: rgba(255, 255, 255, 0.4);
}
@media (max-width: 575px) {
  .tp-hero-arrow-box button {
    top: auto;
    transform: translateY(0);
    bottom: 50px;
    right: 120px;
  }
}
.tp-hero-arrow-box button.tp-hero-prev {
  left: auto;
  right: 40px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-arrow-box button.tp-hero-prev {
    right: 15px;
  }
}
.tp-hero-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation: zoom 20s ease-in-out infinite;
  animation: zoom 20s ease-in-out infinite;
}
.tp-hero-subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
}
.tp-hero-title {
  font-size: 70px;
  line-height: 1.1;
  color: var(--tp-common-white);
  margin-bottom: 5px;
  display: inline-block;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero-title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-hero-title {
    font-size: 50px;
  }
}
@media (max-width: 575px) {
  .tp-hero-title {
    font-size: 34px;
  }
}
.tp-hero-form-input {
  display: inline-block;
}
.tp-hero-form-input p {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
  margin-bottom: 4px;
}
.tp-hero-form-input form {
  position: relative;
}
.tp-hero-form-input input {
  width: 220px;
  max-width: 220px;
  height: 50px;
  background: #f6f6f6;
  border: 1px solid #e4e4e4;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #9d9c9d;
  padding-left: 44px;
  flex: 0 0 auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-hero-form-input input {
    width: 207px;
  }
}
.tp-hero-form-input input:focus {
  background: none;
  color: var(--tp-common-black);
}
.tp-hero-form-input input.round-6 {
  background: none;
}
.tp-hero-form-input input.round-6.active {
  color: var(--tp-common-black);
  border-color: #d7d7d7;
  box-shadow: 0 3px 6px 0 rgba(20, 20, 20, 0.08), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(20, 20, 20, 0.08);
}
.tp-hero-form-input input.round-6.active::placeholder {
  color: var(--tp-common-black);
}
.tp-hero-form-input span {
  color: #9d9c9d;
  position: absolute;
  top: 47%;
  transform: translateY(-50%);
  left: 20px;
}
.tp-hero-form-input-two input {
  border-radius: 6px;
  background: none;
  width: 166px;
}
.tp-hero-form-input-two input:focus {
  box-shadow: 0 0 0 4px rgba(20, 20, 20, 0.03), 0 1px 2px 0 rgba(20, 20, 20, 0.08), 0 2px 6px 0 rgba(20, 20, 20, 0.04), 0 3px 6px 0 rgba(20, 20, 20, 0.08);
}
.tp-hero-form-quantity {
  display: inline-block;
  flex: 0 0 auto;
}
.tp-hero-form-quantity p {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
  margin-bottom: 4px;
}
.tp-hero-quantity-wrap {
  flex-wrap: wrap;
}
@media (max-width: 575px) {
  .tp-hero-quantity-wrap {
    flex-direction: column;
  }
}
.tp-hero-quantity-click {
  display: inline-block;
  border: 1px solid #e4e4e4;
  background: #f6f6f6;
  padding: 12px 22px;
  letter-spacing: -0.02em;
  color: #9d9c9d;
  cursor: pointer;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.tp-hero-quantity-click.active {
  background: none;
  color: var(--tp-common-black);
}
.tp-hero-quantity-click.active svg {
  color: #9d9c9d;
}
.tp-hero-quantity-click.round-6 {
  background: none;
}
.tp-hero-quantity-click.round-6.active {
  color: var(--tp-common-black);
  border-color: #d7d7d7;
  box-shadow: 0 3px 6px 0 rgba(20, 20, 20, 0.08), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(20, 20, 20, 0.08);
}
.tp-hero-quantity-inner {
  position: relative;
  height: 38px;
  width: 78px;
  display: inline-block;
  border: 1px solid #dedede;
}
.tp-hero-quantity-inner .tp-dreckment {
  top: 28%;
  height: 10px;
  line-height: 1;
}
.tp-hero-quantity-inner .tp-increment {
  height: 13px;
  top: 60%;
  line-height: 1;
}
.tp-hero-quantity-inner span {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #9D9C9D;
  transition: all 0.4s ease;
  cursor: pointer;
}
.tp-hero-quantity-inner span:hover {
  color: var(--tp-common-black);
}
.tp-hero-quantity-inner > input {
  max-width: 50px;
  border: none;
  padding: 0;
  padding-left: 14px;
  height: 36px;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
.tp-hero-quantity-content span {
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
.tp-hero-quantity-content p {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #9d9c9d;
}
.tp-hero-quantity-list li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px;
}
.tp-hero-quantity-list li .tp-hero-quantity-content {
  padding-right: 20px;
}
.tp-hero-quantity-border {
  width: 384px;
  box-shadow: 0 10px 20px 4px rgba(20, 20, 20, 0.14);
  background: var(--tp-common-white);
  margin-top: 6px;
}
@media (max-width: 575px) {
  .tp-hero-quantity-border {
    width: 296px;
    left: -34px;
  }
}
.tp-hero-quantity-btn {
  margin: 4px;
}
.tp-hero-quantity-btn button {
  background: #f2f2f2;
  text-align: center;
  display: block;
  padding: 6px 20px;
  margin-bottom: 0;
  width: 100%;
}
.tp-hero-quantity-btn button:hover {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
}
.tp-hero-quantity-active {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  margin-top: 0;
  position: absolute;
  top: 100%;
  left: 0;
}
@media (max-width: 575px) {
  .tp-hero-quantity-active {
    left: -12px;
  }
}
@media(max-width :991px){
  .datepicker{
    flex-direction: column;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero-quantity-active {
    left: auto;
    right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-hero-quantity-active.main {
    left: 0;
    right: auto;
  }
}
.tp-hero-quantity-active.tp-usd-list-open {
  opacity: 1;
  visibility: visible;
  margin-top: 6px;
  position: absolute;
  top: 100%;
  z-index: 999999;
}
.tp-hero-quantity-toggle-shadow {
  padding: 7px 0;
}
.tp-hero-quantity-toggle-shadow.active .tp-hero-two-location-active {
  color: var(--tp-common-black);
  border-color: #d7d7d7;
  box-shadow: 0 3px 6px 0 rgba(20, 20, 20, 0.08), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(20, 20, 20, 0.08);
}
.tp-hero-quantity-toggle-shadow.active .tp-hero-two-location-active .tp-hero-fild-border {
  border-color: #d7d7d7;
  box-shadow: inset 0 3px 6px 0 rgba(20, 20, 20, 0.08), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(20, 20, 20, 0.08);
}
.tp-hero-quantity-dedr {
  position: absolute;
  left: 0;
  right: 10px;
  text-align: center;
  top: 25%;
  width: 20px;
  margin: 0 auto;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-quantity-dedr {
    top: 26%;
    right: 0;
  }
}
.tp-hero-quantity-dedr button {
  z-index: 1;
  position: relative;
}
.tp-hero-submit-btn {
  padding: 13px 32px;
  background: var(--tp-theme-primary);
  color: var(--tp-common-white);
  position: relative;
  z-index: 9;
}
.tp-hero-submit-btn-2 {
  border-radius: 6px;
  padding: 13px 20px;
}
.tp-hero-submit-btn-2 svg {
  transform: translateY(-2px);
}
.tp-hero-submit-btn:hover {
  background: var(--tp-common-black);
}
.tp-hero-submit-btn-wrap {
  margin-top: 26px;
}
.tp-hero-submit-btn-wrap-2 {
  flex: 0 0 auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero-submit-btn-wrap {
    margin-top: 0;
  }
}
.tp-hero-date-form {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  bottom: 183px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-hero-date-form {
    bottom: 100px;
  }
}
@media (max-width: 575px) {
  .tp-hero-date-form {
    bottom: 50px;
  }
}
.tp-hero-search-form {
  background: var(--tp-common-white);
  padding: 24px;
  padding-top: 18px;
  padding-bottom: 4px;
  display: flex;
  justify-content: center;
}
@media (max-width: 575px) {
  .tp-hero-search-form {
    justify-content: flex-start;
  }
}
.tp-hero-fild-border {
  border: 1px solid #e4e4e4;
  border-radius: 50%;
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 10px;
  display: inline-block;
  right: -18px;
  background: var(--tp-common-white);
}
.tp-hero-fild-border.two {
  right: auto;
  left: -18px;
}
.tp-hero-two-wrap {
  margin-top: 1px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-two-wrap {
    position: relative;
  }
}
.tp-hero-two-wrap img {
  width: 100%;
}
.tp-hero-two-title {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.02em;
}
@media (max-width: 575px) {
  .tp-hero-two-title {
    font-size: 50px;
  }
}
.tp-hero-two-title-wrap {
  margin-top: -41px;
  position: relative;
  z-index: 99;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-two-title-wrap {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-two-location {
    width: 100%;
  }
}
.tp-hero-two-location-active {
  fill: var(--tp-common-white);
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  display: inline-block;
  width: 230px;
  padding: 12px 24px;
  font-family: var(--tp-ff-onest);
  letter-spacing: -0.02em;
  color: #9d9c9d;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
  cursor: pointer;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-two-location-active {
    width: 100%;
  }
}
.tp-hero-two-location-active svg {
  margin-right: 3px;
  transform: translateY(-2px);
}
@media (max-width: 575px) {
  .tp-hero-two-location-active svg {
    display: none;
  }
}
.tp-hero-two-location-icon {
  border: 1px solid #e0e0e0;
  border-radius: 100px;
  width: 36px;
  color: #989898;
  display: inline-block;
  height: 36px;
  text-align: center;
  line-height: 34px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-right: 12px;
  position: relative;
  z-index: 1;
}
.tp-hero-two-location-icon:before {
  position: absolute;
  content: "";
  width: 39px;
  height: 39px;
  border-radius: 50%;
  z-index: -1;
  top: -2px;
  left: -2px;
  background: var(--tp-common-white);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tp-hero-two-location-content {
  transform: translateY(-2px);
}
.tp-hero-two-location-content span {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  letter-spacing: -0.01em;
  color: var(--tp-common-black);
}
.tp-hero-two-location-content p {
  font-family: var(--tp-ff-onest);
  font-size: 13px;
  color: #605e5f;
  margin-bottom: 0;
  line-height: 1;
}
.tp-hero-two-location-list {
  width: 380px;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  box-shadow: 0 10px 30px -10px rgba(20, 20, 20, 0.2);
  background: var(--tp-common-white);
  padding: 6px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-hero-two-location-list {
    width: 320px;
  }
}
@media (max-width: 575px) {
  .tp-hero-two-location-list {
    width: 280px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-two-location-list-sm {
    left: auto;
    right: 0;
  }
}
.tp-hero-two-location-list ul li {
  list-style: none;
  display: flex;
  padding: 14px 18px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 7px;
  cursor: pointer;
}
.tp-hero-two-location-list ul li:hover {
  background: #f6f6f6;
}
.tp-hero-two-location-list ul li:hover .tp-hero-two-location-icon {
  background: var(--tp-common-white);
  border: 1px solid transparent;
}
.tp-hero-two-location-list ul li:hover .tp-hero-two-location-icon::before {
  transform: scale(1);
}
.tp-hero-two-search-form-inner {
  box-shadow: 0 20px 30px -6px rgba(20, 20, 20, 0.12), 0 2px 8px 0 rgba(20, 20, 20, 0.04);
  background: var(--tp-common-white);
  padding: 22px 0px 10px 0px;
  border-radius: 14px;
  margin: 0 78px;
  border: 1px solid #e8e8e8;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-two-search-form-inner {
    margin: 0;
  }
}
.tp-hero-two-search-location {
  padding: 30px 30px 0px 30px;
}
@media (max-width: 575px) {
  .tp-hero-two-search-location {
    padding: 30px 8px 0px 8px;
  }
}
.tp-hero-two-search-bottom {
  flex-wrap: wrap;
}
.tp-hero-two-quantity {
  margin-right: 10px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-two-quantity {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-two-sm-space {
    margin-right: 0;
  }
}
.tp-hero-two-shap {
  position: absolute;
  bottom: 50px;
  left: 200px;
  right: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero-two-shap {
    bottom: 0;
    left: 0;
    right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-two-shap {
    position: static;
    margin-top: -85px;
  }
}
@media (max-width: 575px) {
  .tp-hero-two-shap {
    position: static;
    margin-top: -15px;
  }
}
@media (max-width: 575px) {
  .tp-hero-two-shap-man {
    width: 30%;
  }
}
@media (max-width: 575px) {
  .tp-hero-two-shap-woman {
    width: 30%;
  }
}
.tp-hero-3-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 600;
  font-size: 100px;
  line-height: 1;
  letter-spacing: -0.04em;
  color: var(--tp-common-white2);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero-3-title {
    font-size: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-hero-3-title {
    font-size: 60px;
  }
}
@media (max-width: 575px) {
  .tp-hero-3-title {
    font-size: 38px;
  }
}
.tp-hero-3-title img {
  transform: translateY(-25px) translateX(5px);
  margin-left: 88px;
  z-index: -1;
  position: relative;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-3-title img {
    width: 70px;
    margin-left: 30px;
    transform: translateY(-8px) translateX(-11px);
  }
}
@media (max-width: 575px) {
  .tp-hero-3-title img {
    width: 45px;
  }
  .tp-hero-3-title-pre{
    text-align: center;
  }
  .tp-hero-3-title {
    text-align: center;
  }
}
.tp-hero-3-title span {
  transform: translateX(-30px);
  display: inline-block;
}
.tp-hero-3-title-pre {
  font-family: var(--tp-ff-amita);
  font-weight: 400;
  font-size: 22px;
  line-height: 136%;
  letter-spacing: 0.02em;
  color: var(--tp-common-white2);
}
.roomcard{
  width: 100%; /* Set width to 100% of the container */
  height: auto; /* Maintain aspect ratio */
 
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for depth */
}
@media (max-width: 995px) {
  .roomcard{
    width: 100%; /* Set width to 100% of the container */
    height: auto; /* Maintain aspect ratio */
   
    border-radius: 8px; /* Optional: Add rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for depth */
  }
}
@media (max-width: 995px) {
  
}
.tp-hero-3-spacing {
  padding-top: 290px;
  padding-bottom: 180px;
  background-image: url('../src/COMPONENT/img/hero/home3/bg.jpg');
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero-3-spacing {
    padding-top: 232px;
    padding-bottom: 120px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-3-spacing {
    padding-top: 180px;
    padding-bottom: 80px;
  }
}
.tp-hero-discount-wrap {
  padding: 17px 0px;
}
.tp-hero-discount-content p {
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1;
  color: var(--tp-common-black3);
  margin: 0;
}
.tp-hero-discount-content a {
  font-weight: 600;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.tp-hero-booking-form-tab {
  border-bottom: 1px solid #ebf0f5;
  padding: 0px 30px 0px 30px;
}
.tp-hero-booking-form-tab nav button {
  margin-right: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 18px;
  position: relative;
}
@media (max-width: 575px) {
  .tp-hero-booking-form-tab nav button {
    margin: 0;
    width: 50%;
    margin-bottom: 20px;
  }
}
.tp-hero-booking-form-tab nav button .borders {
  background: var(--tp-theme-primary);
  width: 0;
  height: 2px;
  bottom: -1px;
  left: auto;
  right: 0;
  position: absolute;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.tp-hero-booking-form-tab nav button.active .borders {
  width: 100%;
  left: 0;
  right: auto;
}
.tp-hero-booking-form-tab nav button.active .icon {
  color: var(--tp-theme-primary);
}
.tp-hero-booking-form-tab nav button.active span {
  color: var(--tp-theme-primary);
}
.tp-hero-booking-form-tab nav button .icon {
  margin-right: 6px;
  transform: translateY(-2px);
  color: #141414;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.tp-hero-booking-form-tab nav button span {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: #5a5859;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.tp-hero-booking-cetagory {
  display: flex;
  justify-content: end;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-booking-cetagory {
    padding: 20px 0px;
    justify-content: flex-start;
  }
}
.tp-hero-quantity-active-4 {
  width: 280px;
  border-radius: 10px;
  box-shadow: 0 10px 30px -10px rgba(20, 20, 20, 0.2);
  border: 1px solid #e4e4e4;
}
.tp-hero-quantity-active-4 .tp-hero-quantity-btn a {
  border-radius: 0 0 6px 6px;
}
.tp-hero-quantity-active-4 .tp-hero-quantity-inner {
  border-radius: 6px 6px 6px 6px;
}
.tp-hero-quantity-active-4 .tp-hero-quantity-inner > input {
  border-radius: 6px 0px 0px 6px;
}
.tp-hero-quantity-active-4.tp-usd-list-open-3 {
  opacity: 1;
  visibility: visible;
  margin-top: 6px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 999999;
}
.tp-hero-2-quantity-toggle-btn {
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #9d9c9d;
  cursor: pointer;
  transition: all 0.4s ease;
}
.tp-hero-2-quantity-toggle-btn.active {
  color: var(--tp-common-black);
}
.tp-hero-2-quantity-toggle-btn.active svg {
  color: var(--tp-common-black);
}
.tp-hero-2-booking-quantity-wrap {
  line-height: 1;
}
.tp-hero-cetagory-wrap {
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  width: 200px;
  box-shadow: 0 10px 30px -10px rgba(20, 20, 20, 0.2);
  background: var(--tp-common-white);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  margin-top: 0;
  position: absolute;
  top: 100%;
  left: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-cetagory-wrap {
    left: auto;
    right: 0;
  }
}
.tp-hero-cetagory-wrap.tp-usd-list-open-5 {
  opacity: 1;
  visibility: visible;
  margin-top: 6px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 999999;
}
.tp-hero-cetagory-checkbox {
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  padding: 12px 26px 12px 20px;
  margin: 4px;
  transition: all 0.4s ease;
}
.tp-hero-cetagory-checkbox:hover {
  background: #f5f5f5;
}
.tp-hero-cetagory-checkbox .tp-label {
  font-family: var(--tp-ff-onest);
  font-size: 14px;
  color: var(--tp-grey-1);
  cursor: pointer;
  padding-right: 15px;
}
.tp-hero-cetagory-checkbox .tp-input {
  background: var(--tp-common-white);
  appearance: none;
  position: relative;
  cursor: pointer;
}
.tp-hero-cetagory-checkbox .tp-input:checked {
  position: relative;
  background-color: var(--tp-theme-primary);
  border-color: transparent;
}
.tp-hero-cetagory-checkbox .tp-input:checked::after {
  box-sizing: border-box;
  position: absolute;
  content: "\f00c";
  font-weight: 700;
  font-family: var(--tp-ff-fontawesome);
  font-size: 18px;
  color: var(--tp-common-black);
  top: 46%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.tp-hero-birds {
  position: absolute;
  top: 20%;
  left: 30%;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-birds {
    top: 10%;
    left: 40%;
  }
}
.tp-hero-birds-2 {
  position: absolute;
  top: 15%;
  right: 20%;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-birds-2 {
    top: 5%;
    left: 50%;
  }
}
.tp-hero-birds-3 {
  position: absolute;
  top: 10%;
  right: 10%;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-birds-3 {
    top: 5%;
    left: 60%;
  }
}
.tp-hero-four-title {
  font-family: var(--tp-ff-onest);
  font-weight: 700;
  font-size: 66px;
  line-height: 1.12;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
@media (max-width: 575px) {
  .tp-hero-four-title {
    font-size: 46px;
  }
}
.tp-hero-four-title span {
  font-family: var(--tp-ff-amita);
}
.tp-hero-four-search-form {
  box-shadow: 0 20px 60px 0 rgba(20, 24, 32, 0.1);
  background: var(--tp-common-white);
  border-radius: 12px;
  position: relative;
  z-index: 1;
}
.tp-hero-four-content p {
  font-family: var(--tp-ff-onest);
  font-size: 18px;
  line-height: 26px;
  color: #3e3b3b;
}
@media (max-width: 575px) {
  .tp-hero-four-content p br {
    display: none;
  }
}
.tp-hero-four-right {
  margin-left: -120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-four-right {
    margin-left: 0;
    margin-top: 40px;
  }
}
.tp-hero-four-btn a {
  font-weight: 500;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: var(--tp-theme-primary);
  position: relative;
  z-index: 1;
}
.tp-hero-four-thumb-img img {
  border-radius: 12px;
}
.tp-hero-four-thumb-img-2 img {
  border-radius: 12px;
}
.tp-hero-four-thumb-img-3 img {
  border-radius: 12px;
}
.tp-hero-five-bg::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
}
.tp-hero-five-title {
  font-weight: 800;
  font-size: 50px;
  line-height: 1;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
}
@media (max-width: 575px) {
  .tp-hero-five-title {
    font-size: 36px;
  }
}
.tp-hero-five-content p {
  font-family: var(--tp-ff-dm);
  font-weight: 600;
  font-size: 18px;
  color: var(--tp-common-white);
}
.tp-hero-five-bottom {
  margin-top: -184px;
  position: relative;
  z-index: 1;
}
.tp-hero-five-video {
  height: 100% !important;
  line-height: 0.6;
  object-fit: cover;
  width: 100%;
}
.tp-hero-five-video-wrap .inline-YTPlayer {
  height: 300px !important;
}
.tp-hero-five-form-input .five {
  width: 186px;
}
.tp-hero-five-quantity-toggle .five {
  width: 300px;
  max-width: 300px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-hero-five-quantity-toggle .five {
    width: 270px;
    max-width: 270px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-five-quantity-toggle .five {
    width: 210px;
    max-width: 210px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-hero-five-quantity-toggle .tp-hero-two-location-active.five {
    width: 100%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-hero-six-submit-btn {
    margin-top: 0;
  }
}
.tp-hero-video-overly::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, 0.5);
  top: 0;
  left: 0;
}
.tp-hero-video-wrap {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
}
.tp-hero-video-wrap .inline-YTPlayer {
  height: 100%;
}
.tp-hero-video-wrap .inline-YTPlayer > div {
  height: 100% !important;
}
.tp-hero-video-wrap .inline-YTPlayer iframe {
  height: 100%;
}

.border-bottom {
  border-bottom: 1px solid #e8e8e8;
}

.wings {
  animation: 1s flying linear infinite alternate;
  transform: scale(0.3);
}

@keyframes flying {
  0% {
    d: path("M 5 5 Q 25 0 50 15 L 50 25 Q 25 5 5 5 M 95 5 Q 75 0 50 15 L 50 25 Q 75 5 95 5");
    width: 20px;
  }
  50% {
    d: path("M 5 10 Q 25 5 50 15 L 50 25 Q 25 10 5 10 M 95 10 Q 75 5 50 15 L 50 25 Q 75 10 95 10");
    width: 20px;
  }
  100% {
    d: path("M 7 30 Q 25 25 50 15 L 50 25 Q 25 30 7 30 M 93 30 Q 75 25 50 15 L 50 25 Q 75 30 93 30");
    width: 20px;
  }
}
.four-round-6 {
  border-radius: 6px;
}

.buttonBar {
  display: none;
}

/*----------------------------------------*/
/* 7.1 about css
/*----------------------------------------*/
.tp-about-list ul li {
  color: var(--tp-common-white);
  list-style: none;
  display: flex;
  margin-bottom: 20px;
}
.tp-about-list ul li span {
  margin-right: 18px;
}
.tp-about-list ul li p {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.02em;
}
.tp-about-shape {
  position: absolute;
  bottom: 0;
  left: 30px;
}
.tp-about-shape-2 {
  position: absolute;
  bottom: 0;
  left: 75px;
}
.tp-about-shape-3 {
  position: absolute;
  bottom: -65px;
  left: 140px;
  transform-origin: bottom;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-video {
    height: auto !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.tp-about-right-img {
  width: 47%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-right-img {
    position: inherit;
    width: 100%;
  }
}
.tp-about-spacing {
  margin: 0 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-spacing {
    margin: 10px;
  }
}
.tp-about-two-title {
  font-family: var(--tp-ff-amita);
  font-weight: 700;
  font-size: 30px;
}
.tp-about-two-spacing {
  padding-bottom: 243px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-two-spacing {
    padding-bottom: 120px;
  }
}
.tp-about-two-para {
  font-family: var(--tp-ff-onest);
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-two-para {
    font-size: 22px;
  }
}
.tp-about-two-item {
  border-bottom: 1px solid rgba(20, 20, 20, 0.14);
  padding-bottom: 9px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 575px) {
  .tp-about-two-item {
    margin-left: 0;
  }
}
.tp-about-two-item span {
  margin-right: 15px;
}
.tp-about-two-item p {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-two-content {
    padding-top: 40px;
  }
}
@media (max-width: 575px) {
  .tp-about-two-content {
    padding-top: 20px;
  }
}
.tp-about-3-content {
  padding-top: 110px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-3-content {
    padding-top: 0;
    margin-bottom: 40px;
  }
}
.tp-about-3-para {
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 18px;
  line-height: 167%;
  color: var(--tp-grey-4);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-3-para br {
    display: none;
  }
}
.tp-about-3-thumb {
  padding-right: 185px;
  width: 100%;
  transform: translateX(-24px);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-3-thumb {
    transform: translateX(0);
  }
}
@media (max-width: 575px) {
  .tp-about-3-thumb {
    padding-right: 0;
  }
}
.tp-about-3-thumb-2 {
  right: 0;
  position: absolute;
  bottom: -40px;
}
.tp-about-top-subtitle {
  font-family: var(--tp-ff-playfair);
  font-weight: 500;
  font-size: 18px;
  color: var(--tp-common-falured);
  margin-bottom: 12px;
}
.tp-about-top-subtitle span {
  border-radius: 100px;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: var(--tp-common-falured);
  margin-right: 8px;
}
.tp-about-top-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 700;
  font-size: 80px;
  line-height: 1.05;
  color: var(--tp-common-black4);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-top-title {
    font-size: 70px;
  }
  .tp-about-top-title br {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-top-title {
    font-size: 50px;
  }
}
.tp-about-top-title-2 {
  font-size: 60px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-top-title-2 {
    font-size: 45px;
  }
}
.tp-about-top-title span {
  font-style: italic;
  color: var(--tp-common-falured);
}
.tp-about-top-logo {
  display: inline-block;
}
.tp-about-top-logo-content {
  padding-left: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-top-logo-content {
    padding-left: 0;
  }
}
.tp-about-top-logo-content p {
  font-family: var(--tp-ff-onest);
  line-height: 24px;
  color: var(--tp-grey-5);
}
.tp-about-top-logo .shape {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
}
.tp-about-4-shape img {
  mix-blend-mode: hard-light;
}
.tp-about-4-content {
  padding-left: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-4-content {
    padding-left: 0;
  }
}
.tp-about-4-content p {
  font-family: var(--tp-ff-onest);
  line-height: 24px;
  color: var(--tp-grey-5);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-4-content p br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-4-content p {
    margin-bottom: 40px;
  }
}
.tp-about-4-client-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tp-about-4-client-img {
  margin-right: 12px;
}
.tp-about-4-client-review p {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--tp-common-black4);
  margin-bottom: 0;
}
.tp-about-4-expreance {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -87px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-4-expreance {
    top: 10%;
    right: 0;
  }
}
.tp-about-4-expreance-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}
.tp-about-4-expreance-year {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--tp-common-white);
  display: inline-block;
}
.tp-about-hotel-border {
  border: 1px solid rgba(160, 89, 22, 0.14);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-hotel-border {
    border: none;
  }
}
.tp-about-hotel-content p {
  font-family: var(--tp-ff-onest);
  font-size: 18px;
  line-height: 28px;
  color: var(--tp-grey-4);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-hotel-content p br {
    display: none;
  }
}
.tp-about-hotel-thumb {
  margin-right: 77px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-hotel-thumb {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-hotel-thumb {
    margin-bottom: 40px;
  }
}
.tp-about-hotel-subtitle {
  font-family: var(--tp-ff-playfair);
  font-size: 18px;
  color: var(--tp-theme-1);
  margin-bottom: 8px;
  display: inline-block;
}
.tp-about-hotel-content-two {
  padding-left: 55px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-hotel-content-two {
    padding-left: 0;
  }
}
.tp-about-hotel-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 700;
  font-size: 50px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: var(--tp-common-black4);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-about-hotel-title {
    font-size: 45px;
  }
}
.tp-about-hotel-feature ul li {
  list-style: none;
  display: flex;
  margin-bottom: 12px;
}
.tp-about-hotel-feature ul li i {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background: rgba(45, 26, 14, 0.1);
  color: #2d1a0e;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  transform: translateY(-2px);
  margin-right: 20px;
}
.tp-about-hotel-feature ul li span {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 14px;
  color: var(--tp-common-black4);
}
.tp-about-hotel-feature-two ul li {
  margin-bottom: 0;
  padding: 16px 0;
  border-bottom: 1px solid #e2dfeb;
}
.tp-about-hotel-feature-two ul li:last-child {
  border: none;
  padding-bottom: 0;
}
.tp-about-hotel-feature-two ul li b {
  width: 180px;
  max-width: 180px;
  transform: translateY(-1px);
}
.tp-about-hotel-feature-two ul li span {
  color: #5a5859;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.02em;
  margin-left: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .md-relative {
    position: relative;
  }
}
.bird {
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;
  animation-name: fly-cycle;
  animation-timing-function: steps(10);
  animation-iteration-count: infinite;
}
.bird--one {
  animation-duration: 1s;
  animation-delay: -0.5s;
}
.bird--two {
  animation-duration: 0.9s;
  animation-delay: -0.75s;
}
.bird--three {
  animation-duration: 1.25s;
  animation-delay: -0.25s;
}
.bird--four {
  animation-duration: 1.1s;
  animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  top: 20%;
  left: -10%;
  transform: scale(0) translateX(-10vw);
  will-change: transform;
  animation-name: fly-right-one;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.bird-container--one {
  animation-duration: 15s;
  animation-delay: 0;
}
.bird-container--two {
  animation-duration: 16s;
  animation-delay: 1s;
}
.bird-container--three {
  animation-duration: 14.6s;
  animation-delay: 9.5s;
}
.bird-container--four {
  animation-duration: 16s;
  animation-delay: 10.25s;
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}
@keyframes fly-right-one {
  0% {
    transform: scale(0.3) translateX(-10vw);
  }
  10% {
    transform: translateY(2vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(0vh) translateX(30vw) scale(0.5);
  }
  30% {
    transform: translateY(4vh) translateX(50vw) scale(0.6);
  }
  40% {
    transform: translateY(2vh) translateX(70vw) scale(0.6);
  }
  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.6);
  }
  60% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}
@keyframes fly-right-two {
  0% {
    transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }
  10% {
    transform: translateY(0vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }
  30% {
    transform: translateY(1vh) translateX(50vw) scale(0.45);
  }
  40% {
    transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }
  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.45);
  }
  51% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}
.tp-about-big-thumb {
  height: 700px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-big-thumb {
    height: 600px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-big-thumb {
    height: 500px;
  }
}
@media (max-width: 575px) {
  .tp-about-big-thumb {
    height: 400px;
  }
}

/*----------------------------------------*/
/*  7.13 feature css
/*----------------------------------------*/
.tp-feature-thumb {
  width: 180px;
  height: 180px;
  overflow: hidden;
  border-radius: 100%;
  box-shadow: 0 10px 20px 2px rgba(20, 20, 20, 0.14);
  border: 3px solid var(--tp-common-white);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: inline-block;
}
.tp-feature-thumb img {
  border-radius: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-feature-wrapper {
  padding: 40px 35px 20px 35px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-feature-wrapper {
    padding: 40px 10px 20px 10px;
  }
}
@media (max-width: 575px) {
  .tp-feature-wrapper {
    padding: 40px 15px 20px 15px;
  }
}
.tp-feature-wrapper:hover {
  box-shadow: 0 20px 30px 6px rgba(20, 20, 20, 0.1);
  background: var(--tp-common-white);
}
.tp-feature-wrapper:hover .tp-feature-thumb {
  box-shadow: none;
  border: 3px solid transparent;
}
.tp-feature-wrapper:hover .tp-feature-thumb img {
  transform: scale(1.1);
}
.tp-feature-content p {
  letter-spacing: -0.02em;
  color: var(--tp-grey-1);
  line-height: 1.4;
}
.tp-feature-title {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 13px;
}
.tp-feature-title a:hover {
  color: var(--tp-theme-primary);
}
.tp-feature-border {
  border-right: 1px solid #e8e8e8;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-feature-border {
    border: none;
  }
}

/*----------------------------------------*/
/* 7.25 testimonial css
/*----------------------------------------*/
.tp-testimonial-ratings span {
  color: #FF9E0D;
  font-size: 12px;
}
.tp-testimonial-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 93%;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-testimonial-wrapper {
    padding: 0 20px;
  }
}
.tp-testimonial-content p {
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.02em;
  color: var(--tp-grey-1);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-testimonial-content p {
    font-size: 22px;
  }
}
@media (max-width: 575px) {
  .tp-testimonial-content p {
    font-size: 18px;
  }
}
.tp-testimonial-clients img {
  margin-bottom: 8px;
  border-radius: 50%;
}
.tp-testimonial-clients-name {
  display: block;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
  line-height: 1;
}
.tp-testimonial-clients-lucation {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #9d9c9d;
}
.tp-testimonial-pagination {
  text-align: center;
  margin-top: 30px;
}
@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

body {
    background-color: #e0e3e8;
    font-family: "Rubik", sans-serif;
}
/* ---Sidebar css */
.text-primary{
  color: #5a8dee!important;
}
.btn-primary {
    border-color: #5a8dee !important;
    background-color: #5a8dee !important;
    color: #fff;
}
.shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
  border-radius: 5px;
}
.sidebar{
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -600%;
    z-index: 1;
    transition: 0.5s;
}
.sidebar.active{
    left: 0;
}
.sd-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}
.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
}
.sd-body{
    padding: 15px;
    max-height: calc(100vh - 67px);
    overflow-x: hidden;
}
.sd-body ul{
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
}
.sd-body ul li{
    list-style: none;
    margin-bottom: 8px;
}
.sd-body ul li .sd-link{
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #475f7b;
    background-color: #e5e8ec;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}
.tp-testimonial-pagination span.swiper-pagination-bullet {
  width: 40px;
  height: 2px;
  background: #e4e4e4;
  opacity: 1;
  border-radius: 0;
}
.tp-testimonial-pagination span.swiper-pagination-bullet-active {
  background: var(--tp-common-black);
}
.tp-testimonial-two-border {
  position: relative;
  background: var(--tp-common-white);
  border-radius: 12px;
}
.tp-testimonial-two-border:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  z-index: -1;
  margin: -2px;
  height: 100%;
  opacity: 0.3;
  border-radius: 14px;
  width: calc(100% + 4px);
  opacity: 0.3;
  transition: all 0.3s ease-out 0s;
  background: -moz-linear-gradient(90deg, white 0%, #e8e8e8 100%, white 100%);
  background: -webkit-linear-gradient(90deg, white 0%, #e8e8e8 100%, white 100%);
  background: -ms-linear-gradient(90deg, white 0%, #e8e8e8 100%, white 100%);
}
.tp-testimonial-two-para {
  font-family: var(--tp-ff-onest);
  font-size: 24px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: var(--tp-common-black2);
}
.tp-testimonial-two-rating {
  border-radius: 100px;
  border: 1.5px solid #e8e8e8;
  display: inline-block;
  line-height: 1;
  padding: 3px 7px;
  padding-top: 0;
}
.tp-testimonial-two-rating span {
  color: #FF9E0D;
  font-size: 12px;
  line-height: 1;
}
.tp-testimonial-two-rating span i {
  line-height: 1;
}
.tp-testimonial-two-thumb {
  margin-left: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-testimonial-two-thumb {
    margin-left: 0;
  }
  .tp-testimonial-two-thumb img {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-testimonial-two-title {
    padding-left: 20px;
  }
}
.tp-testimonial-avater-title {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}
.tp-testimonial-avater-location {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  letter-spacing: -0.02em;
  color: #9d9c9d;
}
.tp-testimonial-avater-thumb img {
  border-radius: 50%;
}
.tp-testimonial-arrow-box button {
  background: transparent;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 50%;
}
.tp-testimonial-arrow-box button:hover {
  background: rgba(246, 246, 246, 0.97);
}
.tp-testimonial-tree {
  position: absolute;
  top: 163px;
  right: 0;
  width: 17%;
  transform-origin: bottom right;
}

/*----------------------------------------*/
/*  7.10 cta css
/*----------------------------------------*/
.tp-cta-plane {
  right: 127px;
  top: -32px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-cta-plane {
    top: -50px;
  }
}
.tp-cta-content p {
  font-family: var(--tp-ff-amita);
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: var(--tp-common-black);
}

/*----------------------------------------*/
/*  7.8 contact css
/*----------------------------------------*/
.tp-contact-shape {
  position: absolute;
  bottom: 19%;
  left: 20%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-contact-shape {
    left: 2%;
  }
}
.tp-contact-shape-2 {
  position: absolute;
  top: 38%;
  right: 18%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-contact-shape-2 {
    right: 3%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-contact-shape-2 {
    right: 0;
  }
}
.tp-contact-bg {
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 7.31%, #ffadad 100%);
  border-radius: 20px;
}
.align-items-center{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-btn-sec{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.toggale{
  display: none;
}
@media  (max-width: 992px) {
  .toggale{
    display: block;
  }
  .logo-normal{
    height: 50px !important;
  }
  .logo-sticky{
    display: block;
  }
}
  .logo-normal{
  height: 80px;
}
@media  (max-width: 992px) {
  .tp-booking-form-btn-header {
    padding: 0px 10px !important;
  }
}
.tp-booking-form-btn-header{
  background: var(--tp-theme-2);
  /* width: 30%; */
  padding: 10px 10px;
  color: var(--tp-common-white);
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 16px;
  color: var(--tp-common-white2);
  display: flex
;
  align-items: center;
  justify-content: center;
}
@media  (max-width: 522px) {

  .tp-booking-form-btn-header{ 
    font-size: 10px !important;

  }
}
@media  (max-width: 920px) {
  .first-li-header{
    display: none !important;
  }
}
.tp-contact-subtitle {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 14px;
  color: var(--tp-common-white);
  background: var(--tp-theme-2);
  display: inline-block;
  border-radius: 6px;
  padding: 2px 14px;
}
.tp-contact-subtitle-2 {
  background: var(--tp-theme-primary);
}
.tp-contact-title {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 56px;
  letter-spacing: -0.02em;
  color: #323232;
  margin-bottom: 17px;
}
.tp-contact-title-2 {
  font-size: 40px;
}
@media (max-width: 575px) {
  .tp-contact-title-2 {
    font-size: 36px;
  }
}
.tp-contact-dis {
  font-family: var(--tp-ff-onest);
  line-height: 24px;
  color: var(--tp-grey-5);
  margin-bottom: 33px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-contact-dis br {
    display: none;
  }
}
.tp-contact-list ul {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-contact-list ul {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
.tp-contact-list ul li {
  list-style: none;
  display: flex;
}
.tp-contact-list ul li p {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
  color: #323232;
}
.tp-contact-list ul li span {
  margin-right: 8px;
  transform: translateY(-3px);
}
.tp-contact-list ul li:not(:last-child) {
  margin-right: 30px;
}
.tp-contact-icon {
  display: inline-block;
  margin-bottom: 50px;
}
.tp-contact-icon i {
  color: #323232;
  font-size: 24px;
}
.tp-contact-text h5 {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 18px;
  color: #323232;
  margin-bottom: 3px;
}
.tp-contact-text a {
  background-image: linear-gradient(#b7124d, #b7124d), linear-gradient(#b7124d, #b7124d);
  background-size: 0 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s linear;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tp-contact-text a:hover {
  background-image: linear-gradient(#b7124d, #b7124d), linear-gradient(#b7124d, #b7124d);
  background-size: 0 1px, 100% 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s linear;
  color: var(--tp-theme-primary);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tp-contact-text span {
  font-family: var(--tp-ff-onest);
  font-size: 15px;
  color: #323232;
}
.tp-contact-item {
  box-shadow: 0 20px 30px 6px rgba(20, 20, 20, 0.1);
  background: var(--tp-common-white);
  border-radius: 16px;
  padding: 34px 30px 30px 35px;
}
.tp-contact-bg {
  padding: 100px 100px 100px 100px;
  background-repeat: no-repeat;
  background-position: top;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-contact-bg {
    padding: 100px 60px 100px 60px;
  }
}
@media (max-width: 575px) {
  .tp-contact-bg {
    padding: 100px 20px 100px 20px;
  }
}

/*----------------------------------------*/
/* 7.17 place css
/*----------------------------------------*/
.tp-place-wrap:hover .tp-place-thumb img {
  transform: scale(1.1);
}
.tp-place-wrap:hover .tp-place-content {
  box-shadow: 0 1px 2px 0 rgba(20, 20, 20, 0.24);
}
.tp-place-thumb {
  border-radius: 6px 6px 0px 0px;
}
.tp-place-thumb img {
  transition: all 0.6s ease;
}
.tp-place-offer {
  font-family: var(--tp-ff-inter);
  font-weight: 800;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--tp-common-white);
  background: var(--tp-common-black);
  padding: 5px 13px;
  border-radius: 100px;
  position: absolute;
  top: 25px;
  left: 20px;
}
.tp-place-wishlist {
  width: 34px;
  height: 34px;
  background: var(--tp-common-white);
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
}
.tp-place-wishlist .red-heart {
  opacity: 0;
  position: absolute;
  top: 52%;
  left: 0;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
}
.tp-place-title {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 24px;
  line-height: 125%;
  letter-spacing: -0.01em;
}
.tp-place-title a:hover {
  color: var(--tp-theme-primary);
}
.tp-place-content {
  background: var(--tp-common-white);
  box-shadow: 0 20px 30px -20px rgba(20, 20, 20, 0.2);
  border-radius: 0px 0px 6px 6px;
  transition: all 0.4s ease;
}
.tp-place-meta-wrap {
  padding: 25px 30px 30px 30px;
  border-bottom: 1px solid #e8e8e8;
}
.tp-place-meta ul {
  display: flex;
  align-items: center;
}
.tp-place-meta ul li {
  list-style: none;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #5a5859;
  margin-right: 15px;
}
.tp-place-meta ul li svg {
  margin-top: -4px;
  margin-right: 2px;
}
.tp-place-old-price {
  color: #9e989b;
  font-family: var(--tp-ff-onest);
  font-size: 15px;
}
.tp-place-price {
  font-weight: 600;
  color: var(--tp-common-black);
  font-family: var(--tp-ff-onest);
  letter-spacing: -0.02em;
  margin-right: 6px;
  font-size: 20px;
}
.tp-place-price-discount {
  font-family: var(--tp-ff-onest);
  font-size: 15px;
  line-height: 173%;
  letter-spacing: -0.02em;
  color: #5a5859;
}
.tp-place-price-wrap {
  padding: 13px 30px 14px 30px;
  display: flex;
  justify-content: space-between;
}
.tp-place-price-inner {
  align-items: center;
}
.tp-place-btn {
  margin-top: -42px;
}
.tp-place-btn a {
  width: 50px;
  height: 50px;
  background: #f2f2f2;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  display: inline-block;
}
.tp-place-btn a:hover {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
}
.tp-place-tab-btn {
  border: 1px solid #d7d7d7;
  border-radius: 100px;
  padding: 6px 20px;
  font-weight: 500;
  font-size: 14px;
  color: var(--tp-common-black);
  display: flex;
  align-items: center;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-right: 6px;
  margin-bottom: 5px;
}
.tp-place-tab-btn.active {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  border: 1px solid var(--tp-common-black);
}
.tp-place-tab-btn.active:hover {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  border: 1px solid var(--tp-common-black);
}
.tp-place-tab-btn:hover {
  background: #f2f2f2;
  border: 1px solid transparent;
  color: var(--tp-common-black);
}
.tp-place-tab-btn svg {
  margin-right: 7px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-place-right-btn {
    text-align: left;
  }
}

/*----------------------------------------*/
/* 7.22 service css
/*----------------------------------------*/
.tp-service-pagination {
  text-align: center;
  margin-top: 30px;
}
.tp-service-pagination span {
  width: 40px;
  height: 2px;
  background: #e4e4e4;
  opacity: 1;
  border-radius: 0;
}
.tp-service-pagination span.swiper-pagination-bullet-active {
  background: var(--tp-common-black);
}
.tp-service-3-content {
  position: absolute;
  top: 77%;
  background: var(--tp-common-white2);
  left: 20px;
  right: 20px;
  bottom: 30px;
  padding: 17px 43px;
  overflow: hidden;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  transition-delay: 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  /* .tp-service-3-content {
    top: 80%;
  } */
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-service-3-content {
    /* top: 79%; */
    left: 20px;
    right: 20px;
    bottom: 20px;
    padding: 17px 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-service-3-content {
    padding: 22px 20px 17px 20px;
    top: 76%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-service-3-content {
    padding: 17px 14px;
    top: 86%;
  }
}
@media (max-width: 575px) {
  .tp-service-3-content {
    padding: 22px 20px 17px 20px;
    top: 72%;
  }
}
.tp-service-3-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 500;
  font-size: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--tp-common-black4);
  margin-bottom: 0;
}
.tp-service-3-para {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
}
.tp-service-3-para p {
  font-family: var(--tp-ff-onest);
  font-size: 15px;
  line-height: 24px;
  color: #635c57;
  margin-bottom: 23px;
}
.tp-service-3-para .tp-btn-4 {
  padding: 8px 28px;
}
.tp-service-3-thumb img {
  transition: all 0.4s ease;
}
.tp-service-3-wrap {
  overflow: hidden;
}
.tp-service-3-wrap:hover .tp-service-3-thumb img {
  transform: scale(1.1);
}
.tp-service-3-wrap:hover .tp-service-3-content {
  transition-delay: 0s;
  top: 68%;
  padding: 22px 13px 17px 13px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-service-3-wrap:hover .tp-service-3-content {
    top: 44%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-service-3-wrap:hover .tp-service-3-content {
    padding: 22px 20px 17px 20px;
    top: 38%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-service-3-wrap:hover .tp-service-3-content {
    top: 61%;
  }
}
@media (max-width: 575px) {
  .tp-service-3-wrap:hover .tp-service-3-content {
    padding: 22px 20px 17px 20px;
    top: 62%;
  }
}
.tp-service-3-wrap:hover .tp-service-3-para {
  margin-bottom: 10px;
  opacity: 1;
  visibility: visible;
}

.border-radius-10 {
  border-radius: 10px;
}

/*----------------------------------------*/
/*  7.11 product css
/*----------------------------------------*/
.tp-destinations-thumb {
  position: relative;
  transform: scale3d(1, 1, 1);
  transition: transform 0.65s cubic-bezier(0.23, 1, 0.32, 1);
}
.tp-destinations-thumb::after {
  position: absolute;
  content: "";
  background: linear-gradient(180deg, rgba(9, 3, 3, 0.08) 0%, rgba(9, 3, 3, 0) 51.4%, rgba(9, 3, 3, 0.6) 100%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 12px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-destinations-thumb img {
  width: 100%;
  border-radius: 12px;
  transform: scale(1.2);
  transition: transform 0.65s cubic-bezier(0.23, 1, 0.32, 1);
  width: 100%;
}
.tp-destinations-wrap {
  border-radius: 12px;
}
.tp-destinations-wrap:hover .tp-destinations-thumb::after {
  opacity: 1;
}
.tp-destinations-wrap:hover .tp-destinations-time {
  opacity: 1;
  visibility: visible;
}
.tp-destinations-wrap:hover .tp-destinations-content {
  transform: translateY(-22px);
}
.tp-destinations-content {
  position: absolute;
  bottom: 0;
  left: 25px;
  transform: translateY(14px);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-destinations-time {
  font-family: var(--font-family);
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-destinations-time .dvdr {
  margin: 0 8px;
}
.tp-destinations-title {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 3px;
  color: var(--tp-common-white);
}
.tp-destinations-rating {
  position: absolute;
  top: 15px;
  right: 15px;
  backdrop-filter: blur(8px);
  background: rgba(20, 20, 20, 0.08);
  border: 1px solid rgba(20, 20, 20, 0.08);
  border-radius: 100px;
  padding: 0px 7px;
}
.tp-destinations-rating span {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
}
.tp-destinations-rating span i {
  margin-right: 4px;
}
.tp-destinations-box {
  transform: scale3d(1, 1, 1);
  transition: transform 0.65s cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 12px;
}
.tp-destinations-box:hover {
  transform: scale3d(1.09, 1.09, 1);
}
.tp-destinations-box:hover .tp-destinations-thumb img {
  transform: scale(1);
}
.tp-destinations-details-thumb img {
  border-radius: 12px;
}
.tp-destinations-main {
  background: #d9d9d9;
  border-radius: 12px;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.tp-destinations-main:hover .tp-destinations-thumb {
  transform: scale3d(1.09, 1.09, 1);
}
.tp-destinations-main.shadows:hover {
  box-shadow: 0 20px 34px -14px rgba(51, 2, 27, 0.7);
}
.tp-destinations-main.shadows-1:hover {
  box-shadow: 0 20px 34px -14px rgba(109, 62, 6, 0.7);
}
.tp-destinations-main.shadows-2:hover {
  box-shadow: 0 20px 34px -14px rgba(1, 47, 40, 0.7);
}
.tp-destinations-main.shadows-3:hover {
  box-shadow: 0 20px 34px -14px rgba(62, 34, 3, 0.7);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-destinations-arrow-box {
    padding-top: 20px;
  }
}
@media (max-width: 575px) {
  .tp-destinations-arrow-box {
    justify-content: flex-start !important;
  }
}
.tp-destinations-arrow-box .tp-destinations-next {
  margin-left: 15px;
}
.tp-destinations-arrow-box button {
  color: #5A5859;
  font-size: 19px;
  background: transparent;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 50%;
}
.tp-destinations-arrow-box button:hover {
  background: rgba(246, 246, 246, 0.97);
}
.tp-destinations-slide {
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 12px;
  padding-right: 12px;
}

/*----------------------------------------*/
/* 7.26 video css
/*----------------------------------------*/
.tp-video-play {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tp-common-white);
  text-align: center;
  line-height: 100px;
  font-size: 16px;
  color: var(--tp-theme-primary);
  position: relative;
  transition: all 0.4s ease;
  right: 80px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.tp-video-play span {
  margin-left: 6px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-video-play {
    right: 40%;
    top: -50%;
  }
}
.tp-video-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 500;
  font-size: 60px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
}
.tp-video-title a {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-style: italic;
  text-decoration-thickness: 2px;
  text-underline-offset: 6px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-video-title {
    font-size: 44px;
  }
}
@media (max-width: 575px) {
  .tp-video-title {
    font-size: 40px;
  }
}
.tp-video-three-spacing {
  padding-top: 250px;
  padding-bottom: 225px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-video-three-spacing {
    padding-top: 117px;
    padding-bottom: 110px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-video-three-spacing {
    padding-top: 150px;
    padding-bottom: 125px;
  }
}
.tp-video-three-play {
  width: 100px;
  height: 100px;
  display: inline-block;
  line-height: 100px;
  border-radius: 50%;
  border: 1px solid #fff;
}
.tp-video-great-work {
  border-top: 1px solid rgba(250, 245, 239, 0.2);
  padding: 48px 0;
}
.tp-video-great-content p {
  font-family: var(--tp-ff-playfair);
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin-bottom: 0;
  color: var(--tp-common-white2);
}
.tp-video-great-content p a {
  display: inline-block;
  transition: all 0.4s ease;
}
.tp-video-great-content p a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-video-great-content p {
    font-size: 22px;
  }
}
.tp-video-great-content svg {
  margin-left: 4px;
}

.tp-pulse-border {
  border-radius: 50%;
  z-index: 1;
}
.tp-pulse-border::after, .tp-pulse-border::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #fff;
  animation: borderanimate2 2s linear infinite;
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  z-index: -1;
}
.tp-pulse-border::before {
  animation-delay: 0.7s;
}
.third-colum{
  width: 33% !important;
}
.first-colum{
  width: 23% !important;
}
.second-colum{
  width: 43% !important;
}
.container-banefit-area{
  padding: 24px 182px !important;
}

.container-banefit-area .row{
  display: flex;
  align-items: baseline;
  justify-content: center;
}
@media  (max-width: 992px) {
  .container-banefit-area .row{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .container-banefit-area{
    padding: 0px 0px !important;
  }
  .thumbnails{
    display: none !important;
  }
  .tp-banefit-title{
    font-size: 26px;
  }
  .third-colum{
    width: 100% !important;
    align-items: center;
    justify-content: left;
    display: flex
;
  }
  .first-colum{
    width: 100% !important;
    align-items: center;
    justify-content: left;
    display: flex
;
  }
  .second-colum{
    width: 100% !important;
    align-items: center;
    justify-content: left;
    display: flex
;
  }
}
/*----------------------------------------*/
/*  7.18 pricing css
/*----------------------------------------*/
.tp-pricing-title h5 {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-pricing-title h5 {
    margin-left: 0;
  }
}
.tp-pricing-title span {
  height: 40px;
  width: 1px;
  background: #e8e8e8;
  display: inline-block;
  position: absolute;
  right: 0;
  top: -11px;
}
@media (max-width: 575px) {
  .tp-pricing-title span {
    display: none;
  }
}
.tp-pricing-price span {
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-common-black);
  line-height: 92%;
}
.tp-pricing-price-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 92%;
  letter-spacing: -0.02em;
  margin-bottom: 3px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-pricing-btn-wrap {
    text-align: left !important;
  }
}
.tp-pricing-service-list ul li {
  line-height: 1.7;
  letter-spacing: -0.02em;
  color: var(--tp-grey-1);
  position: relative;
  list-style: none;
  padding-left: 14px;
}
.tp-pricing-service-list ul li::before {
  background: var(--tp-grey-1);
  font-size: 12px;
  position: absolute;
  content: "";
  left: 0;
  top: 12px;
  width: 3px;
  border-radius: 20px;
  height: 3px;
}
.tp-pricing-wrapper {
  box-shadow: 0 1px 0 0 #e8e8e8, 0 -1px 0 0 #e8e8e8;
  background: #fff;
  padding: 23px 20px;
  padding-bottom: 3px;
}
.tp-pricing-wrapper-2 {
  padding: 33px 40px;
  padding-bottom: 13px;
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.14);
}
.tp-pricing-wrapper-2 .tp-btn-square {
  color: var(--tp-common-black4);
  border: 1px solid var(--tp-common-black4);
}
.tp-pricing-wrapper-2 .tp-btn-square:hover {
  color: var(--tp-common-white);
  background: var(--tp-common-falured);
  border-color: var(--tp-common-falured);
}
.tp-pricing-wrapper-2 .tp-pricing-title h5 {
  font-family: var(--tp-ff-onest);
  color: var(--tp-common-black4);
}
.tp-pricing-wrapper-2 .tp-pricing-title span {
  right: -28px;
}
.tp-pricing-wrapper-2 .tp-pricing-price {
  margin-left: 30px;
}
@media (max-width: 575px) {
  .tp-pricing-wrapper-2 .tp-pricing-price {
    margin-left: 0;
  }
}
.tp-pricing-wrapper-2 .tp-pricing-price-title {
  font-family: var(--tp-ff-onest);
  color: var(--tp-common-black4);
}
.tp-pricing-wrapper-2 .tp-pricing-price span {
  color: var(--tp-grey-5);
}
.tp-pricing-wrapper-2 .tp-pricing-service-list ul li {
  color: var(--tp-grey-5);
}

/*----------------------------------------*/
/* 7.24 team css
/*----------------------------------------*/
.tp-team-content {
  border: 1px solid #fff;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 20px 11px 20px;
  opacity: 1;
  left: 20px;
  bottom: 20px;
  width: calc(100% - 40px);
  height: auto;
  -webkit-transform: translateY(0) scale(1, 0);
  transform: translateY(0) scale(1, 0);
  transform-origin: bottom center;
  position: absolute;
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
}
.tp-team-item:hover .tp-team-content {
  opacity: 1;
  -webkit-transform: translateY(0) scale(1, 1);
  transform: translateY(0) scale(1, 1);
}
.tp-team-item:hover .tp-team-title {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.2s;
}
.tp-team-item:hover .tp-team-subtitle {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.3s;
}
.tp-team-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 700;
  font-size: 20px;
  color: var(--tp-common-black4);
  margin-bottom: 2px;
  opacity: 0;
  transform: translateY(15px);
  -webkit-transition: all 0.4s cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 0.4s cubic-bezier(0.37, 0, 0.63, 1);
}
.tp-team-subtitle {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
  color: var(--tp-grey-5);
  transform: translateY(10px);
  -webkit-transition: all 0.4s cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 0.4s cubic-bezier(0.37, 0, 0.63, 1);
  margin-bottom: 0;
  opacity: 0;
}

.box-transition {
  overflow: hidden;
}
.box-transition img {
  -webkit-transition: all 0.4s cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 0.4s cubic-bezier(0.37, 0, 0.63, 1);
}
.box-transition:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*----------------------------------------*/
/* 7.6 brand css
/*----------------------------------------*/
.tp-brand-slide-active .swiper-slide {
  width: auto;
}
.tp-brand-border {
  border-top: 1px solid #E8E8E8;
}
.tp-brand-border-2 {
  border-top: 1px solid rgba(160, 89, 22, 0.14);
}
.tp-brand-item {
  padding: 40px 65px;
}
.tp-brand-item img {
  filter: saturate(0);
}

.slide-transtion {
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.tp-text-slider {
  padding: 24px 0px 20px 0px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-text-slider {
    padding: 24px 0px 12px 0px;
  }
}
.tp-text-slider-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 600;
  font-size: 60px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: var(--tp-common-white2);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-text-slider-title {
    font-size: 45px;
  }
}
.tp-text-slider-title svg {
  margin-left: 10px;
  -webkit-animation: rotate-infinite 15s linear infinite;
  animation: rotate-infinite 15s linear infinite;
  margin-top: -12px;
}
.tp-text-slider-content-2 .tp-text-slider-title {
  font-size: 20px;
  letter-spacing: 0.04em;
  margin-bottom: 0;
}
.tp-text-slider-content-2 .tp-text-slider-title svg {
  margin-left: 16px;
  margin-top: -5px;
}
.tp-text-spacing {
  padding: 18px 0px 18px 0px;
}

.tp-brand-active .swiper-slide {
  width: 222px;
}

.tp-brand-active-2 .swiper-slide {
  width: auto;
}

/*----------------------------------------*/
/* 7.23 suites css
/*----------------------------------------*/
.tp-suites-item {
  overflow: hidden;
  z-index: 2;
}
.tp-suites-item:hover .tp-suites-hidden {
  opacity: 1;
  visibility: visible;
}
.tp-suites-item:hover .tp-suites-content {
  bottom: 30px;
}
.tp-suites-item:hover .tp-suites-thumb img {
  transform: scale(1.1);
}
.tp-suites-thumb {
  position: relative;
}
.tp-suites-thumb img {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-suites-thumb::before {
  background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.8) 100%);
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.tp-suites-price {
  top: 30px;
  right: 0;
}
.tp-suites-price span {
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.02em;
  padding: 5px 10px;
  display: inline-block;
  background: var(--tp-common-white);
}
.tp-suites-price.price-radius span {
  border-radius: 6px 0px 0px 6px;
}
.tp-suites-content {
  bottom: -102px;
  left: 30px;
  right: 30px;
  z-index: 3;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-suites-title {
  font-size: 24px;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
  font-weight: 700;
  margin-bottom: 5px;
}
.tp-suites-room .space {
  margin: 0 8px;
}
.tp-suites-room span {
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
}
.tp-suites-hidden {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-suites-hidden p {
  font-size: 16px;
  line-height: 1.37;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
  margin-bottom: 23px;
}

/*----------------------------------------*/
/* 7.19 review css
/*----------------------------------------*/
.tp-review-inner {
  padding: 30px 35px 25px 35px;
  background: var(--tp-common-white);
}
.tp-review-inner-2 {
  background: #f6f6f6;
  border-radius: 12px;
}
.tp-review-rating {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}
.tp-review-rating-star {
  color: #ffa81a;
  font-size: 18px;
  transform: translateY(-2px);
  margin-right: 8px;
}
.tp-review-rating-title {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.01em;
  color: var(--tp-common-black4);
  margin-bottom: 0;
  margin-right: 9px;
}
.tp-review-rating-number {
  font-family: var(--tp-ff-onest);
  font-size: 14px;
  color: var(--tp-grey-1);
}
.tp-review-rating-content {
  font-family: var(--tp-ff-onest);
  font-size: 15px;
  color: var(--tp-common-black4);
  width: 90px;
  margin-right: 8px;
  transform: translateY(-1px);
  flex: 0 0 auto;
}
.tp-review-rating-progress {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  flex-wrap: wrap;
}
.tp-review-rating-progress-2 .tp-review-rating-bar .single-progress {
  background: var(--tp-theme-primary);
}
.tp-review-rating-bar {
  width: 100%;
  background: rgba(160, 89, 22, 0.14);
  border-radius: 5px;
  min-width: 184px;
  height: 4px;
  margin-right: 17px;
}
.tp-review-rating-bar .single-progress {
  background-color: var(--tp-common-falured);
  border-radius: 5px;
  height: 100%;
  width: 100%;
}
.tp-review-count p {
  font-family: var(--tp-ff-onest);
  font-size: 15px;
  color: var(--tp-grey-1);
}
.tp-review-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.tp-review-rates span {
  font-size: 14px;
  color: #ffa81a;
  margin-right: 3px;
}
.tp-review-rates-wrap {
  padding: 26px 35px 15px 32px;
  background: var(--tp-common-white);
}
.tp-review-rates-wrap-2 {
  background: #f6f6f6;
  border-radius: 12px;
}
.tp-review-rates-wrap-2 .tp-review-stats {
  color: var(--tp-common-black);
}
.tp-review-stats {
  font-family: var(--tp-ff-onest);
  font-size: 15px;
  color: var(--tp-common-black4);
  width: 110px;
  margin-right: 10px;
  font-weight: 500;
}
.tp-review-input {
  margin-bottom: 22px;
}
.tp-review-form span {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
  color: var(--tp-common-black4);
  margin-bottom: 8px;
  display: inline-block;
}
.tp-review-form .input {
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
  padding: 23px 30px;
  border: 1px solid transparent;
}
.tp-review-form .input:focus {
  border: 1px solid var(--tp-common-black4);
}
.tp-review-form .textarea {
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
  padding: 23px 30px;
  border: 1px solid transparent;
  height: 160px;
  resize: none;
}
.tp-review-form .textarea:focus {
  border: 1px solid var(--tp-common-black4);
}

.tp-grey {
  color: #c5bdb4 !important;
}

.tp-grey-2 {
  color: #d7d7d7 !important;
}

/*----------------------------------------*/
/* 7.14 gallery css
/*----------------------------------------*/
.tp-gallery-btn .tp-gallery-button-prev {
  margin-right: 16px;
}
.tp-gallery-btn div {
  font-size: 36px;
  color: var(--tp-common-black);
  opacity: 0.3;
  transition: all 0.3s ease;
}
.tp-gallery-btn div:hover {
  opacity: 1;
}
.tp-gallery-btn-3 div {
  font-size: 20px;
}
.tp-gallery-item {
  overflow: hidden;
}
.tp-gallery-item img {
  transition: all 0.5s ease;
  transform: scale(1.1);
  height: 586px;
    width: 100%;
}

.tp-gallery-item:hover img {
  transform: scale(1);
}
.tp-gallery-pagination {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.02em;
  color: #a3a0a1;
}
.tp-gallery-pagination .swiper-pagination-total {
  color: var(--tp-common-black);
}
.tp-gallery-pagination.swiper-pagination-fraction {
  width: auto;
}
.tp-gallery-content p {
  line-height: 150%;
  letter-spacing: -0.02em;
  color: var(--tp-grey-1);
}
.tp-gallery-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.2;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-gallery-logo svg {
    width: 100%;
  }
}
.tp-gallery-view {
  font-weight: 600;
  letter-spacing: -0.02em;
  color: var(--tp-theme-primary);
  padding-left: 65px;
  position: relative;
}
.tp-gallery-view span {
  background-image: linear-gradient(#B7124D, #B7124D), linear-gradient(#B7124D, #B7124D);
  background-size: 0 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s linear;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tp-gallery-view span:hover {
  color: var(--tp-theme-primary);
  background-image: linear-gradient(#B7124D, #B7124D), linear-gradient(#B7124D, #B7124D);
  background-size: 0 1px, 100% 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s linear;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tp-gallery-view::before {
  position: absolute;
  content: "";
  width: 50px;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: var(--tp-theme-primary);
}
.tp-gallery-slide {
  margin-left: -100px;
  margin-right: -100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-gallery-slide {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 575px) {
  .tp-gallery-row-spacing {
    margin-bottom: 10px;
  }
}
.tp-gallery-thumb {
  position: relative;
  transition: all 0.6s ease;
  overflow: hidden;
}
.tp-gallery-thumb img {
  transition: all 0.6s ease;
}
.tp-gallery-thumb:hover img {
  transform: scale(1.07);
}
.tp-gallery-thumb:hover::before {
  opacity: 1;
}
.tp-gallery-thumb::before {
  background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.8) 100%);
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.4s ease;
  z-index: 1;
}

/*----------------------------------------*/
/*  7.16 room css
/*----------------------------------------*/
.tp-offer-info-wrapper {
  box-shadow: 0 20px 30px 6px rgba(20, 20, 20, 0.1);
  background: var(--tp-common-white);
  padding: 50px 55px 50px 50px;
  margin-right: 65px;
  margin-bottom: -90px;
  margin-top: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-offer-info-wrapper {
    margin-right: 26px;
    margin-top: 26px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-offer-info-wrapper {
    margin-right: 0;
  }
}
@media (max-width: 575px) {
  .tp-offer-info-wrapper {
    padding: 50px 20px 50px 20px;
  }
}
.tp-offer-border {
  width: 100px;
  height: 2px;
  background: var(--tp-theme-primary);
  display: inline-block;
}
.tp-offer-para {
  line-height: 1.5;
  letter-spacing: -0.02em;
  color: var(--tp-grey-1);
}
.tp-offer-title {
  font-weight: 700;
  font-size: 36px;
  letter-spacing: -0.02em;
}
.tp-offer-title-pre {
  font-size: 20px;
  letter-spacing: -0.02em;
  color: var(--tp-theme-primary);
  margin-bottom: 5px;
  display: inline-block;
  font-weight: 500;
}
.tp-offer-room-space {
  margin-bottom: 250px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-offer-room-space {
    margin-bottom: 180px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-offer-room-space {
    margin-bottom: 155px;
  }
}
.tp-offer-rating-wrap {
  margin-right: 27px;
  padding-right: 35px;
  border-right: 1px solid #dfdfdf;
}
.tp-offer-rating span {
  color: #FF9E0D;
  font-size: 13px;
}
.tp-offer-rating-days .space {
  margin: 0px 3px;
}
.tp-offer-rating-days span {
  letter-spacing: -0.02em;
}
.tp-offer-price span {
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.02em;
}
.tp-offer-price h6 {
  font-size: 16px;
  line-height: 1;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0;
}
.tp-offer-three-pre {
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 13px;
  line-height: 2.3;
  letter-spacing: 0.06em;
  display: block;
  text-transform: uppercase;
  color: var(--tp-common-white);
  border-bottom: 1px solid rgba(250, 245, 239, 0.2);
  padding-bottom: 7px;
  margin-bottom: 11px;
}
.tp-offer-three-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 600;
  font-size: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--tp-common-white);
}
.tp-offer-three-btn {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--tp-theme-1);
  display: inline-block;
  background: var(--tp-common-white2);
  padding: 9px 24px;
}
.tp-offer-three-btn:hover {
  color: var(--tp-theme-1);
}
.tp-offer-three-content {
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 50px 92px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-offer-three-content {
    padding: 50px 42px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-offer-three-content {
    padding: 20px 20px;
  }
}
.tp-offer-three-thumb img {
  transition: all 0.4s ease;
}
.tp-offer-three-wrap {
  overflow: hidden;
}
.tp-offer-three-wrap:hover .tp-offer-three-thumb img {
  transform: scale(1.1);
}
.tp-offer-awesome {
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  color: var(--tp-common-white);
  background: var(--tp-theme-1);
  padding: 7px 18px;
}
.tp-offer-discount .discount {
  font-weight: 500;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: var(--tp-common-black);
}
.tp-offer-discount p {
  font-family: var(--tp-ff-onest);
  font-size: 17px;
  letter-spacing: -0.02em;
  font-weight: 400;
  color: #39332f;
}

/*----------------------------------------*/
/*  2.14 Flatpicker
/*----------------------------------------*/
.flatpickr-calendar {
  border-radius: 0;
  box-shadow: 0 10px 20px 4px rgba(20, 20, 20, 0.14);
  background: var(--tp-common-white);
}
.flatpickr-calendar.open {
  padding: 30px;
  width: 384px;
  margin-top: 5px;
  padding-top: 20px;
  padding-bottom: 35px;
}
@media (max-width: 575px) {
  .flatpickr-calendar.open {
    width: 340px;
  }
}
.flatpickr-calendar.open::after {
  display: none !important;
}
.flatpickr-calendar.open .flatpickr-months {
  margin-bottom: 13px;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-prev-month, .flatpickr-calendar.open .flatpickr-months .flatpickr-next-month {
  line-height: 35px;
  padding: 0;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-prev-month {
  top: 20px;
  left: 35px;
  transition: 0.3s;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-prev-month svg {
  width: 18px;
  height: 18px;
  fill: #57595F;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-prev-month svg path {
  fill: #57595F;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-prev-month:hover svg path {
  fill: var(--tp-common-black);
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-next-month {
  top: 20px;
  right: 35px;
  transition: 0.3s;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-next-month svg {
  width: 18px;
  height: 18px;
  fill: #57595F;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-next-month svg path {
  fill: #57595F;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-next-month:hover svg path {
  fill: var(--tp-common-black);
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-month .flatpickr-current-month {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-month .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: transparent;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-month .flatpickr-current-month .flatpickr-monthDropdown-months {
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-month .flatpickr-current-month input.cur-year {
  font-size: 18px;
  font-weight: 400;
  color: #1E1E2F;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper span {
  display: none;
}
.flatpickr-calendar.open .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper:hover {
  background: transparent;
}
.flatpickr-calendar.open .flatpickr-innerContainer {
  justify-content: center;
}
.flatpickr-calendar.open .dayContainer {
  justify-content: center;
}
.flatpickr-calendar.open .flatpickr-days {
  padding-top: 2px;
}
.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-weekdaycontainer span {
  color: #57595F;
  font-size: 15px;
  font-weight: 400;
}
.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.flatpickr-disabled, .flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.flatpickr-disabled:hover, .flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.prevMonthDay, .flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.nextMonthDay, .flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.notAllowed, .flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.notAllowed.nextMonthDay {
  color: #9d9c9d;
}
.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day {
  border: 1px solid #e8e8e8;
  border-radius: 0;
  width: 46px;
  max-width: 46px;
  margin-right: -1px;
  height: 44px;
  margin-top: -1px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--tp-common-black);
}
@media (max-width: 575px) {
  .flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day {
    width: 40px;
    max-width: 40px;
    height: 40px;
  }
}
.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.today, .flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.selected {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-primary);
  border: none;
}
.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-primary);
}
.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.today:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-primary);
  border: none;
}
.flatpickr-calendar .flatpickr-weekdays {
  display: none;
}
.flatpickr-calendar.arrowTop::before {
  content: none;
}

.calender-active.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.today:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-falured);
}

.calender-active.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.today, .flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day.selected {
  color: var(--tp-common-white);
  background-color: var(--tp-common-falured);
}

.calender-active.open .flatpickr-innerContainer .flatpickr-days .flatpickr-day:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-falured);
}

.calender-active.open {
  box-shadow: 0 10px 20px 4px rgba(20, 20, 20, 0.14);
  background: #faf5ef;
}

.flatpickr-current-month .nice-select::after {
  display: none;
}

/*----------------------------------------*/
/*  7.4 banner css
/*----------------------------------------*/
.tp-banner-thumb {
  padding: 53px 40px 230px 60px;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-banner-thumb {
    padding: 53px 40px 165px 60px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-banner-thumb {
    padding: 53px 40px 120px 60px;
  }
}
@media (max-width: 575px) {
  .tp-banner-thumb {
    padding: 53px 40px 120px 30px;
  }
}
.tp-banner-thumb-2 {
  padding: 52px 40px 53px 344px;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-banner-thumb-2 {
    padding: 52px 40px 53px 200px;
  }
}
@media (max-width: 575px) {
  .tp-banner-thumb-2 {
    padding: 52px 40px 53px 30px;
  }
}
.tp-banner-thumb-3 {
  padding: 52px 40px 53px 60px;
  border-radius: 10px;
}
@media (max-width: 575px) {
  .tp-banner-thumb-3 {
    padding: 52px 40px 53px 30px;
  }
}
.tp-banner-thumb-inner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  transition: all 0.7s ease;
  height: 100%;
}
.tp-banner-thumb-inner img {
  height: 100%;
}
.tp-banner-box {
  overflow: hidden;
}
.tp-banner-box:hover .tp-banner-thumb-inner {
  transform: scale(1.07);
}
.tp-banner-title {
  font-family: var(--tp-ff-onest);
  font-weight: 700;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-banner-title {
    font-size: 30px;
  }
}
.tp-banner-title-pre {
  font-family: var(--tp-ff-amita);
  color: #697203;
  margin-bottom: 0;
}
.tp-banner-title-pre-2 {
  color: #aa0c87;
}
.tp-banner-offer {
  font-family: var(--tp-ff-amita);
  font-weight: 700;
  font-size: 24px;
  color: var(--tp-common-black);
}
.tp-banner-offer span {
  position: relative;
  color: var(--tp-theme-primary);
}
.tp-banner-offer span svg {
  position: absolute;
  left: -6px;
  bottom: 3px;
}

/*----------------------------------------*/
/*  7.2 adventures css
/*----------------------------------------*/
.tp-adventures-thumb {
  margin-right: 90px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-adventures-thumb {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-adventures-title-wrap {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-adventures-title-wrap .tp-section-title-two br {
    display: block;
  }
}
.tp-adventures-para {
  font-family: var(--tp-ff-onest);
  font-size: 18px;
  line-height: 156%;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-adventures-para br {
    display: none;
  }
}
.tp-adventures-content span {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--tp-grey-3);
}
.tp-adventures-content h4 {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 18px;
  line-height: 133%;
  letter-spacing: -0.02em;
}
.tp-adventures-box {
  border-radius: 10px;
  box-shadow: 6px 6px 0 0 #f5f5f5, 0 1px 4px 0 rgba(20, 20, 20, 0.1);
  border: 1px solid #e4e4e4;
  padding: 25px 25px 10px 25px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-adventures-box:hover {
  box-shadow: none;
}
.tp-adventures-box:hover .tp-adventures-box-icon img {
  transform: scale(1.1);
}
.tp-adventures-box-icon {
  display: inline-block;
}
.tp-adventures-box-icon img {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-adventures-box svg {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
}
.tp-adventures-shape {
  position: absolute;
  bottom: 20%;
  left: 36%;
}
@media (max-width: 575px) {
  .tp-adventures-shape img {
    width: 40%;
  }
}
.tp-adventures-shape-2 {
  position: absolute;
  bottom: 15%;
  right: 12%;
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: hero-bounce;
}
@media (max-width: 575px) {
  .tp-adventures-shape-2 img {
    width: 40%;
  }
}
.tp-adventures-shape-3 {
  position: absolute;
  bottom: 22%;
  left: 0;
}
@media (max-width: 575px) {
  .tp-adventures-shape-3 img {
    width: 40%;
  }
}

/*----------------------------------------*/
/* 7.7 connected css
/*----------------------------------------*/
.tp-connected-app-item {
  box-shadow: 0 1px 4px 0 rgba(20, 20, 20, 0.5);
  background: var(--tp-common-black);
  padding: 8px 16px 10px 16px;
  border-radius: 6px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: relative;
  z-index: 1;
}
.tp-connected-app-item::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--tp-common-black);
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 6px;
}
.tp-connected-app-item span {
  width: 30px;
}
.tp-connected-app-item p {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 12px;
  line-height: 167%;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
}
.tp-connected-app-store {
  flex-wrap: wrap;
}
.tp-connected-app-border {
  border: 2px solid #a1c8de;
  padding: 6px;
  border-radius: 10px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tp-connected-app-border:hover {
  border: 2px solid transparent;
}
.tp-connected-app-border:hover .tp-connected-app-item::before {
  transform: scale(1.12);
}
.tp-connected-app-white::before {
  background: var(--tp-common-white);
}
.tp-connected-app-white p {
  color: var(--tp-common-black);
}
.tp-connected-app-white h6 {
  color: var(--tp-common-black);
}
.tp-connected-app-title {
  font-family: var(--tp-ff-onest);
  font-weight: 700;
  font-size: 18px;
  color: var(--tp-common-white);
  margin-bottom: 0;
}
.tp-connected-thumb {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-connected-thumb {
    text-align: left;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-connected-thumb img {
    width: 100%;
  }
}
.tp-connected-content-wrap {
  position: relative;
  z-index: 1;
}
@media (max-width: 575px) {
  .tp-connected-content-wrap {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-connected-title-wrap {
    margin-right: 0;
  }
}

/*----------------------------------------*/
/* 7.5 booking css
/*----------------------------------------*/
.tp-booking-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 500;
  font-size: 34px;
  color: var(--tp-common-white);
  padding: 0 60px;
  display: inline-block;
  background: var(--tp-theme-1);
  position: relative;
  z-index: 1;
}
.tp-booking-border {
  background: rgba(255, 208, 132, 0.2);
  height: 1px;
  width: 100%;
  display: inline-block;
  left: 0;
  right: 0;
  top: 58%;
}
.tp-booking-form-input {
  position: relative;
}
.tp-booking-form-input input {
  background: none;
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.01em;
  border: none;
  border-bottom: 2px solid #faf5ef;
  width: 100%;
  max-width: 100%;
  padding: 9px 6px;
  color: var(--tp-common-white);
  height: inherit;
}
.tp-booking-form-input input::placeholder {
  color: rgba(250, 245, 239, 0.5);
  letter-spacing: -0.01em;
  font-size: 16px;
}
.tp-booking-form-input span {
  color: var(--tp-common-white);
  position: absolute;
  right: 6px;
  top: 4px;
}
.tp-booking-form-input p {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: var(--tp-common-white2);
  margin-bottom: 5px;
}
.tp-booking-form-input-2 p {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #857a70;
}
.tp-booking-form-input-2 span {
  right: 20px;
  top: 16px;
}
.tp-booking-form-input-2 input {
  background: rgba(160, 89, 22, 0.05);
  color: var(--tp-common-falured);
  padding: 20px 20px 20px 20px;
  border: 1.5px solid transparent;
}
.tp-booking-form-input-2 input:focus {
  border-color: var(--tp-common-falured);
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: none;
}
.tp-booking-form-input-2 input::placeholder {
  color: var(--tp-common-falured);
}
.tp-booking-form-quantity {
  width: 100%;
}
.tp-booking-form-quantity .tp-hero-quantity-click.active {
  background: none;
  color: var(--tp-common-white);
}
.tp-booking-form-quantity .tp-hero-quantity-click svg {
  color: var(--tp-common-white);
}
.tp-booking-form-quantity .tp-hero-quantity-click.active svg {
  color: var(--tp-common-white);
}
.tp-booking-form-quantity .tp-booking-form-click {
  background: none;
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.01em;
  border: none;
  border-bottom: 2px solid #faf5ef;
  width: 100%;
  max-width: 100%;
  padding: 9px 6px;
  color: rgba(250, 245, 239, 0.5);
  height: inherit;
  display: flex;
  justify-content: space-between;
}
.tp-booking-form-quantity .tp-booking-form-para {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: var(--tp-common-white2);
  margin-bottom: 5px;
}
.tp-booking-form-quantity-list {
  box-shadow: 0 10px 20px 4px rgba(20, 20, 20, 0.14);
  background: #faf5ef;
}
.tp-booking-form-quantity-list .tp-hero-quantity-inner > input {
  background: #faf5ef;
}
.tp-booking-form-quantity-list .tp-hero-quantity-btn a {
  background: #ede3d6;
}
.tp-booking-form-quantity-list .tp-hero-quantity-btn a:hover {
  background: var(--tp-common-falured);
  color: var(--tp-common-white);
}
.tp-booking-form-quantity-2 .tp-booking-form-para {
  font-weight: 500;
  font-size: 14px;
  color: #857a70;
}
.tp-booking-form-quantity-2 .tp-booking-form-click {
  background: rgba(160, 89, 22, 0.05);
  color: var(--tp-common-falured);
  padding: 20px 20px 20px 20px;
  border: 1.5px solid transparent;
}
.tp-booking-form-quantity-2 .tp-booking-form-click.active {
  color: var(--tp-common-falured);
  border-color: var(--tp-common-falured);
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-booking-form-quantity-2 .tp-hero-quantity-active {
    left: auto;
    right: 0;
  }
}
@media (max-width: 575px) {
  .tp-booking-form-quantity-2 .tp-hero-quantity-active {
    width: 300px;
  }
}
.tp-booking-form-btn {
  background: var(--tp-theme-2);
  width: 100%;
  padding: 10px 10px;
  color: var(--tp-common-white);
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 16px;
  color: var(--tp-common-white2);
  display: flex
;
  align-items: center;
  justify-content: center;
}
.tp-booking-form-btn:hover {
  background: var(--tp-common-falured);
}
.tp-booking-form-btn svg {
  margin-right: 4px;
  transform: translateY(-2px);
}

/*----------------------------------------*/
/* 7.3 banefit css
/*----------------------------------------*/
.tp-banefit-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.04em;
  color: var(--tp-common-black4);
}
.tp-banefit-content {
  position: relative;
  padding-left: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-banefit-content {
    margin-left: 0;
  }
}
.tp-banefit-content::before {
  content: "";
  background: rgba(160, 89, 22, 0.16);
  height: 74px;
  width: 1px;
  position: absolute;
  top: -8px;
  left: 0;
}
.tp-banefit-icon span {
  color: var(--tp-theme-2);
  margin-right: 20px;
}
.tp-banefit-icon span svg {
  transform: translateY(5px);
}

/*----------------------------------------*/
/* 7.21 room css
/*----------------------------------------*/
.tp-room-tag {
  font-family: var(--tp-ff-playfair);
  font-weight: 600;
  font-size: 14px;
  color: var(--tp-common-falured);
  display: inline-block;
  border: 1px solid rgba(160, 89, 22, 0.2);
  padding: 3px 11px;
}
.tp-room-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 700;
  font-size: 28px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black4);
}
.tp-room-btn-2 a {
  padding: 13px 31px;
}
.tp-room-btn-3 a {
  padding: 9px 42px;
  font-weight: 600;
}
.tp-room-btn-3 a:hover {
  background: var(--tp-common-falured);
  border-color: var(--tp-common-falured);
}
.tp-room-meta ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.tp-room-meta ul li {
  list-style: none;
  margin-right: 20px;
}
@media (max-width: 575px) {
  .tp-room-meta ul li {
    margin-right: 12px;
  }
}
.tp-room-meta ul li svg {
  transform: translateY(-3px);
  margin-right: 5px;
}
.tp-room-meta ul li span {
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.02em;
  color: #635c57;
}
.tp-room-meta-2 {
  background: var(--tp-common-white2);
  position: absolute;
  top: 0;
  right: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
}
@media (max-width: 575px) {
  .tp-room-meta-2 {
    right: 0;
  }
}
.tp-room-meta-2 ul li {
  padding: 8px 15px;
  margin-right: 0;
}
@media (max-width: 575px) {
  .tp-room-meta-2 ul li {
    padding: 4px 4px;
  }
}
.tp-room-meta-2 ul li span {
  color: #2d1a0e;
}
.jarallax {
  background-image: url('../src/COMPONENT/img/video/01.jpg');
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  background-attachment: fixed; /* Fix the background image on scroll */
  height: 880px; /* Set the height of the jarallax element to the full viewport height */
}

.tp-room-meta-2 ul li:not(:last-child) {
  border-right: 1px solid rgba(160, 89, 22, 0.12);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-room-btn {
    justify-content: start !important;
  }
}
.tp-room-slider-active.swiper-container {
 
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-room-slider-active.swiper-container {
    
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-room-slider-active.swiper-container {
    margin-right: 0;
  }
}
.swiper-container{
  display: flex;
  gap: 18px;
}
@media (max-width: 995px) {

  .swiper-container{
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    width: 100%;
  }
  .tp-room-wrap{
    align-items: center;
    justify-content: center;    
    width: 100%;
  }
  .tp-room-thumb{
    align-items: center;
    justify-content: center;    
    width: 100%;
  }
}
.tp-room-tab-btn {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 15px;
  color: var(--tp-common-black4);
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
  display: inline-block;
  padding: 11px 30px 12px 30px;
  margin-right: 4px;
}
.tp-room-tab-btn:hover {
  background: var(--tp-theme-2);
  color: var(--tp-common-white);
}
.tp-room-tab-btn.active {
  background: var(--tp-theme-2);
  color: var(--tp-common-white);
}
.tp-room-wrap:hover .tp-room-thumb img {
  transform: scale(1.1);
}
.tp-room-wrap:hover .tp-room-thumb-2 img {
  transform: scale(1.1);
}
.tp-room-wrap-2 .tp-room-content {
  padding: 40px 40px 40px 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px) {
  .tp-room-wrap-2 .tp-room-content .tp-room-meta ul li {
    margin-bottom: 8px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px) {
  .tp-room-wrap-2 .tp-room-content {
    padding: 20px 20px 20px 20px;
  }
}
.tp-room-wrap-3:hover .tp-room-meta-2 {
  top: 30px;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 575px) {
  .tp-room-wrap-3:hover .tp-room-meta-2 {
    top: 10px;
  }
}
.tp-room-thumb {
  overflow: hidden;
}
.tp-room-thumb img {
  transition: all 0.4s ease;
}
.tp-room-thumb-2 {
  overflow: hidden;
}
.tp-room-thumb-2 img {
  transition: all 0.4s ease;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-room-thumb-2 img {
    height: 250px;
    object-fit: cover;
  }
}
.tp-room-3-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 600;
  font-size: 28px;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
}
.tp-room-3-content {
  position: absolute;
  bottom: 35px;
  left: 40px;
}
@media (max-width: 575px) {
  .tp-room-3-content {
    bottom: 15px;
    left: 20px;
  }
}
.tp-room-3-price {
  display: flex;
  align-items: flex-end;
}
.tp-room-3-price span {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.02em;
  color: var(--tp-common-white);
  margin-bottom: 0;
  line-height: 1.3;
}
.tp-room-3-price p {
  font-weight: 500;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
}

.tp-bocking-input-wrap {
  width: 482px;
  max-width: 482px;
  height: 80px;
  display: flex;
  border: 2px solid #e7e8ea;
  background: var(--tp-common-white);
  padding: 15px 30px 17px 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-bocking-input-wrap {
    max-width: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-bocking-input-wrap {
    max-width: 385px;
  }
}
@media (max-width: 575px) {
  .tp-bocking-input-wrap {
    max-width: 305px;
    margin-right: 0;
    padding: 15px 10px 17px 10px;
  }
}
.tp-bocking-search-form {
  background: #f8f8f8;
  padding: 30px 36px 10px 36px;
}
@media (max-width: 575px) {
  .tp-bocking-search-form {
    padding: 15px 10px 0px 10px;
  }
}
.tp-bocking-submit-btn {
  height: 100%;
  padding: 20px 72px;
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: var(--tp-common-white);
}
.tp-bocking-form-input {
  width: 50%;
}
@media (max-width: 575px) {
  .tp-bocking-form-input {
    padding-left: 0;
  }
}
.tp-bocking-form-input p {
  margin-bottom: 4px;
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #9d9c9d;
}
.tp-bocking-form-input input {
  background: none;
  border: none;
  padding: 0;
  height: 20px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
.tp-bocking-form-input input::placeholder {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
.tp-bocking-form-quantity {
  border: 2px solid #e7e8ea;
  width: 200px;
  max-width: 200px;
  height: 80px;
  background: var(--tp-common-white);
  padding: 15px 30px 17px 30px;
}
.tp-bocking-form-quantity .tp-hero-quantity-click {
  background: none;
  border: none;
  padding: 0;
  height: 20px;
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
.tp-bocking-form-quantity p {
  margin-bottom: 4px;
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #9d9c9d;
}
@media (max-width: 575px) {
  .tp-bocking-quantity-active {
    left: -53px;
  }
}
@media (max-width: 575px) {
  .tp-bocking-quantity-active.tp-usd-list-open {
    left: -53px;
  }
}

.box-bg {
  background: var(--tp-common-white);
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.14);
}

/*----------------------------------------*/
/* 7.12 faq css
/*----------------------------------------*/
.tp-faq-thumb img {
  transform: translateX(-195px);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-faq-thumb img {
    width: 100%;
    transform: translateX(0);
  }
}
.tp-faq-wrapper .accordion {
  --bs-accordion-border-width: 0;
  --bs-accordion-btn-icon: none;
  --bs-accordion-btn-active-icon: none;
}
.tp-faq-wrapper .accordion-item {
  border-bottom: 2px solid rgba(160, 89, 22, 0.12);
  background: none;
  border-radius: 0;
}
.tp-faq-wrapper .accordion-body {
  padding: 0;
}
.tp-faq-btn {
  padding: 27px 20px;
  background: none;
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 22px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  color: var(--tp-common-black4);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-faq-btn b {
    margin-right: 30px;
  }
}
.tp-faq-btn:not(.collapsed) {
  box-shadow: none;
  background: none;
  color: var(--tp-common-falured);
}
.tp-faq-btn:not(.collapsed)::after {
  background-image: inherit;
}
.tp-faq-btn:after {
  background-image: inherit;
}
.tp-faq-btn:focus {
  border: none;
  outline: 0;
  background: none;
  box-shadow: none;
}
.tp-faq-btn .accordion-btn {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: inline-block;
  width: 12px;
  height: 12px;
}
.tp-faq-btn .accordion-btn::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #000000;
  border-radius: 2px;
  transition: all 0.4s ease;
}
.tp-faq-btn .accordion-btn::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  left: 50%;
  top: 0;
  background-color: #000000;
  transform: translateX(-50%);
  border-radius: 2px;
  transition: all 0.4s ease;
}
.tp-faq-btn .accordion-button:not(.collapsed) {
  color: var(--tp-common-black);
  background: none;
  box-shadow: none;
}
.tp-faq-btn:not(.collapsed) .accordion-btn::before {
  transform: translateX(-50%) rotate(90deg);
  background: var(--tp-common-falured);
  opacity: 1;
}
.tp-faq-btn:not(.collapsed) .accordion-btn::after {
  background: var(--tp-common-falured);
  opacity: 1;
}
.tp-faq-details-para {
  padding: 0 44px 8px 20px !important;
  transform: translateY(-8px);
}
.tp-faq-details-para p {
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  color: #635c57;
}
.tp-faq-wrapper-2 .accordion-item {
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
  margin-bottom: 12px;
  border: none;
}
.tp-faq-wrapper-2 .tp-faq-number {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 18px;
  color: var(--tp-common-falured);
  border-radius: 22px;
  background: rgba(160, 89, 22, 0.06);
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-right: 16px;
  transition: all 0.4s ease;
  flex: 0 0 auto;
}
.tp-faq-wrapper-2 .tp-faq-btn:not(.collapsed) .tp-faq-number {
  background: var(--tp-common-falured);
  color: var(--tp-common-white);
}
.tp-faq-wrapper-2 .tp-faq-details-para {
  padding: 0 70px 8px 77px !important;
  transform: translateY(-3px);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-faq-wrapper-2 .tp-faq-details-para {
    padding: 0 20px 8px 20px !important;
  }
}
.tp-faq-wrapper-2 .tp-faq-btn {
  font-weight: 600;
  font-size: 24px;
  font-family: var(--tp-ff-playfair);
}
@media (max-width: 575px) {
  .tp-faq-wrapper-2 .tp-faq-btn {
    font-size: 20px;
  }
}
.tp-faq-wrapper-2 .tp-faq-btn .accordion-btn {
  width: 34px;
  height: 34px;
  background: rgba(160, 89, 22, 0.06);
  border-radius: 20px;
  transition: all 0.4s ease;
}
.tp-faq-wrapper-2 .tp-faq-btn .accordion-btn::before {
  height: 12px;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--tp-common-falured);
}
.tp-faq-wrapper-2 .tp-faq-btn .accordion-btn::after {
  width: 12px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--tp-common-falured);
}
.tp-faq-wrapper-2 .tp-faq-btn:not(.collapsed) .accordion-btn {
  background: var(--tp-common-falured);
}
.tp-faq-wrapper-2 .tp-faq-btn:not(.collapsed) .accordion-btn::after {
  background: var(--tp-common-white);
}
.tp-faq-wrapper-2 .tp-faq-btn:not(.collapsed) .accordion-btn::before {
  transform: translate(-50%, -50%) rotate(90deg);
}
.tp-faq-title {
  display: inline-block;
  margin-right: 20px;
}
.tp-faq-sidebar-wrap {
  margin-right: 54px;
  position: sticky;
  top: 120px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-faq-sidebar-wrap {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-faq-sidebar-wrap {
    display: flex;
    flex-direction: column;
  }
}
.tp-faq-sidebar-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 700;
  font-size: 28px;
  color: var(--tp-common-black4);
}
.tp-faq-sidebar-nav {
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
  padding: 11px 28px 9px 28px;
}
.tp-faq-sidebar-nav ul li {
  list-style: none;
}
.tp-faq-sidebar-nav ul li:not(:last-child) {
  border-bottom: 1px dashed rgba(20, 24, 32, 0.2);
}
.tp-faq-sidebar-nav ul li a {
  display: block;
  position: relative;
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 18px;
  color: var(--tp-grey-5);
  width: 100%;
  display: flex;
  transition: all 0.4s ease;
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: pointer;
}
.tp-faq-sidebar-nav ul li a.active {
  color: var(--tp-common-falured);
}
.tp-faq-sidebar-nav ul li a.active .borders {
  transform: scale(1);
  visibility: visible;
}
.tp-faq-sidebar-nav ul li a .borders {
  width: 3px;
  height: 36px;
  background: var(--tp-common-falured);
  display: inline-block;
  position: absolute;
  left: -28px;
  top: 15%;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
  transform: scale(0);
  visibility: hidden;
}
.tp-faq-sidebar-nav ul li a .icon {
  transform: translateY(-3px);
  margin-right: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-faq-sidebar-box {
    margin-bottom: 30px;
  }
}
.tp-faq-contact-chat {
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
  padding: 35px 36px 19px 28px;
}
.tp-faq-contact-icon span {
  border-radius: 30px;
  width: 50px;
  height: 50px;
  background: rgba(160, 89, 22, 0.1);
  display: inline-block;
  text-align: center;
  line-height: 48px;
  margin-bottom: 21px;
}
.tp-faq-contact-btn a {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.02em;
  color: #141820;
}
.tp-faq-contact-btn a span {
  margin-left: 5px;
}
.tp-faq-contact-btn a span svg {
  transform: translateY(-1px);
}
.tp-faq-contact-text {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 22px;
  color: var(--tp-grey-5);
}

.tp-privacy-heading {
  margin-bottom: 35px;
}
.tp-privacy-title {
  font-size: 60px;
  font-weight: 700;
  color: var(--tp-common-black);
}
@media (max-width: 575px) {
  .tp-privacy-title {
    font-size: 42px;
  }
}
.tp-privacy-box {
  padding: 36px 40px 40px 40px;
  background-color: var(--tp-common-white);
  box-shadow: 0px 1px 2px 0px rgba(18, 20, 32, 0.2);
}
.tp-privacy-content {
  margin-bottom: 40px;
}
.tp-privacy-content-title {
  font-size: 26px;
  margin-bottom: 15px;
  color: var(--tp-common-black);
}
.tp-privacy-content p {
  color: #57575A;
  margin-bottom: 15px;
}
.tp-privacy-content ul li {
  position: relative;
  list-style: none;
  font-size: 18px;
  color: #57575A;
  padding-left: 16px;
}
.tp-privacy-content ul li:not(:last-of-type) {
  margin-bottom: 7px;
}
.tp-privacy-content ul li::after {
  position: absolute;
  content: "";
  left: 0;
  top: 10px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #57575A;
}
.tp-privacy-content-bottom p {
  font-style: italic;
  font-size: 14px;
}

/*----------------------------------------*/
/* 7.20 room-details css
/*----------------------------------------*/
.tp-room-details-thumb {
  position: relative;
}
/* .tp-room-details-thumb::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid var(--tp-common-falured);
  top: 0;
  left: 0;
  transform: scale(0.8);
  opacity: 0;
  transition: all 0.8s ease;
  z-index: 99;
} */
.tp-room-details-thumb-active .swiper-slide-active .tp-room-details-thumb::before {
  transform: scale(1);
  opacity: 1;
}
.tp-room-details-thumb img {
  cursor: pointer;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.tp-room-details-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 700;
  font-size: 35px;
  color: var(--tp-common-black4);
}
.tp-room-details-title-2 {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  font-size: 28px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
.tp-room-details-info-wrap {
  margin-left: 58px;
  padding: 33px 34px 40px 34px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-room-details-info-wrap {
    margin-left: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-room-details-info-wrap {
    margin-left: 0;
    padding: 33px 23px 40px 26px;
  }
}
.tp-room-details-info-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.02em;
  color: var(--tp-common-black4);
}
.tp-room-details-toggle-btn .tp-btn-square {
  padding: 8px 24px;
  cursor: pointer;
  transition: all 0.4s ease;
  display: inline-block;
}
.tp-room-details-toggle-btn .tp-btn-square:hover {
  background: var(--tp-theme-2);
  border-color: var(--tp-theme-2);
}
.tp-room-details-toggle-btn .show-less {
  display: none;
}
.tp-room-details-toggle-btn-2 .tp-btn-4 {
  background: var(--tp-theme-primary);
  backdrop-filter: blur(6px);
  border-radius: 6px;
  padding: 6px 24px;
  transition: all 0.4s ease;
  cursor: pointer;
}
.tp-room-details-toggle-btn-2 .tp-btn-4:hover {
  background: var(--tp-common-falured);
}
.tp-room-regular-price {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -0.01em;
  color: var(--tp-common-black4);
}
.tp-room-regular-price-border {
  border-top: 1px solid rgba(160, 89, 22, 0.16);
  border-bottom: 1px solid rgba(160, 89, 22, 0.16);
  padding: 23px 0px 16px 0px;
}
.tp-room-regular-price-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 600;
  font-size: 18px;
  color: var(--tp-common-black4);
}
.tp-room-extra-services ul li {
  list-style: none;
  font-family: var(--tp-ff-onest);
  font-size: 14px;
  display: flex;
  align-items: center;
}
.tp-room-extra-services ul li:not(:last-child) {
  margin-bottom: 16px;
}
.tp-room-extra-services ul li span {
  margin-right: 20px;
  width: 17px;
}
.tp-room-type-capacity {
  display: flex;
  align-items: center;
}
.tp-room-type-icon {
  margin-right: 11px;
  transform: translateY(-3px);
}
.tp-room-type-title {
  font-family: var(--tp-ff-onest);
  font-size: 14px;
  font-weight: 400;
  color: var(--tp-grey-5);
  margin-bottom: 0;
}
.tp-room-popup-icon {
  margin-right: 5px;
}
.tp-room-popup-map {
  margin-bottom: 27px;
}
.tp-room-popup-map-content {
  font-family: var(--tp-ff-onest);
  line-height: 28px;
  color: #5a5859;
  margin-bottom: 0;
}
.tp-room-popup-map-link {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: var(--tp-theme-primary);
  display: inline-block;
}
.tp-room-about-content p {
  font-family: var(--tp-ff-onest);
  font-size: 17px;
  line-height: 1.65;
  color: var(--tp-grey-5);
}
.tp-room-about-content-2 p {
  color: var(--tp-grey-1);
}
.tp-room-about-feature ul li {
  list-style: none;
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.6;
  color: var(--tp-grey-5);
  display: flex;
  justify-content: flex-start;
}
.tp-room-about-feature ul li:not(:last-child) {
  margin-bottom: 10px;
}
.tp-room-about-feature ul li span {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: var(--tp-grey-5);
  border-radius: 20px;
  transform: translateY(10px);
  margin-right: 10px;
  flex: 0 0 auto;
}
.tp-room-about-feature-2 ul li {
  color: var(--tp-grey-1);
}
.tp-room-about-feature-2 ul li span {
  background: var(--tp-grey-1);
}
.tp-room-about-refund {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  line-height: 1.75;
  color: var(--tp-common-falured);
}
.tp-room-about-amenities-item {
  background: var(--tp-common-white);
  padding: 30px 30px 30px 34px;
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-room-about-amenities-item {
    padding: 30px 25px 30px 34px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-room-about-amenities-item {
    padding: 30px 20px 30px 20px;
  }
}
.tp-room-about-amenities-item-2 {
  background: rgba(160, 89, 22, 0.05);
  border: none;
  padding: 23px 30px 21px 34px;
}
.tp-room-about-amenities-item-2.colum-space {
  margin-bottom: 12px;
}
.tp-room-about-amenities-item-2 .tp-room-about-amenities-title {
  font-size: 22px;
}
.tp-room-about-amenities-item-2 .tp-room-about-amenities-icon::before {
  height: 36px;
  top: -2px;
}
.tp-room-about-amenities-item-2 .tp-room-about-amenities-icon span {
  width: 30px;
  height: 30px;
}
.tp-room-about-amenities-icon {
  position: relative;
  padding-right: 30px;
  margin-right: 23px;
}
.tp-room-about-amenities-icon span {
  width: 36px;
  height: 36px;
  display: inline-block;
}
.tp-room-about-amenities-icon::before {
  position: absolute;
  content: "";
  background: rgba(160, 89, 22, 0.16);
  width: 1px;
  height: 50px;
  top: -6px;
  right: 0;
}
.tp-room-about-amenities-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 600;
  font-size: 24px;
  color: var(--tp-common-black4);
  margin-bottom: 0;
}
.tp-room-about-amenities-2 .tp-room-about-amenities-item {
  background: #f6f6f6;
  border-radius: 12px;
}
.tp-room-about-amenities-2 .tp-room-about-amenities-title {
  font-family: var(--tp-ff-onest);
  font-size: 20px;
  color: var(--tp-common-black);
}
.tp-room-about-amenities-2 .tp-room-about-amenities-icon::before {
  background: rgba(20, 20, 20, 0.1);
}
.tp-room-slider-navigation button {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 48px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  background-color: transparent;
  color: var(--tp-common-white);
  position: absolute;
  top: 48%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-size: 18px;
}
.tp-room-slider-navigation button:hover {
  background: var(--tp-common-white);
  color: var(--tp-common-black4);
}
.tp-room-slider-navigation button.tp-room-details-slide-next {
  right: 15px;
  left: auto;
}
.tp-room-gellary-thumb-2 img {
  border-radius: 12px;
}
.tp-room-gellary-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.tp-room-info ul li {
  background: var(--tp-common-white);
  padding: 11px 19px;
  border-radius: 100px;
  display: inline-block;
  margin-bottom: 10px;
  list-style: none;
  margin-right: 4px;
}
.tp-room-info ul li span {
  display: inline-block;
}
.tp-room-info-space {
  padding-bottom: 50px;
  margin-bottom: 53px;
  border-bottom: 1px solid rgba(160, 89, 22, 0.16);
}
.tp-room-info-wrap p {
  font-family: var(--tp-ff-onest);
  font-size: 20px;
  line-height: 1.65;
  color: var(--tp-grey-5);
}
.tp-room-info-title {
  font-family: var(--tp-ff-playfair);
  font-weight: 700;
  font-size: 60px;
  letter-spacing: -0.01em;
  color: var(--tp-common-black4);
}
@media (max-width: 575px) {
  .tp-room-info-title {
    font-size: 40px;
  }
}
.tp-room-info-title-2 {
  font-family: var(--tp-ff-onest);
  font-weight: 600;
  letter-spacing: -0.02em;
}
@media (max-width: 575px) {
  .tp-room-info-title-2 {
    font-size: 40px;
  }
}
.tp-room-info-subtitle {
  font-family: var(--tp-ff-playfair);
  font-weight: 600;
  font-size: 19px;
  color: var(--tp-common-falured);
  border: 1px solid rgba(160, 89, 22, 0.2);
  padding: 10px 20px;
  display: inline-block;
  backdrop-filter: blur(6px);
}
.tp-room-info-subtitle-2 {
  font-family: var(--tp-ff-onest);
  font-size: 14px;
  color: var(--tp-grey-1);
  background: #f6f6f6;
  border-radius: 100px;
  padding: 5px 16px;
  display: inline-block;
  margin-bottom: 8px;
}
.tp-room-info-subtitle-2 i {
  color: var(--tp-common-black);
  margin-right: 3px;
  transform: translateY(-1px);
}
.tp-room-info-border {
  position: relative;
  margin-right: 13px;
  padding-right: 13px;
  transform: translateY(-2px);
}
.tp-room-info-border::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 26px;
  background: rgba(160, 89, 22, 0.2);
  top: 1px;
  right: 0;
}
.tp-room-info-text {
  font-family: var(--tp-ff-onest);
  font-size: 15px;
  color: var(--tp-common-black);
  font-weight: 500;
}
.tp-room-info-2 ul li {
  background: #f6f6f6;
}
.tp-room-info-2 ul li .tp-room-info-border::before {
  background: rgba(20, 20, 20, 0.1);
}
.tp-room-free-parking p {
  font-family: var(--tp-ff-onest);
  font-size: 15px;
  color: var(--tp-grey-1);
}
.tp-room-free-parking p i {
  margin-right: 5px;
}
.tp-room-map-box {
  height: 300px;
  width: 100%;
  border: 10px solid var(--tp-common-white);
  box-shadow: 0 2px 3px 0 rgba(32, 33, 36, 0.12);
  margin-bottom: 18px;
}
.tp-room-map-box-2 {
  border-radius: 12px;
  border: 1px solid rgba(20, 20, 20, 0.2);
  padding: 10px;
  box-shadow: none;
}
.tp-room-map-box-2 iframe {
  border-radius: 12px;
}
.tp-room-map-box iframe {
  width: 100%;
  height: 100%;
  border: 1px solid #000;
}
.tp-room-review-rating span {
  font-size: 12px;
  color: #ffa81a;
}
.tp-room-review-rating span:nth-child(4) {
  color: #c5bdb4;
}
.tp-room-review-rating span:nth-child(5) {
  color: #c5bdb4;
}
.tp-room-review-item {
  border-top: 1px solid rgba(160, 89, 22, 0.16);
  padding-top: 32px;
  margin-bottom: 15px;
}
.tp-room-review-avater {
  display: flex;
}
@media (max-width: 575px) {
  .tp-room-review-avater {
    flex-direction: column;
  }
}
.tp-room-review-avater-content p {
  font-family: var(--tp-ff-onest);
  font-size: 14px;
  line-height: 22px;
  color: #55585b;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-room-review-avater-content p br {
    display: none;
  }
}
.tp-room-review-avater-thumb {
  margin-right: 20px;
  transform: translateY(6px);
}
@media (max-width: 575px) {
  .tp-room-review-avater-thumb {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.tp-room-review-avater-thumb img {
  border-radius: 50%;
  flex: 0 0 auto;
}
.tp-room-review-avater-title {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 16px;
  color: #010f1c;
  margin-bottom: 10px;
}
.tp-room-review-avater-title span {
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 14px;
  color: #55585b;
  position: relative;
  padding-left: 7px;
  display: inline-block;
}
.tp-room-review-avater-title span::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 20px;
  background: #c5bdb4;
  left: -2px;
  top: 50%;
  transform: translateY(-1px);
}
.tp-room-review-form-btn .tp-btn-4 {
  padding: 13px 43px;
  background: var(--tp-common-falured);
}
.tp-room-review-form-btn .tp-btn-4:hover {
  background: var(--tp-theme-2);
}
.tp-room-review-form-content {
  display: none;
}
.tp-room-review-form-content p {
  font-family: var(--tp-ff-onest);
  line-height: 28px;
  color: var(--tp-grey-5);
}
.tp-room-review-form-content-2 p {
  color: var(--tp-grey-1);
}
.tp-room-booking-sidebar {
  margin-left: 57px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-room-booking-sidebar {
    margin-left: 0;
  }
}
.tp-room-book-wrap {
  padding: 18px 35px 20px 35px;
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.12);
  background: var(--tp-common-white);
}
@media (max-width: 575px) {
  .tp-room-book-wrap {
    padding: 15px 15px 15px 15px;
  }
}
.tp-room-book-wrap-space {
  padding: 32px 35px 35px 35px;
}
@media (max-width: 575px) {
  .tp-room-book-wrap-space {
    padding: 15px 15px 15px 15px;
  }
}
.tp-room-book-wrap-2 {
  box-shadow: 0 20px 30px 6px rgba(20, 20, 20, 0.1);
  border-radius: 12px;
  border: 1px solid #ebebeb;
}
.tp-room-book-wrap-2 .tp-room-book-price {
  font-family: var(--tp-ff-onest);
  color: var(--tp-theme-primary);
  line-height: 1;
  font-size: 30px;
}
.tp-room-book-wrap-2 .tp-room-book-price .night {
  font-family: var(--tp-ff-onest);
  color: var(--tp-grey-1);
  line-height: 1;
}
.tp-room-book-wrap-2 .tp-room-book-unit {
  color: var(--tp-grey-1);
}
.tp-room-book-wrap-2 .tp-room-book-date {
  display: block;
}
.tp-room-book-unit {
  font-family: var(--tp-ff-onest);
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #2d1a0e;
}
.tp-room-book-price {
  font-family: var(--tp-ff-playfair);
  font-weight: 600;
  font-size: 40px;
  color: #2d1a0e;
  line-height: 0.7;
}
.tp-room-book-price .dolar {
  font-weight: 400;
  font-size: 34px;
  letter-spacing: -0.04em;
  color: #2d1a0e;
  margin-right: 5px;
  transform: translateY(4px);
  display: inline-block;
}
.tp-room-book-price .night {
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #635c57;
  transform: translateY(-3px);
  display: inline-block;
  margin-left: -5px;
}
.tp-room-book-date .input {
  width: 100%;
  max-width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
}
.tp-room-guest-toogle {
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
}

/*----------------------------------------*/
/*  35. LOGIN CSS START
/*----------------------------------------*/
.login__wrapper {
  padding: 50px 85px 55px;
  background: var(--tp-common-white);
  box-shadow: 0px 30px 60px rgba(3, 4, 28, 0.1);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .login__wrapper {
    padding: 50px 60px 55px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .login__wrapper {
    padding: 50px 40px 55px;
  }
}
@media (max-width: 575px) {
  .login__wrapper {
    padding: 40px 25px 45px;
  }
}
.login__title {
  font-size: 34px;
  letter-spacing: -0.04em;
  margin-bottom: 6px;
}
.login__top p {
  font-size: 16px;
}
.login__input {
  position: relative;
}
.login__input-wrapper {
  margin-bottom: 14px;
}
.login__input input {
  padding-left: 55px;
  padding-right: 50px;
  height: 60px;
  line-height: 60px;
  border: 1px solid #e9e9e9;
  background: #f6f6f6;
}
.login__input input:focus {
  border-color: var(--tp-theme-primary);
  background: #fff;
}
.login__input span {
  position: absolute;
  top: 50%;
  left: 27px;
  -webkit-transform: translateY(calc(-50% - 3px));
  -moz-transform: translateY(calc(-50% - 3px));
  -ms-transform: translateY(calc(-50% - 3px));
  -o-transform: translateY(calc(-50% - 3px));
  transform: translateY(calc(-50% - 3px));
}
.login__input-item {
  position: relative;
}
.login__input-item:not(:last-child) {
  margin-bottom: 20px;
}
.login__input-item > span {
  position: absolute;
  top: 50%;
  right: 22px;
  -webkit-transform: translateY(calc(-50% - 3px));
  -moz-transform: translateY(calc(-50% - 3px));
  -ms-transform: translateY(calc(-50% - 3px));
  -o-transform: translateY(calc(-50% - 3px));
  transform: translateY(calc(-50% - 3px));
}
.login__input-item > span:hover {
  cursor: pointer;
}
.login__remember input[type=checkbox], .login__remember input[type=radio] {
  display: none;
}
.login__remember input[type=checkbox]:checked ~ label::after, .login__remember input[type=radio]:checked ~ label::after {
  background-color: transparent;
  border-color: var(--tp-theme-primary);
}
.login__remember input[type=checkbox]:checked ~ label::before, .login__remember input[type=radio]:checked ~ label::before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.login__remember label {
  font-size: 14px;
  position: relative;
  padding-left: 30px;
  line-height: 1.7;
}
.login__remember label::after, .login__remember label::before {
  position: absolute;
  content: "";
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  border: 1px solid #e9e9e9;
  background: #f6f6f6;
}
.login__remember label::after:focus, .login__remember label::before:focus {
  border-color: var(--tp-theme-primary);
  background: #fff;
}
.login__remember label::before {
  width: 14px;
  height: 14px;
  background-color: var(--tp-theme-primary);
  left: 3px;
  top: 5px;
  right: 3px;
  bottom: 3px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.login__remember label:hover {
  cursor: pointer;
}
.login__forgot a {
  font-size: 14px;
  color: var(--tp-common-black);
  text-transform: capitalize;
}
.login__forgot a:hover {
  color: var(--tp-theme-primary);
}
.login__btn {
  margin-bottom: 18px;
}
.login__btn .tp-btn {
  font-size: 18px;
  padding: 17px 30px;
}
.login__btn .tp-btn:hover {
  background-color: var(--tp-common-black);
}
.login__social-header {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black-solid);
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 0 10px;
  margin-bottom: 25px;
}
.login__social-header::after, .login__social-header::before {
  position: absolute;
  content: "";
  left: 100%;
  top: 10px;
  width: 30px;
  height: 1px;
  background-color: var(--tp-common-black);
}
.login__social-header::before {
  left: auto;
  right: 100%;
}
.login__social-item a {
  display: inline-block;
  padding: 11px 25px;
  font-weight: 500;
  font-size: 14px;
  background: var(--tp-common-white);
  border: 1px solid #EAEAEF;
  margin-bottom: 20px;
  margin-right: 5px;
}
@media (max-width: 575px) {
  .login__social-item a {
    width: 100%;
    margin-right: 0;
  }
}
.login__social-item a:last-child {
  margin-right: 0;
}
.login__social-item a .login-social-icon {
  margin-right: 3px;
}
.login__social-item a .login-social-icon svg {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}
.login__social-item a:hover {
  border-color: var(--tp-theme-primary);
}
.login__register-now {
  text-align: center;
}
.login__register-now p {
  font-size: 14px;
  color: #525258;
  margin-bottom: 0;
}
.login__register-now p a {
  color: var(--tp-theme-primary);
  font-weight: 500;
}
.login__shape-1 {
  position: absolute;
  right: 14%;
  bottom: 32%;
  z-index: -1;
}
.login__shape-2 {
  position: absolute;
  right: -1%;
  bottom: 14%;
  z-index: -1;
}
.login__shape-3 {
  position: absolute;
  left: 3%;
  top: 35%;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login__shape-3 {
    left: -4%;
  }
}
.login__shape-4 {
  position: absolute;
  left: 18%;
  bottom: 10%;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login__shape-4 {
    left: 0%;
  }
}
.login__shape-5 {
  position: absolute;
  right: 20%;
  top: 43%;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login__shape-5 {
    right: 0%;
  }
}
.login__shape-6 {
  position: absolute;
  right: 22%;
  top: 27%;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login__shape-6 {
    right: 1%;
  }
}

.tp-login-input-eye {
  position: absolute;
  right: 26px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}

.tp-login-input-eye .open-eye {
  display: none;
}

/*----------------------------------------*/
/* 7.9 counter css
/*----------------------------------------*/
.tp-counter-number {
  font-family: var(--tp-ff-onest);
  font-weight: 400;
  font-size: 60px;
  letter-spacing: -0.02em;
  color: #141820;
  margin-bottom: 0;
}
.tp-counter-item {
  padding: 21px 30px 24px 40px;
  margin-left: -1px;
  border: 1px solid rgba(160, 89, 22, 0.1);
  margin-top: -1px;
}
.tp-counter-title {
  font-family: var(--tp-ff-onest);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #525356;
}

/*----------------------------------------*/
/*  8.19 Cart CSS
/*----------------------------------------*/
.tp-cart-header-product {
  padding-left: 30px !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-cart-list {
    overflow-x: scroll;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-cart-list {
    margin-right: 0;
  }
}
.tp-cart-list .table > :not(caption) > * > * {
  padding: 20px 0;
  box-shadow: none;
  vertical-align: middle;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-cart-list table {
    width: 840px;
  }
}
.tp-cart-list thead {
  background-color: #F1F3F4;
}
.tp-cart-list thead th {
  font-weight: 500;
  font-size: 14px;
  color: var(--tp-common-black);
  border: 0 !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
.tp-cart-list tr td {
  border-color: #E0E2E3;
}
.tp-cart-list tr td .tp-cart-action-btn {
  font-weight: 400;
  font-size: 14px;
  color: #818487;
}
.tp-cart-list tr td .tp-cart-action-btn:hover {
  color: #FF1826;
}
.tp-cart-list tr td.tp-cart-add-to-cart .tp-btn {
  border-radius: 0;
}
.tp-cart-list tbody tr:first-child td {
  padding-top: 30px !important;
}
.tp-cart-img {
  width: 78px;
  margin-right: 20px;
}
.tp-cart-img img {
  width: 78px;
  height: 100px;
  object-fit: cover;
}
.tp-cart-title a {
  margin-left: 20px;
  font-weight: 400;
  font-size: 16px;
  color: var(--tp-common-black);
}
.tp-cart-title a:hover {
  color: var(--tp-theme-primary);
}
.tp-cart-price {
  width: 126px;
}
.tp-cart-price span {
  font-size: 16px;
  color: var(--tp-common-black);
}
.tp-cart-quantity {
  width: 180px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-cart-coupon {
    margin-bottom: 20px;
  }
}
.tp-cart-coupon-input-box label {
  font-size: 14px;
  color: var(--tp-common-black);
  margin-bottom: 7px;
}
.tp-cart-coupon-input input {
  height: 46px;
  line-height: 46px;
  margin-right: 4px;
  max-width: 282px;
  border: 1px solid #e9e9e9;
  background: #f6f6f6;
}
.tp-cart-coupon-input input:focus {
  border-color: var(--tp-theme-primary);
  background: #fff;
}
.tp-cart-coupon-input input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #818487;
}
.tp-cart-coupon-input input::-moz-placeholder {
  /* Firefox 19+ */
  color: #818487;
}
.tp-cart-coupon-input input:-moz-placeholder {
  /* Firefox 4-18 */
  color: #818487;
}
.tp-cart-coupon-input input:-ms-input-placeholder {
  /* IE 10+  Edge*/
  color: #818487;
}
.tp-cart-coupon-input input::placeholder {
  /* MODERN BROWSER */
  color: #818487;
}
.tp-cart-coupon-input button {
  font-weight: 500;
  font-size: 16px;
  color: var(--tp-common-white);
  background-color: var(--tp-common-black);
  padding: 10px 30px;
}
.tp-cart-coupon-input button:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-primary);
}
.tp-cart-update-btn {
  font-weight: 500;
  font-size: 16px;
  color: var(--tp-common-black);
  background-color: var(--tp-common-white);
  padding: 9px 29px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.tp-cart-update-btn:hover {
  background-color: var(--tp-theme-primary);
  border-color: var(--tp-theme-primary);
  color: var(--tp-common-white);
}
.tp-cart-checkout-wrapper {
  background: var(--tp-common-white);
  box-shadow: 0px 30px 70px rgba(1, 15, 28, 0.1);
  padding: 36px 24px 28px;
  margin-left: -24px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tp-cart-checkout-wrapper {
    margin-top: 50px;
    margin-left: 0;
  }
}
.tp-cart-checkout-top {
  padding-bottom: 13px;
  margin-bottom: 19px;
  border-bottom: 1px solid #E0E2E3;
}
.tp-cart-checkout-top span {
  font-size: 20px;
  color: var(--tp-common-black);
  font-weight: 500;
}
.tp-cart-checkout-shipping {
  padding-bottom: 16px;
  border-bottom: 1px solid #E0E2E3;
  margin-bottom: 15px;
}
.tp-cart-checkout-shipping-title {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 7px;
}
.tp-cart-checkout-shipping-option:not(:last-child) {
  margin-bottom: 4px;
}
.tp-cart-checkout-shipping-option input {
  display: none;
}
.tp-cart-checkout-shipping-option input:checked + label::after {
  border-color: var(--tp-theme-primary);
}
.tp-cart-checkout-shipping-option input:checked + label::before {
  opacity: 1;
  visibility: visible;
}
.tp-cart-checkout-shipping-option label {
  font-weight: 400;
  font-size: 14px;
  color: #161C2D;
  position: relative;
  padding-left: 25px;
  line-height: 1.9;
}
.tp-cart-checkout-shipping-option label:hover {
  cursor: pointer;
}
.tp-cart-checkout-shipping-option label span {
  color: var(--tp-theme-primary);
}
.tp-cart-checkout-shipping-option label::after {
  position: absolute;
  content: "";
  left: 0;
  top: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #BCBCBC;
}
.tp-cart-checkout-shipping-option label::before {
  position: absolute;
  content: "";
  left: 4px;
  top: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--tp-theme-primary);
  visibility: hidden;
  opacity: 0;
}
.tp-cart-checkout-total {
  margin-bottom: 25px;
}
.tp-cart-checkout-total span {
  font-weight: 500;
  font-size: 18px;
  color: var(--tp-common-black);
}
.tp-cart-checkout-btn {
  font-size: 16px;
  font-weight: 500;
  color: var(--tp-common-white);
  background-color: var(--tp-common-black);
  display: inline-block;
  padding: 10px 30px;
  text-align: center;
}
.tp-cart-checkout-btn:hover {
  background-color: var(--tp-theme-primary);
  color: var(--tp-common-white);
}

.tp-product-quantity {
  width: 100px;
  position: relative;
  border-radius: 20px;
}

.tp-cart-plus,
.tp-cart-minus {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  color: var(--tp-common-black);
  position: absolute;
  top: 51%;
  left: 16px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.tp-cart-plus svg,
.tp-cart-minus svg {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}
.tp-cart-plus:hover,
.tp-cart-minus:hover {
  cursor: pointer;
  color: var(--tp-theme-1);
}
.tp-cart-plus.tp-cart-plus,
.tp-cart-minus.tp-cart-plus {
  left: auto;
  right: 16px;
}
.tp-cart-plus.tp-cart-plus::after,
.tp-cart-minus.tp-cart-plus::after {
  left: 0;
  right: auto;
}

.tp-cart-input[type=text] {
  height: 34px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #DADCE0;
  background-color: var(--tp-common-white);
  padding: 0 30px;
  border-radius: 20px;
}
[dir=rtl] .tp-cart-input[type=text] {
  text-align: center;
}
.tp-cart-input[type=text]:focus {
  outline: none;
}

/*----------------------------------------*/
/*  7.7 cart css
/*----------------------------------------*/
.cartmini__top-title {
  padding: 20px 0;
  border-bottom: 1px solid var(--tp-theme-primary);
}
.cartmini__top-title h4 {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 0;
}
.cartmini__close {
  position: absolute;
  top: 17px;
  right: 20px;
}
.cartmini__close-btn {
  background: transparent;
  color: var(--tp-common-black);
  font-size: 22px;
}
.cartmini__close-btn:hover {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.cartmini__shipping {
  padding: 15px 0;
  border-bottom: 1px solid var(--tp-theme-primary);
}
.cartmini__shipping .progress {
  height: 10px;
  border-radius: 0;
}
.cartmini__shipping .progress-bar {
  background-color: var(--tp-theme-primary);
}
.cartmini__shipping p {
  margin-bottom: 5px;
}
.cartmini__shipping p span {
  color: var(--tp-pink-1);
  font-weight: 600;
}
.cartmini__content a {
  font-size: 15px;
  margin-bottom: 12px;
  font-weight: 500;
  transition: all 0.3s ease;
}
.cartmini__content a:hover {
  color: var(--tp-theme-04);
}
.cartmini__widget {
  height: 100%;
}
.cartmini__widget::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.cartmini__widget-item {
  position: relative;
  display: flex;
  padding: 20px;
  padding-right: 35px;
  border-bottom: 1px solid rgba(129, 129, 129, 0.2);
  transition: background-color 0.3s;
}
.cartmini__widget-item:last-child {
  border-bottom: 0;
}
.cartmini__thumb {
  margin-right: 15px;
}
.cartmini__thumb img {
  width: 70px;
  height: 90px;
}
.cartmini__title {
  font-weight: 500;
  padding: 20px 20px;
  border-bottom: 1px solid rgba(129, 129, 129, 0.2);
  box-shadow: 0 0 10px 0 rgba(129, 129, 129, 0.2);
}
.cartmini__title h4 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0;
}
.cartmini__title a:hover {
  color: var(--tp-theme-primary);
}
.cartmini__del {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: var(--tp-common-black);
  font-size: 14px;
}
.cartmini__del:hover {
  color: var(--tp-theme-04);
}
.cartmini__checkout {
  padding: 20px;
  padding-bottom: 85px;
  width: 100%;
  background: var(--tp-common-white);
  border-top: 2px solid rgba(129, 129, 129, 0.2);
}
.cartmini__checkout-title h4 {
  font-size: 18px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--tp-common-black);
}
.cartmini__checkout-title span {
  float: right;
  font-size: 20px;
  font-weight: 600;
  color: var(--tp-theme-04);
}
.cartmini__price {
  font-size: 14px;
  font-weight: 500;
  color: var(--tp-theme-04);
}
.cartmini__quantity {
  font-size: 12px;
  font-weight: 500;
}
.cartmini__empty {
  margin-top: 150px;
}
.cartmini__empty img {
  margin-bottom: 30px;
}
.cartmini__empty p {
  font-size: 16px;
  color: var(--tp-common-black);
  margin-bottom: 15px;
}
.cartmini-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: var(--tp-common-white);
  background: var(--tp-common-black);
  text-align: center;
  padding: 10px 30px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  overflow: hidden;
  letter-spacing: -0.02em;
  border: 1px solid var(--tp-common-black);
}
.cartmini-btn:hover {
  border: 1px solid rgba(129, 129, 129, 0.2);
  color: var(--tp-common-black);
  background: none;
}
.cartmini-btn-border {
  display: inline-block;
  font-weight: 500;
  color: var(--tp-common-black);
  border: 1px solid rgba(129, 129, 129, 0.2);
  text-align: center;
  padding: 10px 30px;
  font-size: 15px;
  text-transform: capitalize;
}
.cartmini-btn-border:hover {
  background: var(--tp-common-black);
  border: 1px solid var(--tp-common-black);
  color: var(--tp-common-white);
}
.cartmini__area {
  position: fixed;
  right: 0;
  top: 0;
  width: 360px;
  height: 100%;
  transform: translateX(calc(100% + 80px));
  background: var(--tp-common-white) none repeat scroll 0 0;
  -moz-transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
  transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
  z-index: 999;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  scrollbar-width: none;
}
.cartmini__area.cartmini-opened {
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.cartmini__wrapper {
  position: relative;
  min-height: 100%;
}
.cartmini-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 995;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(15px);
  visibility: hidden;
  opacity: 0;
  transition: 0.45s ease-in-out;
}
.cartmini-overlay.openeds {
  opacity: 1;
  visibility: visible;
}
.cartmini-item-count {
  top: -10px;
  right: -9px;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  color: var(--tp-common-white);
  background-color: var(--tp-theme-01);
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
}
.cartmini-item-count-2 {
  background-color: var(--tp-common-black);
  color: var(--tp-common-white);
}
.cartmini-item-count-3 {
  background-color: var(--tp-theme-1);
}

.table-content table {
  background: var(--tp-common-white);
  border-color: #e3e3e3;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .table-content table {
    width: 150%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .table-content table {
    width: 200%;
  }
}
@media (max-width: 575px) {
  .table-content table {
    width: 300%;
  }
}
.table-content table.table > :not(:first-child) {
  border-top: 0;
}
.table-content table td {
  border-top: medium none;
  padding: 15px 10px;
  vertical-align: middle;
  font-size: 16px;
}
.table-content table td a:hover {
  color: var(--tp-blue-2);
}
.table-content table td.product-name {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.table-content table td.product-name a:hover {
  color: var(--tp-blue-2);
}
.table-content .product-quantity {
  float: none;
}
.table-content .product-quantity input {
  color: #000;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #e3e3e3;
}

.table-content .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #e3e3e3;
}

.table-content table th,
.table-content table td {
  border-bottom: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
}

.table td,
.table th {
  border-top: 1px solid #e3e3e3;
}

/* Checkout */
.coupon-accordion h3 {
  background-color: #f6f6f6;
  border-top: 2px solid var(--tp-theme-primary);
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 25px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  width: auto;
}
.coupon-accordion h3::before {
  content: "\f07b";
  left: 15px;
  top: 13px;
  position: absolute;
  color: #6f7172;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
}
.coupon-accordion span {
  color: #6f7172;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 500;
}
.coupon-accordion span:hover, .coupon-accordion span p.lost-password a:hover {
  color: var(--tp-theme-primary);
}

.payment-method {
  margin-top: 40px;
}
.payment-method .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.payment-method .accordion-item {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #00000026;
}
.payment-method .accordion-button {
  font-size: 16px;
  font-weight: 500;
  color: var(--tp-green-2);
  padding: 23px 0;
  border: none;
}
.payment-method .accordion-button:focus {
  box-shadow: none;
}
.payment-method .accordion-button::after {
  position: absolute;
  content: "\f067";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-weight: 400;
  margin-left: 0;
  background-image: none;
}
.payment-method .accordion-button:not(.collapsed) {
  color: var(--tp-theme-primary);
  background-color: var(--tp-common-white);
  box-shadow: none;
}
.payment-method .accordion-button:not(.collapsed)::after {
  content: "\f068";
}
.payment-method .accordion-body {
  font-size: 16px;
  padding: 8px 0;
  padding-bottom: 40px;
  color: var(--tp-text-body);
}
.payment-method .accordion-collapse {
  border: none;
}
.payment-method .accordion .card:first-of-type {
  border: 1px solid #eaedff;
}
.payment-method .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaedff;
}
.payment-method .card {
  background-color: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  margin-bottom: 10px;
}
.payment-method .btn-link {
  background: no-repeat;
  border: medium none;
  border-radius: 0;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 0;
  padding: 3px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}

.product-quantity > input {
  background-color: #fff;
  height: 30px;
  width: 80px;
  border-radius: 3px;
}

.table-content table td.product-subtotal {
  font-size: 16px;
}

.table-content table td .cart-plus-minus {
  float: none;
  margin: 0 auto;
}

.coupon-all {
  margin-top: 50px;
}

.coupon {
  float: left;
}

@media (max-width: 767px) {
  .coupon {
    float: none;
  }
}
#coupon_code {
  height: 50px;
  border: 1px solid #e3e3e3;
  padding: 0 15px;
  margin-right: 10px;
  border-radius: 0;
  background: #fff;
  margin-bottom: 15px;
  width: 265px;
}
#coupon_code:focus {
  border: 1px solid var(--tp-green-2);
}

.tp-btn.tp-color-btn {
  padding: 17px 33px;
  border-radius: 0;
}
.tp-btn.tp-color-btn-2:hover {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
}
.tp-btn.tp-color-btn-2::before {
  display: none;
}

@media (max-width: 767px) {
  #coupon_code {
    margin-bottom: 15px;
  }
}
.coupon2 {
  float: right;
}

@media (max-width: 767px) {
  .coupon2 {
    float: none;
    margin-top: 15px;
  }
}
.cart-page-total {
  padding-top: 50px;
}

.cart-page-total > h2 {
  font-size: 25px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid #e3e3e3;
}

.cart-page-total > ul > li {
  list-style: none;
  font-size: 15px;
  color: #6f7172;
  padding: 10px 30px;
  border-bottom: 1px solid #e3e3e3;
  font-weight: 400;
}

.cart-page-total ul > li > span {
  float: right;
}

.cart-page-total li:last-child {
  border-bottom: 0;
}

td.product-thumbnail img {
  width: 125px;
}

.tp-product-quantity .cart-input {
  height: 30px;
  background-color: #FFF;
}

.product-quantity .cart-plus, .product-quantity .cart-minus {
  width: 25px;
  height: 30px;
  border: 1px solid #e3e3e3;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  transition: 0.3s;
}
.product-quantity .cart-plus:hover, .product-quantity .cart-minus:hover {
  cursor: pointer;
  color: var(--tp-common-white);
  background: var(--tp-theme-primary);
}

.cart-input {
  height: 30px;
  width: 32px;
  text-align: center;
  font-size: 14px;
  border: none;
  border-top: 2px solid #e3e3e3;
  border-bottom: 2px solid #e3e3e3;
  display: inline-block;
  vertical-align: middle;
  margin: 0 -3px;
  padding-bottom: 0px;
}

.tp-wish-cart {
  min-width: 150px;
}

.coupon-content {
  border: 1px solid #eaedff;
  display: none;
  margin-bottom: 20px;
  padding: 30px;
}

.coupon-info p.coupon-text {
  margin-bottom: 15px;
}

.coupon-info p {
  margin-bottom: 0;
}

.coupon-info p.form-row-first label, .coupon-info p.form-row-last label {
  display: block;
  color: #6f7172;
}

.coupon-info p.form-row-first label span.required, .coupon-info p.form-row-last label span.required {
  color: red;
  font-weight: 700;
}

.coupon-info p.form-row-first input, .coupon-info p.form-row-last input {
  height: 45px;
  margin: 0 0 14px;
  max-width: 100%;
  width: 100%;
  outline: none;
  box-shadow: none;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 17px 20px 17px 20px;
  background: #f6f6f6;
}
.coupon-info p.form-row-first input:focus, .coupon-info p.form-row-last input:focus {
  border-color: var(--tp-theme-primary);
  background: #fff;
}

.coupon-info p.form-row input[type=submit]:hover, p.checkout-coupon input[type=submit]:hover {
  background: #3e976c none repeat scroll 0 0;
}

.coupon-info p.form-row input[type=checkbox] {
  position: relative;
  top: 4px;
  transform: translateY(-4px);
}

.form-row > label {
  margin-top: 15px;
  margin-left: 15px;
  color: #6f7172;
}

.buttons-cart input, .coupon input[type=submit], .buttons-cart a, .coupon-info p.form-row input[type=submit] {
  background: #252525 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

p.lost-password {
  margin-top: 15px;
}

p.lost-password a {
  color: #6f6f6f;
}
p.lost-password a:hover {
  color: var(--tp-theme-primary);
}

p.checkout-coupon input[type=text] {
  height: 45px;
  width: 100%;
  margin-bottom: 15px;
  outline: none;
  box-shadow: none;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 17px 20px 17px 20px;
  background: #f6f6f6;
}
p.checkout-coupon input[type=text]:focus {
  border-color: var(--tp-theme-primary);
  background: #fff;
}

.coupon-checkout-content {
  display: none;
}

.checkbox-form h3 {
  border-bottom: 1px solid #e3e3e3;
  font-size: 24px;
  font-weight: 800;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.country-select {
  margin-bottom: 30px;
  position: relative;
}

.country-select select {
  width: 100%;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #eaedff;
  padding: 0 10px;
  height: 50px;
}

.country-select label, .checkout-form-list label {
  color: #6f7172;
  display: block;
  margin: 0 0 5px;
}

.country-select label span.required, .checkout-form-list label span.required {
  color: red;
}

.country-select .nice-select {
  border: 1px solid #e3e3e3;
  height: 52px;
  padding-left: 20px;
  width: 100%;
  color: var(--tp-text-body);
  margin-bottom: 20px;
  line-height: 50px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  background: #f6f6f6;
}
.country-select .nice-select:focus {
  border-color: var(--tp-theme-primary);
  background: #fff;
}

.country-select .nice-select::after {
  right: 20px;
  top: 25px;
}

.country-select .nice-select .list {
  width: 100%;
}

.checkout-form-list {
  margin-bottom: 30px;
}

.checkout-form-list label {
  color: var(--tp-text-body);
}

.checkout-form-list input[type=text], .checkout-form-list input[type=password], .checkout-form-list input[type=email] {
  border-radius: 0;
  height: 52px;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  box-shadow: none;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 17px 20px 17px 20px;
  background: #f6f6f6;
}
.checkout-form-list input[type=text]:focus, .checkout-form-list input[type=password]:focus, .checkout-form-list input[type=email]:focus {
  border-color: var(--tp-theme-primary);
  background: #fff;
}

.checkout-form-list input[type=text]::-moz-placeholder,
.checkout-form-list input[type=password]::-moz-placeholder,
.checkout-form-list input[type=email]::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type=text]::placeholder,
.checkout-form-list input[type=password]::placeholder,
.checkout-form-list input[type=email]::placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type=checkbox] {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 4px;
  transform: translateY(-3px);
}

.create-acc label {
  color: var(--tp-text-body);
  display: inline-block;
}

.checkout-form-list #cbox {
  transform: translateY(-3px);
}

.create-account {
  display: none;
}

.ship-different-title h3 label {
  display: inline-block;
  margin-right: 20px;
  color: var(--tp-heading-primary);
}

.ship-different-title h3 #ship-box {
  transform: translateY(-2px);
}

.order-notes textarea {
  height: 120px;
  width: 100%;
  outline: 0;
  resize: none;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 17px 20px 17px 20px;
  background: #f6f6f6;
}
.order-notes textarea:focus {
  border-color: var(--tp-theme-primary);
  background: #fff;
}

.order-notes textarea::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.order-notes textarea::placeholder {
  color: #6f7172;
  opacity: 1;
}

#ship-box-info {
  display: none;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-default > .panel-heading {
  border-radius: 0;
}

.your-order {
  padding: 30px 40px 45px;
  border: 1px solid var(--tp-theme-primary);
}

@media (max-width: 767px) {
  .your-order {
    padding: 15px;
  }
}
.your-order h3 {
  border-bottom: 1px solid #e3e3e3;
  font-size: 30px;
  font-weight: 800;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.your-order-table table {
  background: none;
  border: 0;
  width: 100%;
}

.your-order-table table th, .your-order-table table td {
  border-bottom: 1px solid #e3e3e3;
  border-right: medium none;
  color: var(--tp-text-body);
  font-size: 16px;
  padding: 15px 0;
  text-align: left;
}

@media (max-width: 767px) {
  .your-order-table table th, .your-order-table table td {
    padding-right: 10px;
  }
}
.your-order-table table th {
  border-top: medium none;
  color: var(--tp-text-body);
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.panel-body > p {
  color: #222;
}

.your-order-table table .shipping ul li {
  list-style: none;
}

.your-order-table table .shipping ul li input {
  position: relative;
  top: 0px;
}

.your-order-table table .shipping ul li label {
  color: #6f7172;
}

.your-order-table table .shipping th {
  vertical-align: top;
}

.your-order-table table .order-total th {
  border-bottom: 0;
  font-size: 14px;
}

.your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order-table table tr.cart_item:hover {
  background: #F9F9F9;
}

.your-order-table table tr.order-total td span {
  color: var(--tp-green-2);
  font-size: 18px;
  font-weight: 500;
}

.order-button-payment input {
  background: #232323 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  margin: 0px 0 0;
  padding: 0;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  width: 100%;
}
.order-button-payment input:hover {
  background: #3e976c none repeat scroll 0 0;
}

.panel-title > a {
  display: block;
}

.card-header:first-child {
  border-radius: 0;
}

/*# sourceMappingURL=main.css.map */
