.dropdown-menu-animation {
    position: absolute;
    top: 100px; /* Adjust based on navbar height */
    right: 20px; /* Adjust based on preference */
    background-color: white;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    padding: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    height: 300vh;
    width: 100%;
    transition: opacity 0.6s ease, transform 0.6s ease; /* Smooth transitions */
    align-items: center;
  }

.dropdown-menu-animation.show {
    opacity: 1; /* Fully visible */
    transform: translate(-50%, 0); /* Slide into place */
  }
  
  .dropdown-menu-animation ul {
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 22px;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown-menu-animation li {
    margin: 10px 0;
  }
  
  .dropdown-menu-animation a {
    text-decoration: none;
    color: black;
    font-size: 25px;
  }
  