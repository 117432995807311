footer .footer-list li {
    margin-bottom: 10px;
  }
  footer .footer-list li a {
    transition: all 0.5s ease;
  }
  footer .footer-list li a:hover {
    color: #e6e8ea;
    font-size: 12px;
  }
  .footer-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  footer .footer-list li:last-child {
    margin-bottom: 0;
  }
  .nakin-footer-div-logo{
    width: 350px;
    height: 200px;
    display: flex !important;
    align-items: start;
    justify-content: end;
    
  }
  .foot-social-icon{
    display: flex;

    width: 50%;
  }