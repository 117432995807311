.shape-overlays {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
 
  z-index: 10;
  }
  .shape-overlays svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    fill: rgb(128, 35, 35); /* Matches the overlay color */
  }
  .shape-overlays__path:nth-of-type(1){
    fill: rgb(255 204 115 / 61%);
  }
  .shape-overlays__path:nth-of-type(2) {
    fill: rgba(255, 170, 0, 0.27);
}
.shape-overlays__path:nth-of-type(3) {
    fill: #fff;
}
  .shape-overlays.is-animated {
    transform: translateY(-100%);
    display: block;
  }
  
  .global-menu {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .global-menu.is-opened {
    opacity: 1;
    visibility: visible;
  }
  
  .hamburger.is-active .hamburger__line {
    background-color: #000; /* Change this to your desired color */
  }
  