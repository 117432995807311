:root {
    --navBg: #060b26;
  }
  
  .navbar {
    background-color:  #ff9930;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    z-index: 1;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color:  #ff9930;
    width: 250px;
    height: 100vh;
    display: flex
;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    z-index: 4;
  }
  
  .nav-menu.active {
    right: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: white;
    color: #ff9930;
}
.icons:hover{
    color: #ff9930;
}
.nav-text a:hover i {
    color: #ff9930;
}

  .nav-menu-items {
    width: 100%;
    padding: 0;
  }
  
  .navbar-toggle {
    background-color:  #ff9930;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }
  